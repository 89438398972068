import React from "react";

function MySettingsBar() {
    return (
        <div className="my-settings-bar">
            <div className="my-settings-bar-text">
                My settings
            </div>
        </div>
    );
}

export default MySettingsBar;