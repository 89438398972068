import React from 'react';
import plusOrange from '../../../images/plusOrange.png';
import PLAN_NAMES from "../../../core/constants/planNames";

const getCounts = selectedPlanName => {
    const projects = selectedPlanName === PLAN_NAMES.LARGE ? 10 : 5;
}

function PlanUpgradeConfirm({ selectedPlanName, onClose }) {
   const projectsCount = selectedPlanName === PLAN_NAMES.LARGE ? 10 : 5;
   const exportsCount = selectedPlanName === PLAN_NAMES.LARGE? 50: 10;
  return (
    <>
      <div className="upgrade-confirm-title">
        Vielen Dank für Ihr Upgrade!
      </div>
      <div className="upgrade-confirm-detail">
        <img src={plusOrange} />
        <span>bis zu <span>{projectsCount}</span></span> Projekte können Sie jetzt hinzufügen
      </div>
      <div className="upgrade-confirm-detail">
        <img src={plusOrange} />
        <span>bis zu <span>5</span></span> Nutzer können jetzt gemeinsam zugreifen
      </div>
      <div className="upgrade-confirm-detail">
        <img src={plusOrange} />
        <span>und <span>{exportsCount}</span></span> Exporte pro Monat unterstützen Sie bei Ihrer Arbeit
      </div>
      <div className="plan-update-popup-buttons">
        <div className="plan-update-popup-button" onClick={onClose}>
          In Ordnung
        </div>
      </div>
    </>
  );
}

export default PlanUpgradeConfirm;
