import React, { useState, useEffect} from "react";
import Loader from "../loader";
import {useSelector} from "react-redux";
import moment from "moment";
import UseSemanticsController from "../../../customHooks/controllerHooks/semanticController";
import UsePublisherController from "../../../customHooks/controllerHooks/publisherController";
import UseProjectController from "../../../customHooks/controllerHooks/projectController";
import { useLocation } from 'react-router-dom';
export const formatDateForApi = date => moment(date).format('MM/DD/YYYY');


const now = new Date();
const oneYearBefore = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
const threeMonthBefore = new Date(new Date().setMonth(new Date().getMonth() - 3));

const oneMonthBefore = new Date(new Date().setMonth(new Date().getMonth()-1));

function Splash({ children }) {
    const selectedSubject = useSelector(state => state.userInfo.data.projects[0] || '');

    const { pathname } = useLocation();

    const startDate = formatDateForApi(oneYearBefore);
    const threeMonthsBeforeStartDate = formatDateForApi(threeMonthBefore);
    const endDate = formatDateForApi(now);
    const [ isLoading, setLoading ] = useState(!!selectedSubject && pathname === '/');

    const {
        getFirstColumnV5, getFirstColumnV3,
    } = UseSemanticsController();

    const { getPublishersFirstColumn } = UsePublisherController();
    const { getHiddenLayers, getHiddenPublishers } = UseProjectController();

    useEffect(() => {

        if(selectedSubject && pathname === '/') {
            Promise.all([
                getHiddenLayers({name: selectedSubject}),
                getHiddenPublishers({name: selectedSubject}),
                getFirstColumnV5({
                    startDate: threeMonthsBeforeStartDate,
                    endDate,
                    project: selectedSubject,
                }),
                getFirstColumnV3({
                    startDate: threeMonthsBeforeStartDate,
                    endDate,
                    project: selectedSubject,
                }),
                getPublishersFirstColumn({
                    project: selectedSubject,
                    startDate, endDate
                }),
            ]).then(() => {
                setLoading(false);
            });
        }



    }, []);

    return isLoading ? <Loader/> : <>{children}</>
}

export default Splash;