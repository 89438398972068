import Fetch from "../fetch";

const END_POINT = {
    prefix: 'coverage',
};

class CoverageApiHandler {

    async getPublisherFilterCoverage(queryData, abortSignal) {
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal);
    }
}

const coverageApiHandler = new CoverageApiHandler();

export default coverageApiHandler;