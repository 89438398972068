import { combineReducers,  } from "redux";
import createAsyncReducer from "../createAsyncReducer";
import { SEMANTICS_TYPES } from "../actionTypes/semanticsActionTypes";
import {PROJECT_ATTRIBUTES} from "../../core/constants/projectAttributes";

export const semanticsReducer = combineReducers({
    semantics: combineReducers({
        firstColumn: createAsyncReducer(SEMANTICS_TYPES.RX_GET_FIRST_COLUMN, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: {
                [PROJECT_ATTRIBUTES.SCORECARD]: [],
                [PROJECT_ATTRIBUTES.INDUSTRY]: [],
                [PROJECT_ATTRIBUTES.RISKS]: [],
                [PROJECT_ATTRIBUTES.GENERAL]: [],
                [PROJECT_ATTRIBUTES.SECURITY]: [],
            },
            startDate: '',
            endDate: '',
            companies: [],
            coverages: [],
            peoples: [],
            publishers: [],
        }),
        secondColumn: createAsyncReducer(SEMANTICS_TYPES.RX_GET_SECOND_COLUMN, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: {},
            coverage: '',
            company: '',
            publisher: '',
            people: '',
            companies: [],
            coverages: [],
            peoples: [],
            publishers: [],
        }),
        thirdColumn: createAsyncReducer(SEMANTICS_TYPES.RX_GET_THIRD_COLUMN, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: {},
            coverage: '',
            company: '',
            publisher: '',
            people: '',
            companies: [],
            coverages: [],
            peoples: [],
            publishers: [],
        }),
        fourthColumn: createAsyncReducer(SEMANTICS_TYPES.RX_GET_FOURTH_COLUMN, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: {},
            coverage: '',
            company: '',
            publisher: '',
            people: '',
            companies: [],
            coverages: [],
            peoples: [],
            publishers: [],
        }),
        firstColumnV3: createAsyncReducer(SEMANTICS_TYPES.RX_GET_FIRST_COLUMN_V3, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: [],
            startDate: '',
            endDate: '',
            companies: [],
            coverages: [],
            peoples: [],
            publishers: [],
        }),
        secondColumnV3: createAsyncReducer(SEMANTICS_TYPES.RX_GET_SECOND_COLUMN_V3, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: {},
            coverage: '',
            company: '',
            publisher: '',
            people: '',
            companies: [],
            coverages: [],
            peoples: [],
            publishers: [],
        }),
        thirdColumnV3: createAsyncReducer(SEMANTICS_TYPES.RX_GET_THIRD_COLUMN_V3, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: {},
            coverage: '',
            company: '',
            publisher: '',
            people: '',
            companies: [],
            coverages: [],
            peoples: [],
            publishers: [],
        }),
        fourthColumnV3: createAsyncReducer(SEMANTICS_TYPES.RX_GET_FOURTH_COLUMN_V3, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: {},
            coverage: '',
            company: '',
            publisher: '',
            people: '',
            companies: [],
            coverages: [],
            peoples: [],
            publishers: [],
        }),
        firstChartAllLayers: createAsyncReducer(SEMANTICS_TYPES.RX_GET_FIRST_CHART_ALL_LAYERS, []),
        secondChartAllLayers: createAsyncReducer(SEMANTICS_TYPES.RX_GET_SECOND_CHART_ALL_LAYERS, []),
        thirdChartAllLayers: createAsyncReducer(SEMANTICS_TYPES.RX_GET_THIRD_CHART_ALL_LAYERS, []),
        fourthChartAllLayers: createAsyncReducer(SEMANTICS_TYPES.RX_GET_FOURTH_CHART_ALL_LAYERS, []),
        firstChartData: createAsyncReducer(SEMANTICS_TYPES.RX_GET_FIRST_CHART_DATA, {
            data: [],
            labels: [],
            company: '',
        }),
        secondChartData: createAsyncReducer(SEMANTICS_TYPES.RX_GET_SECOND_CHART_DATA, {
            data: [],
            labels: [],
            company: '',
        }),
        thirdChartData: createAsyncReducer(SEMANTICS_TYPES.RX_GET_THIRD_CHART_DATA, {
            data: [],
            labels: [],
            company: '',
        }),
        fourthChartData: createAsyncReducer(SEMANTICS_TYPES.RX_GET_FOURTH_CHART_DATA, {
            data: [],
            labels: [],
            company: '',
        }),
    }),
    chartLayers: createAsyncReducer(SEMANTICS_TYPES.RX_GET_CHART_LAYERS, []),
});
