import React from "react";
import ColorIndicatorSection from "./colorIndicatorSection";
import DownloadSection from "./downLoadSection";

function ColorIndicatorAndDownloadSection({ setOpen, saveAsExcel }) {
    return (
        <div>
           <DownloadSection setOpen={setOpen} saveAsExcel={saveAsExcel} />
           <ColorIndicatorSection />
        </div>
    );
}

export default ColorIndicatorAndDownloadSection;