export const PROJECT_TYPES = {
    RX_GET_HIDDEN_LAYERS: 'RX_GET_HIDDEN_LAYERS',
    RX_GET_HIDDEN_LAYERS_PENDING: 'RX_GET_HIDDEN_LAYERS_PENDING',
    RX_GET_HIDDEN_LAYERS_DONE: 'RX_GET_HIDDEN_LAYERS_DONE',

    RX_GET_HIDDEN_PUBLISHERS: 'RX_GET_HIDDEN_PUBLISHERS',
    RX_GET_HIDDEN_PUBLISHERS_PENDING: 'RX_GET_HIDDEN_PUBLISHERS_PENDING',
    RX_GET_HIDDEN_PUBLISHERS_DONE: 'RX_GET_HIDDEN_PUBLISHERS_DONE',

    RX_GET_PROJECT_DETAILS: 'RX_GET_PROJECT_DETAILS',
    RX_GET_PROJECT_DETAILS_PENDING: 'RX_GET_PROJECT_DETAILS_PENDING',
    RX_GET_PROJECT_DETAILS_DONE: 'RX_GET_PROJECT_DETAILS_DONE',
}