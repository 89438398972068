export const GRAPH_TYPES = {
    RX_GET_FILTERS: 'RX_GET_FILTERS',
    RX_GET_FILTERS_PENDING: 'RX_GET_FILTERS_PENDING',
    RX_GET_FILTERS_DONE: 'RX_GET_FILTERS_DONE',

    RX_GET_NETWORK: 'RX_GET_NETWORK',
    RX_GET_NETWORK_PENDING: 'RX_GET_NETWORK_PENDING',
    RX_GET_NETWORK_DONE: 'RX_GET_NETWORK_DONE',

    RX_GET_ENTITY_DETAILS: 'RX_GET_ENTITY_DETAILS',
    RX_GET_ENTITY_DETAILS_PENDING: 'RX_GET_ENTITY_DETAILS_PENDING',
    RX_GET_ENTITY_DETAILS_DONE: 'RX_GET_ENTITY_DETAILS_DONE',
}
