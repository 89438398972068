import React from "react";
import {useSelector} from "react-redux";

function getPercentBlockHeight(rowCount, totalCount) {
    if(!rowCount) {
        return 0;
    }

    return Math.round(rowCount / totalCount * 40);
}

function PercentSection({ firstRowCount, secondRowCount, thirdRowCount, fourthRowCount, className }) {
    const secondBlockHeight = getPercentBlockHeight(secondRowCount, firstRowCount);
    const thirdBlockHeight = getPercentBlockHeight(thirdRowCount, firstRowCount);
    const fourthBlockHeight = getPercentBlockHeight(fourthRowCount, firstRowCount);

    return (
        <div className={`percent-section ${className}`}>
            <div className="percent-section-block" style={{height: 40}}>
            </div>
            <div className="percent-section-block" style={{height: secondBlockHeight}}>
            </div>
            <div className="percent-section-block" style={{height: thirdBlockHeight}}>
            </div>
            <div className="percent-section-block" style={{height: fourthBlockHeight}}>
            </div>
        </div>
    );
}

export default PercentSection;