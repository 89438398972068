import React, {useEffect, useState} from "react";
import SubjectSelect from "./subjectSelect";
import plusIcon from "../../../images/btnPlus.svg";
import {useSelector} from "react-redux";
import ChartFilterRow from "./chartFilterRow";
import moment from "moment";
import UseSemanticsController from "../../../customHooks/controllerHooks/semanticController";
import UseSavedSettingsController from "../../../customHooks/controllerHooks/savedSettingsController";

const formatDateForApi = date => moment(date).format('MM/DD/YYYY');

const now = new Date();
const oneYearBefore = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

const startDate = formatDateForApi(oneYearBefore);
const endDate = formatDateForApi(now);

const initialState = {
    company: '',
    firstLayer: '',
    secondLayer: '',
    thirdLayer: '',
    fourthLayer: '',
    fifthLayer: '',
};

function ChartSearchSection({ open }) {

    const selectedSubject = useSelector(state => state.userInfo.data.projects[0] || '');
    const [ subjectSelectedValue, setSubjectSelectedValue ] = useState(selectedSubject);
    const chartSettings = useSelector(state => state.savedSettings.chartSettings);
    const firstChartAllLayers = useSelector(state => state.semantics.semantics.firstChartAllLayers.data);
    const secondChartAllLayers = useSelector(state => state.semantics.semantics.secondChartAllLayers.data);
    const thirdChartAllLayers = useSelector(state => state.semantics.semantics.thirdChartAllLayers.data);
    const fourthChartAllLayers = useSelector(state => state.semantics.semantics.fourthChartAllLayers.data);

    const { saveChartSettings } = UseSavedSettingsController();
    const {
        getFirstChartAllLayers,
        getFirstChartData,
        getSecondChartAllLayers,
        getSecondChartData,
        getThirdChartAllLayers,
        getThirdChartData,
        getFourthChartAllLayers,
        getFourthChartData,
        getChartCompaniesAndLayers
    } = UseSemanticsController();

    const [ firstRowValues, setFirstRowValues ] = useState({ ...initialState });
    const [ secondRowValues, setSecondRowValues ] = useState({ ...initialState });
    const [ thirdRowValues, setThirdRowValues ] = useState({ ...initialState });
    const [ fourthRowValues, setFourthRowValues ] = useState({ ...initialState });

    const [ showSecondRow, setShowSecondRow ] = useState(false);
    const [ showThirdRow, setShowThirdRow ] = useState(false);
    const [ showFourthRow, setShowFourthRow ] = useState(false);

    const handlePlusIconClick = () => {
        if(!showSecondRow) {
            setShowSecondRow(true);
        } else if (!showThirdRow) {
            setShowThirdRow(true);
        } else {
            setShowFourthRow(true);
        }
    }

    const handleFirstRowMinusIconClick = () => {
        setFirstRowValues({...initialState});
        setSecondRowValues({...initialState});
        setThirdRowValues({...initialState});
        setFourthRowValues({...initialState});
        setShowSecondRow(false);
        setShowThirdRow(false);
        setShowFourthRow(false);
    }

    const handleSecondRowMinusIconClick = () => {
        setSecondRowValues({...initialState});
        setThirdRowValues({...initialState});
        setFourthRowValues({...initialState});
        setShowSecondRow(false);
        setShowThirdRow(false);
        setShowFourthRow(false);
    }

    const handleThirdRowMinusIconClick = () => {
        setThirdRowValues({...initialState});
        setFourthRowValues({...initialState});
        setShowThirdRow(false);
        setShowFourthRow(false);
    }

    const handleFourthRowMinusIconClick = () => {
        setFourthRowValues({...initialState});
        setShowFourthRow(false);
    }

    const changeSubjectSelectedValue = value => {
        const valuesToSave = {
            firstRowValues: {...firstRowValues},
            secondRowValues: {...secondRowValues},
            thirdRowValues: {...thirdRowValues},
            fourthRowValues: {...fourthRowValues},
        }
        saveChartSettings(subjectSelectedValue, valuesToSave)
        setSubjectSelectedValue(value);
    }

    useEffect(() => {
        if(subjectSelectedValue) {
            getChartCompaniesAndLayers({project: subjectSelectedValue, startDate, endDate});
            // getChartLayers({project: subjectSelectedValue, startDate, endDate});
            if(chartSettings[subjectSelectedValue]) {
                setFirstRowValues({...chartSettings[subjectSelectedValue].firstRowValues});
                setSecondRowValues({...chartSettings[subjectSelectedValue].secondRowValues});
                setThirdRowValues({...chartSettings[subjectSelectedValue].thirdRowValues});
                setFourthRowValues({...chartSettings[subjectSelectedValue].fourthRowValues});

                if (chartSettings[subjectSelectedValue].fourthRowValues.company){
                    !showFourthRow && setShowFourthRow(true);
                    !showSecondRow && setShowSecondRow(true);
                    !showThirdRow && setShowThirdRow(true);
                } else if(chartSettings[subjectSelectedValue].thirdRowValues.company){
                    !showSecondRow && setShowSecondRow(true);
                    !showThirdRow && setShowThirdRow(true);
                    showFourthRow && setShowFourthRow(false);
                } else if (chartSettings[subjectSelectedValue].secondRowValues.company) {
                    !showSecondRow && setShowSecondRow(true);
                    showThirdRow && setShowThirdRow(false);
                    showFourthRow && setShowFourthRow(false);
                } else {
                    showSecondRow && setShowSecondRow(false);
                    showThirdRow && setShowThirdRow(false);
                    showFourthRow && setShowFourthRow(false);
                }
            } else {
                handleFirstRowMinusIconClick()
            }
        }
    }, [subjectSelectedValue]);

    useEffect(() => {
        const  { firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company } = firstRowValues;
        getFirstChartData(firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company );
    }, [firstRowValues.firstLayer, firstRowValues.secondLayer, firstRowValues.thirdLayer, firstRowValues.fourthLayer, firstRowValues.fifthLayer, firstChartAllLayers]);

    useEffect(() => {
        const  { firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company } = secondRowValues;
        getSecondChartData(firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company );
    }, [secondRowValues.firstLayer, secondRowValues.secondLayer, secondRowValues.thirdLayer, secondRowValues.fourthLayer, secondRowValues.fifthLayer, secondChartAllLayers]);

    useEffect(() => {
        const  { firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company } = thirdRowValues;
        getThirdChartData(firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company );
    }, [thirdRowValues.firstLayer, thirdRowValues.secondLayer, thirdRowValues.thirdLayer, thirdRowValues.fourthLayer, thirdRowValues.fifthLayer, thirdChartAllLayers]);

    useEffect(() => {
        const  { firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company } = fourthRowValues;
        getFourthChartData(firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company );
    }, [fourthRowValues.firstLayer, fourthRowValues.secondLayer, fourthRowValues.thirdLayer, fourthRowValues.fourthLayer, fourthRowValues.fifthLayer, fourthChartAllLayers]);

    useEffect(() => {
        if(firstRowValues.company === 'All') {
            getFirstChartAllLayers({project: subjectSelectedValue, startDate, endDate})
        } else if(firstRowValues.company) {
            getFirstChartAllLayers({project: subjectSelectedValue, startDate, endDate, company: firstRowValues.company})
        }
    }, [firstRowValues.company])

    useEffect(() => {
        if(secondRowValues.company === 'All') {
            getSecondChartAllLayers({project: subjectSelectedValue, startDate, endDate})
        } else if(secondRowValues.company) {
            getSecondChartAllLayers({project: subjectSelectedValue, startDate, endDate, company: secondRowValues.company});
        }
    }, [secondRowValues.company])

    useEffect(() => {
        if(thirdRowValues.company === 'All') {
            getThirdChartAllLayers({project: subjectSelectedValue, startDate, endDate})
        } else if(thirdRowValues.company) {
            getThirdChartAllLayers({project: subjectSelectedValue, startDate, endDate, company: thirdRowValues.company});
        }
    }, [thirdRowValues.company])

    useEffect(() => {
        if(fourthRowValues.company === 'All') {
            getFourthChartAllLayers({project: subjectSelectedValue, startDate, endDate})
        } else if(fourthRowValues.company) {
            getFourthChartAllLayers({project: subjectSelectedValue, startDate, endDate, company: fourthRowValues.company});
        }
    }, [fourthRowValues.company])



    return(
        <div className={open? "" : "closed"}>
            <div className="chart-subject-select">
                <SubjectSelect subjectSelectedValue={subjectSelectedValue}
                               setSubjectSelectedValue={changeSubjectSelectedValue}/>
            </div>
            <div className="chart-section-filters">
                <div className="search-filter-row-title">
                    Company
                </div>
                <div className="chart-filters-section" key={subjectSelectedValue}>
                    <ChartFilterRow key={`${subjectSelectedValue}-1`}
                        values={firstRowValues}
                        setValues={setFirstRowValues}
                        showLayersHeader={true}
                        handleClose={handleFirstRowMinusIconClick}
                        selectedSubject={subjectSelectedValue}
                    />
                    {showSecondRow &&
                    <ChartFilterRow key={`${subjectSelectedValue}-2`}
                        values={secondRowValues}
                        setValues={setSecondRowValues}
                        handleClose={handleSecondRowMinusIconClick}
                    />}
                    {showThirdRow &&
                    <ChartFilterRow key={`${subjectSelectedValue}-3`}
                        values={thirdRowValues}
                        setValues={setThirdRowValues}
                        handleClose={handleThirdRowMinusIconClick}
                    />}
                    {showFourthRow &&
                    <ChartFilterRow key={`${subjectSelectedValue}-4`}
                                    values={fourthRowValues}
                                    setValues={setFourthRowValues}
                                    handleClose={handleFourthRowMinusIconClick}
                    />}
                    {!showFourthRow &&
                    <div className="chart-filters-row">
                        <div className="icon-container icon-light" >
                            <img src={plusIcon} alt="" onClick={handlePlusIconClick}/>
                        </div>
                    </div>}
                </div>

            </div>
        </div>

    )
}

export default ChartSearchSection;
