import {GRAPH_TYPES} from "../actionTypes/graphActionTypes";

export const rxGetFiltersPending = payload => ({
    type: GRAPH_TYPES.RX_GET_FILTERS_PENDING,
    payload,
});

export const rxGetFiltersDone = payload => ({
    type: GRAPH_TYPES.RX_GET_FILTERS_DONE,
    payload,
});

export const rxGetNetworkPending = payload => ({
    type: GRAPH_TYPES.RX_GET_NETWORK_PENDING,
    payload,
});

export const rxGetNetworkDone = payload => ({
    type: GRAPH_TYPES.RX_GET_NETWORK_DONE,
    payload,
});

export const rxGetEntityDetailsPending = payload => ({
    type: GRAPH_TYPES.RX_GET_ENTITY_DETAILS_PENDING,
    payload,
});

export const rxGetEntityDetailsDone = payload => ({
    type: GRAPH_TYPES.RX_GET_ENTITY_DETAILS_DONE,
    payload,
});

