const abortRegistry = {};

export const ABORT_API_KEYS = {
    getFirstColumnV5: 'getFirstColumnV5',
    getSecondColumnV5: 'getSecondColumnV5',
    getThirdColumnV5: 'getThirdColumnV5',
    getFourthColumnV5: 'getFourthColumnV5',
    getFirstColumnV3: 'getFirstColumnV3',
    getSecondColumnV3: 'getSecondColumnV3',
    getThirdColumnV3: 'getThirdColumnV3',
    getFourthColumnV3: 'getFourthColumnV3',
    getContestDetails: 'getContestDetails',
    getChartCompanies: 'getChartCompanies',
    getFirstChartAllLayers: 'getFirstChartAllLayers',
    getSecondChartAllLayers: 'getSecondChartAllLayers',
    getThirdChartAllLayers: 'getThirdChartAllLayers',
    getFourthChartAllLayers: 'getFourthChartAllLayers',
    getPublishersFirstColumn: 'getPublishersFirstColumn',
    getPublishersSecondColumn: 'getPublishersSecondColumn',
    getPublishersThirdColumn: 'getPublishersThirdColumn',
    getPublishersFourthColumn: 'getPublishersFourthColumn',
    getPublisherFilterFirstColCoverage: 'getPublisherFilterFirstColCoverage',
    getPublisherFilterSecondColCoverage: 'getPublisherFilterSecondColCoverage',
    getPublisherFilterThirdColCoverage: 'getPublisherFilterThirdColCoverage',
    getPublisherFilterFourthColCoverage: 'getPublisherFilterFourthColCoverage',
    getPublisherFilterSecondColCompanies: 'getPublisherFilterSecondColCompanies',
    getPublisherFilterThirdColCompanies: 'getPublisherFilterThirdColCompanies',
    getPublisherFilterFourthColCompanies: 'getPublisherFilterFourthColCompanies'
};

export const abortFetch = apiName => {
    const abortController = abortRegistry[apiName];
    if (abortController) {
        abortController.abort();
    }
    abortRegistry[apiName] = new AbortController();
    return abortRegistry[apiName].signal;
};

export const abort = apiName => {
    const abortController = abortRegistry[apiName];
    if (abortController) {
        abortController.abort();
    }
    abortRegistry[apiName] = null;
}