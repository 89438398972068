import Operations from "./operations";
import loginApiHandler from "../apiHandlers/loginApiHandler";
import Fetch from "../fetch";

class LoginOperations extends Operations {

    constructor(loginApiHandler) {
        super();
        this.loginApiHandler = loginApiHandler;
        this.refreshToken = localStorage.getItem('refreshToken');
    }

    async login(payload) {
        const response =  await this.loginApiHandler.login(payload);
        if(response && response.accessToken){
            Fetch.token = response.accessToken;
            this.refreshToken = response.refreshToken;
            localStorage.setItem('refreshToken', response.refreshToken);
            localStorage.setItem('email', payload.email);
            // setTimeout(this.refresh, this.timer);
        }
        return response;
    }

    async refresh(email) {
        const response = await this.loginApiHandler.refresh({
            email,
            refreshToken: this.refreshToken,
        });
        if(response && response.accessToken){
            Fetch.token = response.accessToken;
            this.refreshToken = response.refreshToken;
            localStorage.setItem('refreshToken', response.refreshToken);
        }
        return response;
    }

    async getUserInfo(queryData) {
        return await this.loginApiHandler.getUserInfo(queryData);
    }

    async getCompanyInfo(queryData) {
        return await this.loginApiHandler.getCompanyInfo(queryData);
    }

    async getAllPlans () {
        return await this.loginApiHandler.getAllPlans();
    }

    async getCompanyData (queryData) {
        return await this.loginApiHandler.getCompanyData(queryData);
    }

    async uploadCompanyImage(payload) {
        return await this.loginApiHandler.uploadCompanyImage(payload);
    }

    logout() {
        Fetch.token = '';
        this.refreshToken = '';
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('email');
    }

    async checkWarnings(queryData) {
        return await this.loginApiHandler.checkWarnings(queryData);
    }

    async updatePlan(queryData) {
        return await this.loginApiHandler.updatePlan(queryData);
    }

    async cancelPlan(queryData) {
        return await this.loginApiHandler.cancelPlan(queryData);
    }

    async verifyAccount(queryData) {
        return await this.loginApiHandler.verifyAccount(queryData);
    }
}

const loginOperations= new LoginOperations(loginApiHandler);

export default loginOperations;
