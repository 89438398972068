import Fetch from "../fetch";
import {ABORT_API_KEYS, abortFetch} from "../operations/abortFetch";

const END_POINT = {
    prefix: 'publisher',
    occurrences: 'occurrences',
};

class PublisherApiHandler {

    async getPublishers(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublishers);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal);
    }

    async getChartCompanies(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getChartCompanies);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal);
    }

    async getPublisherOccurrences(queryData, abortSignal) {
        const relativePath = `${END_POINT.prefix}/${END_POINT.occurrences}`
        return await Fetch.get(relativePath, queryData, abortSignal);
    }
}

const publisherApiHandler = new PublisherApiHandler();
export default publisherApiHandler;