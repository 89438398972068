import React, {useEffect, useState} from "react";
import TableTitle from "./tableTitle";
import TableFirstRow from "./tableFirstRow";
import TableLeftBlock from "./tableLeftBlock";
import PercentSection from "./percentSection";
import OutcomeTable from "./outcomeTable";
import ColorIndicatorAndDownloadSection from "./colorIndicatorAndDownloadSection";
import {useSelector} from "react-redux";
import HeatmapLoader from "./heatmapLoader";
import DownloadInfoPopup from "./downloadInfoPopup";
import UseProjectController from "../../../customHooks/controllerHooks/projectController";
import useDownload from "../../../customHooks/useDownload";


function Heatmap({ heatmapLoading, setHeatmapLoading }) {
    const firstColumnLoading = useSelector(state => state.semantics.semantics.firstColumnV3.isLoading);
    const secondColumnLoading = useSelector(state => state.semantics.semantics.secondColumnV3.isLoading);
    const thirdColumnLoading = useSelector(state => state.semantics.semantics.thirdColumnV3.isLoading);
    const fourthColumnLoading = useSelector(state => state.semantics.semantics.fourthColumnV3.isLoading);
    const loading = firstColumnLoading || secondColumnLoading || thirdColumnLoading || fourthColumnLoading;

    const [ open, setOpen ] = useState(false);

    const [ showHiddenLayers, setShowHiddenLayers ] = useState(false);
    const hiddenLayers = useSelector(state => state.hiddenLayers.data.layers);
    const project = useSelector(state => state.hiddenLayers.data.name);
    const firstColumn = useSelector(state => state.semantics.semantics.firstColumnV3.data.layers);
    const secondColumn = useSelector(state => state.semantics.semantics.secondColumnV3.data.layers);
    const thirdColumn = useSelector(state => state.semantics.semantics.thirdColumnV3.data.layers);
    const fourthColumn = useSelector(state => state.semantics.semantics.fourthColumnV3.data.layers);
    const totalArticlesCount = useSelector(state => state.semantics.semantics.firstColumnV3.data.totalArticlesCount);
    const firstColumnArticlesCount = useSelector(state => state.semantics.semantics.firstColumnV3.data.filteredArticlesCount);
    const secondColumnArticlesCount = useSelector(state => state.semantics.semantics.secondColumnV3.data.filteredArticlesCount);
    const thirdColumnArticlesCount = useSelector(state => state.semantics.semantics.thirdColumnV3.data.filteredArticlesCount);
    const fourthColumnArticlesCount = useSelector(state => state.semantics.semantics.fourthColumnV3.data.filteredArticlesCount);
    const { changeHiddenLayers } = UseProjectController();
    const { saveHeatmap: saveAsExcel } = useDownload();

    useEffect(() => {
        !loading && setHeatmapLoading(false);
    }, [loading]);



    return(
        <>
            <TableTitle />
            <div className="outer-table">
                <TableFirstRow
                    totalArticlesCount={totalArticlesCount}
                    firstColumnCount={firstColumnArticlesCount}
                    secondColumnCount={secondColumnArticlesCount}
                    thirdColumnCount={thirdColumnArticlesCount}
                    fourthColumnCount={fourthColumnArticlesCount}
                />
                <div className="inner-table-wrapper">
                    <TableLeftBlock withDivider/>
                    <div className="table-right-block">
                        {(heatmapLoading || firstColumnLoading) && <HeatmapLoader />}
                        <PercentSection
                            firstRowCount={firstColumnArticlesCount}
                            secondRowCount={secondColumnArticlesCount}
                            thirdRowCount={thirdColumnArticlesCount}
                            fourthRowCount={fourthColumnArticlesCount}
                            className="heatmap-percent-section"
                        />
                        <OutcomeTable
                            showHiddenLayers={showHiddenLayers}
                            setShowHiddenLayers={setShowHiddenLayers}
                            hiddenLayers={hiddenLayers}
                            project={project}
                            firstColumn={firstColumn}
                            secondColumn={secondColumn}
                            thirdColumn={thirdColumn}
                            fourthColumn={fourthColumn}
                            firstColumnArticlesCount={firstColumnArticlesCount}
                            secondColumnArticlesCount={secondColumnArticlesCount}
                            thirdColumnArticlesCount={thirdColumnArticlesCount}
                            fourthColumnArticlesCount={fourthColumnArticlesCount}
                            changeHiddenLayers={changeHiddenLayers}
                        />
                    </div>
                </div>
            </div>
            <ColorIndicatorAndDownloadSection setOpen={setOpen} saveAsExcel={saveAsExcel}/>
            {open && <DownloadInfoPopup setOpen={setOpen} subject="heatmap"/>}
        </>
    );
}

export default Heatmap;