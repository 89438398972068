import React from 'react';
import DowngradeConfirmIcon from './downgradeConfirmIcon';

function DowngradeToProAbort({ onClose }) {
  return (
    <>
      <DowngradeConfirmIcon />
      <div className="downgrade-confirm-text">
        Mit dem PLUS Account steht Ihnen eine grosse Auswahl<br />
        von Analysemöglichkeiten und bis zu [number of <span>5</span> zur<br />
        Verfügung. Wir freuen uns, dass Sie sich weiter für<br />
        Entdeckungsreisen in Ihren Daten begeistern lassen!
      </div>
      <div className="plan-update-popup-buttons">
        <div className="plan-update-popup-button" onClick={onClose}>
          in Ordnung
        </div>
      </div>
    </>
  );
}

export default DowngradeToProAbort;
