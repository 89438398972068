import React from 'react';
import roundIcon from '../../../images/roundIcon.png';
import blackConfirmIcon from '../../../images/blackConfirmIcon.png';

function DowngradeConfirmIcon() {
  return (
    <div className="downgrade-confirm-icon">
      <img src={roundIcon} />
      <img src={blackConfirmIcon} className="black-confirm-icon"/>
    </div>
  );
}

export default DowngradeConfirmIcon;
