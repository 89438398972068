import createAsyncReducer from "../createAsyncReducer";
import {USER_TYPES} from "../actionTypes/userActionTypes";

export const userInfoReducer = createAsyncReducer(USER_TYPES.RX_GET_USER_INFO, {
    email: '',
    projects: [],
    company: '',
});

export const loginInfoReducer = createAsyncReducer(USER_TYPES.RX_GET_LOGIN_INFO, {
    token: false,
    error: false,
    email: '',
});

export const companyInfoReducer = createAsyncReducer(USER_TYPES.RX_GET_COMPANY_INFO, {
    city: '',
    country: '',
    name: '',
    postalCode: '',
    regionState: '',
    streetBuilding: '',
});

export const allPlansReducer = createAsyncReducer(USER_TYPES.RX_GET_ALL_PLANS, []);

export const companyDataReducer = createAsyncReducer(USER_TYPES.RX_GET_COMPANY_DATA, {
    name: '',
    users: [],
    projects: [],
    plan: {},
});
