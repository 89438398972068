import React from 'react';
import PLAN_UPDATE_MODAL_CONSTANTS from "../../../core/constants/planUpdateModalConstants";

function LargeToPlusWarning({ setModalState, onClose }) {

  const handleConfirm = () => {
    setModalState(PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_SELECT);
  }
  return (
    <>
        <div className="downgrade-title">
          Wenn Sie Ihr Downgrade durchführen <br />
          verlieren Sie an Funktionalität.
        </div>
        <div className="downgrade-text warning">
            Sie können jetzt bis zu <span>10</span> für Ihre Analysen wählen. <br />
          Wenn Sie mehr als <span>5</span> Projekte eingerichtet haben, müssen Sie sich im <br />
          nächsten Schritt entscheiden, welche Projekte Sie behalten wollen. <br />
          Statt den <span>50</span> stehen Ihnen nach dem Downgrade auch nur noch <span>10</span> <br />
          Downloads zur Verfügung.
        </div>
        <div className="downgrade-text">
          Sind Sie sicher?
        </div>
        <div className="plan-update-popup-buttons">
          <div className="plan-update-popup-button" onClick={handleConfirm}>
            Ja
          </div>
          <div className="plan-update-popup-button" onClick={onClose}>
            Abbrechen
          </div>
        </div>
    </>
  );
}

export default LargeToPlusWarning;
