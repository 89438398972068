import React, {useState} from 'react';
import greenIcon from "../../../images/greenIcon.png";
import greyConfirmIcon from "../../../images/greyConfirmIcon.png";
import PLAN_UPDATE_MODAL_CONSTANTS from "../../../core/constants/planUpdateModalConstants";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";
import {useSelector} from "react-redux";

const PROJECTS = [
  'ProjectName 1',
  'ProjectName 6',
  'ProjectName 2',
  'ProjectName 7',
  'ProjectName 3',
  'ProjectName 8',
  'ProjectName 4',
  'ProjectName 9',
  'ProjectName 5',
  'ProjectName 10',
];

function DowngradeSelect({ setModalState, onClose, projects, selectedProjects, setSelectedProjects }) {
  // const [ projects, setProjects ] = useState(PROJECTS);
  // const [ selectedProjects, setSelectedProjects ] = useState([]);
  const [ showErrorMessage, setShowErrorMessage ] = useState(false);

  const handleClick = project => {
      if(selectedProjects.includes(project)) {
          setSelectedProjects(selectedProjects.filter(item => item !== project));
          if(showErrorMessage) {
              setShowErrorMessage(false);
          }

      } else if(selectedProjects.length === 5) {
          setShowErrorMessage(true);
      } else {
          setSelectedProjects([...selectedProjects, project]);
      }
  };

  const handleConfirm = () => {
      if(selectedProjects.length > 0) {
          setModalState(PLAN_UPDATE_MODAL_CONSTANTS.SELECTED_PROJECTS);
      } else {

      }
  }

  return (
    <>
      <div className="downgrade-title">
          Bitte wählen Sie bis zu 5 Projekte aus, die sie <br /> behalten wollen
      </div>

      <div className="downgrade-select-section">
        {
          projects.map(project => {
            const selected = selectedProjects.includes(project);
            return (
              <div className="downgrade-select-item" key={project} onClick={()=>handleClick(project)}>
                <img src={selected? greenIcon: greyConfirmIcon} />
                <span>{project}</span>
              </div>
            )
          })
        }
      </div>

        {
            showErrorMessage &&
            <div className="downgrade-text warning">
                Sie haben schon 5 Projekte ausgewählt
            </div>
        }


      <div className="plan-update-popup-buttons">
        <div className="plan-update-popup-button" onClick={onClose}>
          Abbrechen
        </div>
        <div className="plan-update-popup-button" onClick={handleConfirm}>
          Bestätigen
        </div>
      </div>
    </>

  );
}

export default DowngradeSelect;
