import Operations from "./operations";
import companyApiHandler from "../apiHandlers/companyApiHandler";

class CompanyOperations extends Operations {
    constructor(companyApiHandler) {
        super();
        this.companyApiHandler = companyApiHandler;
    }

    async getPublisherFilterCompanies(queryData, abortSignal) {
        return await this.companyApiHandler.getPublisherFilterCompanies(queryData, abortSignal);
    }
}

const companyOperations = new CompanyOperations(companyApiHandler);

export default companyOperations;