import { CONTENT_TYPES } from "../constants/contentTypes";
import { HEADER_TYPES } from "../constants/headerTypes";

function buildHeaders(contentType = CONTENT_TYPES.applicationJson,
                      includeTimeZone = true,
                      token,
                      additionalHeaders
                      ) {
    let headers = {};

    // if (includeTimeZone) {
    //     headers = {
    //         [HEADER_TYPES.timeZone]: (new Date().getTimezoneOffset() / (-60)).toString(),
    //         ...headers
    //     };
    // }

    if (token && token.length > 0) {
        headers = {
            [HEADER_TYPES.authorization]: `${token}`,
            ...headers
        };
    }

    switch (contentType) {
        case CONTENT_TYPES.applicationJson:
            headers = {
                [HEADER_TYPES.contentType]: CONTENT_TYPES.applicationJson,
                ...headers
            };
            break;
        case CONTENT_TYPES.formData:
            // content type should be empty for this type
            break;
    }

    if(additionalHeaders){
        headers = {
            ...headers,
            ...additionalHeaders
        }
    }

    return headers;
}

export default buildHeaders;
