import React, {useEffect, useRef, useState} from "react";
import Dygraph from "dygraphs";

function DygraphChart({ chartData }) {
    const [ chartDisplay, setChartDisplay ] = useState(false);
    const chartRef = useRef(null);
    const labelRef = useRef(null);
    const display = chartDisplay ? 'flex': 'none';

    useEffect(() => {
        if(chartData.data.length) {
            !chartDisplay && setChartDisplay(true);
            new Dygraph(chartRef.current,
                chartData.data,
                {
                    labels: ['x', ...chartData.labels],
                    labelsDiv: labelRef.current,
                    labelsSeparateLines: true,
                    width: 1000,
                    height: 200,
                    showRangeSelector: true,
                    rangeSelectorHeight: 37,
                    rightGap: 10,
                    axisLineColor: '#000000',
                    ylabel: 'number of articles',
                    colors: ['#FA6400', '#078820', '#0091FF', '#446792', '#09859C' ],
                    // series:{
                    //     "RangeSelector" : {
                    //         showInRangeSelector : true,
                    //     }
                    // }
                });
        } else {
            chartDisplay && setChartDisplay(false);
            new Dygraph(chartRef.current,
                [[0]],
                {
                    labels: [ "x"],
                    width: 1000,
                    height: 200,
                    colors: ['#FA6400', '#078820', '#0091FF', '#446792', '#09859C'],
                    // series:{
                    //     "RangeSelector" : {
                    //         showInRangeSelector : true,
                    //     }
                    // }
                });
        }
    }, [chartData]);

    return(
        <>
            <div className="dygraph-chart-title">{chartData.title}</div>
            <div style={{ display, marginBottom: 50, position: 'relative' }} >
                <div ref={chartRef} />
                <div ref={labelRef} style={{fontSize: 14}}/>
            </div>
        </>
    );
}

export default DygraphChart;