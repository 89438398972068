import React, {useEffect, useState} from 'react';
import uploadIcon from "../../../images/uploadIcon.png";
import {useSelector} from "react-redux";
import moment from "moment";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";

function UserInfo() {

    const { getCompanyInfo, uploadCompanyImage } = UseLoginController();

    const userInfo = useSelector(state => state.userInfo.data);

    const [ imageSrc, setImageSrc ] = useState(null);

    const [ isEdit, setIsEdit ] = useState(false);

    const [ errorMessage, setErrorMessage ] = useState(false);

    const companyName = useSelector(state => state.userInfo.data.company);

    useEffect(() => {
        getCompanyInfo({ name: companyName})
    }, []);

    const image = useSelector(state => state.companyInfo.data.image);

    useEffect(() => {
        if(image) {
            setImageSrc(`data:image/png;base64, ${image}`);
        }
    }, [image]);


    const handleFileChange  = event => {
        const files = event.target.files;
        const formData = new FormData;
        const file = files[0];
        const  fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            setErrorMessage(true);
            return;
        }
        console.log(files[0]);

        formData.append('file', file);
        uploadCompanyImage({
            company: companyName,
            data: formData
        })
            .then((res) => {
                if (!res) {
                    getCompanyInfo({ name: companyName});
                    errorMessage && setErrorMessage(false);
                }
            })
        // setImageSrc(URL.createObjectURL(files[0]));
    }


    return (
        <div className="user-info">
            <div className="user-info-bar">
                <div className="user-info-bar-text">
                    My Account
                </div>
            </div>
            <div className="image-section">
                {
                    imageSrc ?
                        <>
                            <img src={imageSrc} />
                            <div className="change-text" onClick={() => setImageSrc(null)}>Change</div>
                        </> :
                        <>
                            <input type="file" name="file" id="file" className="input-file" onChange={handleFileChange}/>
                            <label htmlFor="file" className="upload-image-text">
                                Upload image
                            </label>
                            {
                                errorMessage ?
                                    <div className="upload-error-text">
                                        Bitte laden Sie ein <br/>
                                        Bild im Format <br />
                                        164 x 164 pxl <br />
                                        als jpg oder png.
                                    </div> :
                                    <>
                                        <div className="upload-icon">
                                            <img src={uploadIcon} alt=""/>
                                        </div>
                                        <div className="upload-size">
                                            164x164px
                                        </div>
                                    </>
                            }

                        </>
                }


            </div>
            <div className="info-section">
                <div className="info-section-item">
                    <div className="info-section-item-title">
                        Registered email address
                    </div>
                    <div className="info-section-item-description">
                        {userInfo.email}
                    </div>
                </div>
                <div className="info-section-item">
                    <div className="info-section-item-title">
                        Corporation
                    </div>
                    <div className="info-section-item-description">
                        {userInfo.company}
                    </div>
                </div>
                <div className="info-section-item">
                    <div className="info-section-item-title">
                        Last time of login
                    </div>
                    <div className="info-section-item-description">
                        {!!userInfo.lastLoginTime && moment(userInfo.lastLoginTime).format('DD/MM/YY')}
                        {/*<span>(23:23:23)</span>*/}
                    </div>
                </div>
                <div className="info-section-item">
                    <div className="info-section-item-title">
                        Active plan
                    </div>
                    <div className="info-section-item-description">
                        {userInfo.planName}
                    </div>
                </div>
            </div>
            {/*<div className="info-section">*/}
            {/*    <div className="info-section-item">*/}
            {/*        <div className="info-section-item-title">*/}
            {/*            Registered email address*/}
            {/*        </div>*/}
            {/*        <input className="info-section-item-description" value="davit@evai-intelligence.com" />*/}
            {/*    </div>*/}
            {/*    <div className="info-section-item">*/}
            {/*        <div className="info-section-item-title">*/}
            {/*            Corporation*/}
            {/*        </div>*/}
            {/*        <input className="info-section-item-description" value="evAI intelligence" />*/}
            {/*    </div>*/}

            {/*    <div className="info-section-item">*/}
            {/*        <div className="info-section-item-title">*/}
            {/*            Active plan*/}
            {/*        </div>*/}
            {/*        <input className="info-section-item-description" value="Pro" />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default UserInfo;
