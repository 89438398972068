import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const defaultPath = '/';

const IsNotAuthLayer = ({ children }) => {
    const isAuth = useSelector(state => state.loginInfo.data.token);
    return (
        <>
            {!isAuth ? children : <Redirect to={defaultPath}/>}
        </>
    )
};

export default IsNotAuthLayer;