import React from 'react';

function FilterTableRow({ name, count, handleClick, selectedValue }) {
    return (
        <tr onClick={handleClick} className={name===selectedValue? 'selected': ''}>
            <td className="table-name">{name}</td>
            <td className="table-count">{count}</td>
        </tr>
    )
}

export default FilterTableRow;