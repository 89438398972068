import Fetch from "../fetch";

const END_POINT = {
    prefix: 'company',
    coverage: 'coverage',
};

class CompanyApiHandler {
    async getPublisherFilterCompanies(queryData, abortSignal) {
        const relativePath = `${END_POINT.prefix}/${END_POINT.coverage}`;
        return await Fetch.get(relativePath, queryData, abortSignal);
    }
}

const companyApiHandler = new CompanyApiHandler();
export default companyApiHandler;