import {useDispatch} from "react-redux";
import {rxGetCompaniesDone, rxGetCompaniesPending} from "../../state-management/actions/semanticsActions";
import companyOperations from "../../services/operations/companyOperations";
import {
    rxGetPublisherFilterFourthColCompaniesDone,
    rxGetPublisherFilterFourthColCompaniesPending, rxGetPublisherFilterSecondColCompaniesDone,
    rxGetPublisherFilterSecondColCompaniesPending, rxGetPublisherFilterThirdColCompaniesDone,
    rxGetPublisherFilterThirdColCompaniesPending
} from "../../state-management/actions/publisherActions";
import apiOperations from "../../services/operations/apiOperations";
import {
    rxGetArticleEntitiesDone,
    rxGetArticleEntitiesPending, rxGetArticleSemanticsDone,
    rxGetArticleSemanticsPending
} from "../../state-management/actions/apiActions";

function UseApiController() {
    const dispatch = useDispatch();

    async function getArticleEntities(data, language) {
        dispatch(rxGetArticleEntitiesPending());
        const response = await apiOperations.getArticleEntities(data, language);
        dispatch(rxGetArticleEntitiesDone(response));
    }

    async function getArticleSemantics(data, language) {
        dispatch(rxGetArticleSemanticsPending());
        const response = await apiOperations.getArticleSemantics(data, language);
        dispatch(rxGetArticleSemanticsDone(response));
    }

    return {
        getArticleEntities,
        getArticleSemantics
    }


}

export default UseApiController;