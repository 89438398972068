import React from "react";
import plusIcon from "../../../images/btnPlus.svg";

function FilterRowPlusIcon({ handleClick }) {
    return (
        <div className="search-filter-row">
            <div className="icon-container minus-icon plus-icon-light" onClick={handleClick}>
                <img src={plusIcon} alt=""/>
            </div>
        </div>
    )
}

export default FilterRowPlusIcon;