import React, { useState } from "react";
import useDidUpdateEffect from "../../../customHooks/useDidUpdateEffect";
import openIcon from '../../../images/openIcon.png';
import closeIcon from '../../../images/closeArrowIcon.png';

function EntityDescription({ title, layers, isContext}) {

    const [ open, setOpen ] = useState(false);
    useDidUpdateEffect(() => {
        if(open){
            setOpen(false);
        }
    }, [layers])
    return (
        <>
            <div className="entity-title title" >
                {title}
                <img src={open? closeIcon : openIcon} onClick={() => setOpen(prev => !prev)} />
            </div>
            <div className={`graph-entity-description ${open? 'open': ''}`}>
                {
                    layers.map(layer => {
                        return (
                            <React.Fragment key={layer}>
                                {
                                    isContext? <>
                                                <div>{layer}</div>
                                                <hr />
                                             </>:
                                        <span>{layer}</span>
                                }
                            </React.Fragment>

                        )
                    })
                }
            </div>
            {
                !open &&
                <div className="entity-dots">...</div>
            }

        </>
    );
}

export default EntityDescription;