import React from "react";

function CancelAccountConfirm({ onClose }) {
    return (
        <>
            <div className="plan-update-content">
                <div className="cancel-title">
                    Ihr Konto wurde erfolgreich angehalten!
                </div>
                <div className="cancel-text warning">
                    Mit Beginn der nächsten Abrechnungsperiode wird Ihr Account pausiert*
                </div>
            </div>
            <div className="plan-update-popup-buttons">
                <div className="plan-update-popup-button" onClick={onClose}>
                    in Ordnung
                </div>
            </div>
        </>
    )
}

export default CancelAccountConfirm;