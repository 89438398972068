import React from "react";
import {ACCOUNT_TABS} from "../../../core/constants/accountTabs";

function AccountTabs({ activeTab, setActiveTab }) {
    return (
        <div className="tab-section">
            <div className={`tab ${activeTab===ACCOUNT_TABS.MY_PROJECTS? 'active': ''}`}
                 onClick={()=>setActiveTab(ACCOUNT_TABS.MY_PROJECTS)}
            >
                My projects
            </div>
            <div className={`tab ${activeTab===ACCOUNT_TABS.MY_PLAN? 'active': ''}`}
                 onClick={()=>setActiveTab(ACCOUNT_TABS.MY_PLAN)}
            >
                My plan
            </div>
            {/*<div className={`tab ${activeTab===ACCOUNT_TABS.INVOICES? 'active': ''}`}*/}
            {/*     onClick={()=>setActiveTab(ACCOUNT_TABS.INVOICES)}*/}
            {/*>*/}
            {/*    Invoices*/}
            {/*</div>*/}
            <div className={`tab ${activeTab===ACCOUNT_TABS.API? 'active': ''}`}
                 onClick={()=>setActiveTab(ACCOUNT_TABS.API)}
            >
                API
            </div>
        </div>
    );
}
export default AccountTabs;
