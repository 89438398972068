import Operations from "./operations";
import apiApiHandler from "../apiHandlers/apiApiHandler";

class ApiOperations extends Operations {
    constructor(apiApiHandler) {
        super();
        this.apiApiHandler = apiApiHandler;
    }

    async getArticleEntities(data, language) {
        const response = await this.apiApiHandler.getArticleEntities(data, language);
        const arrayOfResponse = [];
        let length = 0;
        for(let key in response) {
            arrayOfResponse.push({
                entity: key,
                values: response[key]
            });
            length += response[key].length;
        }
        return { entities: arrayOfResponse, length };
    }

    async getArticleSemantics(data, language) {
        const response = await this.apiApiHandler.getArticleSemantics(data, language);
        const arrayOfResponse = [];
        if (response?.values) {
            for(let key in response.values) {
                arrayOfResponse.push({
                    title: key,
                    count: response.values[key]
                });
            }
        }
        return arrayOfResponse;
    }
}

const apiOperations = new ApiOperations(apiApiHandler);

export default apiOperations;
