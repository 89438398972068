import React, {useState} from "react";
import closeIcon from "../../../images/closeIcon.svg";
import SelectItem from "./selectItem";
import searchIcon from "../../../images/searchIcon.svg";

const SELECT_ALL_TEXT = 'Alle Themen';


function EditLayerSection({ onClose, layers, currentLayers, setCurrentLayers, fullScreen }) {

    const [ selectedLayers, setSelectedLayers ] = useState([...currentLayers]);
    const [ allSelected, setAllSelected ] = useState(!currentLayers.length);

    const [ searchValue, setSearchValue ] = useState('');
    const [ error, setError ] = useState(false);

    const maxHeight = !selectedLayers.length ? 255 : selectedLayers.length === 1 ? 205 : selectedLayers.length === 2 ? 180 : 155;
    const fullScreenMaxHeight = !selectedLayers.length ? 310 : selectedLayers.length === 1 ? 260 : selectedLayers.length === 2 ? 235 : 210;

    const handleClick = layer => {
        if(selectedLayers.includes(layer)) {
            setSelectedLayers(selectedLayers.filter(item => item !== layer));
            if (error) {
                setError(false);
            }
        } else if (selectedLayers.length < 3){
            if (allSelected) {
                setAllSelected(false);
            }
            setSelectedLayers([...selectedLayers, layer])
        } else {
            !error && setError(true);
        }
    }

    const handleAllSelectedButtonClick = () => {
        setAllSelected(prev => !prev);
        if (selectedLayers.length) {
            setSelectedLayers([]);
        }
        if (error) {
            setError(false);
        }
    }

    const handleDoneButtonClick = () => {
        setCurrentLayers([...selectedLayers]);
        onClose();
    };

    const filteredLayers = layers.filter(layer => layer.toLowerCase().includes(searchValue.trim().toLowerCase()) && !selectedLayers.includes(layer));

    return (
        <div className="edit-layers-select">
            <img src={closeIcon} alt="" className='close-icon' onClick={onClose}/>
            <div className="edit-section-title">
                Einzelauswahl
            </div>
            <div className="edit-search">
                <div className="icon-container">
                    <img src={searchIcon} alt="" />
                </div>
                <input
                    className="edit-section-input"
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                />
            </div>
            {
                error ?
                    <div className="edit-section-title error">
                        Sie haben bereits drei Themen gewählt.
                    </div> :
                    <div className="edit-section-title">
                        Sie können bis zu drei Themen  auswählen.
                    </div>

            }

            <SelectItem
                key={SELECT_ALL_TEXT}
                text={SELECT_ALL_TEXT}
                selected={allSelected}
                handleClick={handleAllSelectedButtonClick}
            />

            {
                !!selectedLayers.length &&
                    <>
                        <div className="edit-section-title">
                            Ihre Auswahl
                        </div>

                        {
                            selectedLayers.map(item => {
                                return (
                                    <SelectItem
                                        key={item}
                                        text={item}
                                        selected={selectedLayers.includes(item) || allSelected}
                                        handleClick={() => handleClick(item)}
                                    />
                                )
                            })
                        }
                    </>
            }

            <div className="edit-section-title">
                Themenliste
            </div>


            <div className="selected-layers-scroll" style={{maxHeight: fullScreen? fullScreenMaxHeight : maxHeight}}>
                {
                    filteredLayers.map(item => {
                        return (
                            <SelectItem
                                key={item}
                                text={item}
                                selected={selectedLayers.includes(item) || allSelected}
                                handleClick={() => handleClick(item)}
                            />
                        );
                    })
                }
            </div>
            <div className="select-layers-button" onClick={handleDoneButtonClick}>
                Speichern
            </div>

        </div>
    );
}

export default EditLayerSection;



