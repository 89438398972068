import {useSelector} from "react-redux";
import * as ExcelJS from 'exceljs/dist/exceljs'
import { saveAs } from 'file-saver';
import { PROJECT_ATTRIBUTES } from "../core/constants/projectAttributes";

const colorScheme = {
    0: {
        color: 'B1B1B1',
        fgColor: 'FFFFFF',
    },
    1: {
        color: 'B1B1B1',
        fgColor: 'FFFFFF',
    },
    2: {
        color: 'B1B1B1',
        fgColor: 'F7F8F9',
    },
    3: {
        color: '000000',
        fgColor: 'E3E5E9',
    },
    4: {
        color: '000000',
        fgColor: 'CCCFD6',
    },
    5: {
        color: '000000',
        fgColor: 'ACB2BD',
    },
    6: {
        color: '000000',
        fgColor: '9199A8',
    },
    7: {
        color: 'FFFFFF',
        fgColor: '747D91',
    },
    8: {
        color: 'FFFFFF',
        fgColor: '58637A',
    },
    9: {
        color: 'FFFFFF',
        fgColor: '3E4B66',
    },
    10: {
        color: 'FFFFFF',
        fgColor: '223150',
    },
    20: {
        color: '000000',
        fgColor: 'F7F8F9',
    },
}

function getColorNumber(count, totalCount) {
    const numberInPercents = count && totalCount ? Math.round(count / totalCount * 100): 0;
    return  numberInPercents < 20 ? 0 : numberInPercents === 20 ? 20 :  Math.ceil(numberInPercents/10);

}

const attributes = [PROJECT_ATTRIBUTES.SCORECARD, PROJECT_ATTRIBUTES.INDUSTRY, PROJECT_ATTRIBUTES.RISKS, PROJECT_ATTRIBUTES.SECURITY];

const attributeTexts = {
    [PROJECT_ATTRIBUTES.SCORECARD]: 'Scorecard',
    [PROJECT_ATTRIBUTES.INDUSTRY]: 'Industry',
    [PROJECT_ATTRIBUTES.RISKS]: 'Risks',
    [PROJECT_ATTRIBUTES.SECURITY]: 'Security',
}

function useDownload() {

    const hiddenLayers = useSelector(state => state.hiddenLayers.data.layers);
    const firstColumnData = useSelector(state => state.semantics.semantics.firstColumn.data);
    const secondColumnData = useSelector(state => state.semantics.semantics.secondColumn.data);
    const thirdColumnData = useSelector(state => state.semantics.semantics.thirdColumn.data);
    const fourthColumnData = useSelector(state => state.semantics.semantics.fourthColumn.data);

    const firstColumnV3Data = useSelector(state => state.semantics.semantics.firstColumnV3.data);
    const secondColumnV3Data = useSelector(state => state.semantics.semantics.secondColumnV3.data);
    const thirdColumnV3Data = useSelector(state => state.semantics.semantics.thirdColumnV3.data);
    const fourthColumnV3Data = useSelector(state => state.semantics.semantics.fourthColumnV3.data);

    const { project, startDate, endDate } = firstColumnData;
    const { project: projectV3, startDate: startDateV3, endDate: endDateV3 } = firstColumnV3Data;

    const hiddenPublishers = useSelector(state => state.hiddenPublishers.data.publishers);
    const publishersFirstColumnData = useSelector(state => state.publisherHeatmap.firstColumn.data);
    const publishersSecondColumnData = useSelector(state => state.publisherHeatmap.secondColumn.data);
    const publishersThirdColumnData = useSelector(state => state.publisherHeatmap.thirdColumn.data);
    const publishersFourthColumnData = useSelector(state => state.publisherHeatmap.fourthColumn.data);
    const {
        project: publishersProject,
        startDate: publishersStartDate,
        endDate: publishersEndDate,
    } = publishersFirstColumnData;

    async function saveHeatmap()  {
        const wb = new ExcelJS.Workbook();

        const ws = wb.addWorksheet();

        const projectRow = ws.getRow(1);
        projectRow.values = ['Project', projectV3];
        projectRow.getCell(1).font = {
            bold: true,
        };
        projectRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        const sd = new Date(startDateV3);
        sd.setHours(sd.getHours() + 4);

        const startDateRow = ws.getRow(2);
        startDateRow.values = ['Start Date', sd];
        startDateRow.getCell(1).font = {
            bold: true,
        };
        startDateRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };
        startDateRow.getCell(2).style.alignment = {
            horizontal: "center",
        }

        const ed = new Date(endDateV3);
        ed.setHours(ed.getHours() + 4);

        const endDateRow = ws.getRow(3);
        endDateRow.values = ['End Date', ed];
        endDateRow.getCell(1).font = {
            bold: true,
        };
        endDateRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };
        endDateRow.getCell(2).style.alignment= {
            horizontal: "center",
        }
        ws.getRow(4).values = [];

        const coverageRow = ws.getRow(5);
        coverageRow.values = ['Coverage', 'All', secondColumnV3Data.coverage, thirdColumnV3Data.coverage, fourthColumnV3Data.coverage];
        coverageRow.getCell(1).font = {
            bold: true,
        };
        coverageRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        const companyRow = ws.getRow(6);
        companyRow.values = ['Company', 'All', secondColumnV3Data.company, thirdColumnV3Data.company, fourthColumnV3Data.company];
        companyRow.getCell(1).font = {
            bold: true,
        };
        companyRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        const publisherRow = ws.getRow(7);
        publisherRow.values = ['Publisher', 'All', secondColumnV3Data.publisher, thirdColumnV3Data.publisher, fourthColumnV3Data.publisher];
        publisherRow.getCell(1).font = {
            bold: true,
        };
        publisherRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        const peopleRow = ws.getRow(8);
        peopleRow.values = ['People', 'All', secondColumnV3Data.people, thirdColumnV3Data.people, fourthColumnV3Data.people];
        peopleRow.getCell(1).font = {
            bold: true,
        };
        peopleRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        ws.getRow(9).values = [];


        ws.columns = [
            { key: 'layers', width: 30, style: {alignment :{ vertical: 'middle', horizontal: 'right',indent: 1 }} },
            { key: 'firstColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
            { key: 'secondColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
            { key: 'thirdColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
            { key: 'fourthColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
        ];

        const total = ws.addRow({
            id: 'header',
            layers: '',
            firstColumn: firstColumnV3Data.filteredArticlesCount || 0,
            secondColumn: secondColumnV3Data.filteredArticlesCount || 0,
            thirdColumn: thirdColumnV3Data.filteredArticlesCount || 0,
            fourthColumn: fourthColumnV3Data.filteredArticlesCount || 0,
        })
        total.eachCell(cell => {
            cell.style.font = {
                bold: true,
            }
        })

        const layers = firstColumnV3Data.layers;
        let addedRows = 0;
        for( let i = 0; i < layers.length; i ++) {
            const layer = layers[i];
            if (addedRows === 30) {
                break;
            }
            if (!hiddenLayers[layer.name]) {
                let row = ws.addRow({
                    id: i,
                    layers: layer.name,
                    firstColumn: layer.count,
                    secondColumn: secondColumnV3Data.layers[layer.name] || 0,
                    thirdColumn: thirdColumnV3Data.layers[layer.name] || 0,
                    fourthColumn: fourthColumnV3Data.layers[layer.name] || 0,
                });
                const firstRowColorNumber = getColorNumber(layer.count, firstColumnV3Data.filteredArticlesCount);
                const secondRowColorNumber = getColorNumber(secondColumnV3Data.layers[layer.name], secondColumnV3Data.filteredArticlesCount);
                const thirdRowColorNumber = getColorNumber(thirdColumnV3Data.layers[layer.name], thirdColumnV3Data.filteredArticlesCount);
                const fourthRowColorNumber = getColorNumber(fourthColumnV3Data.layers[layer.name], fourthColumnV3Data.filteredArticlesCount);
                addedRows ++;
                row.getCell(2).style.font = {
                    color: { argb: colorScheme[firstRowColorNumber].color }
                }
                row.getCell(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: colorScheme[firstRowColorNumber].fgColor },
                }

                row.getCell(3).style.font = {
                    color: { argb: colorScheme[secondRowColorNumber].color }
                }
                row.getCell(3).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: colorScheme[secondRowColorNumber].fgColor },
                }

                row.getCell(4).style.font = {
                    color: { argb: colorScheme[thirdRowColorNumber].color }
                }
                row.getCell(4).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: colorScheme[thirdRowColorNumber].fgColor },
                }

                row.getCell(5).style.font = {
                    color: { argb: colorScheme[fourthRowColorNumber].color }
                }
                row.getCell(5).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: colorScheme[fourthRowColorNumber].fgColor },
                }

                row.eachCell(cell => {
                    cell.border = {
                        top: {style:'thin', color: {argb:'DDDDDD'}},
                        left: {style:'thin', color: {argb:'DDDDDD'}},
                        bottom: {style:'thin', color: {argb:'DDDDDD'}},
                        right: {style:'thin', color: {argb:'DDDDDD'}}
                    };
                })
                row.commit();
            }
        }

        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${projectV3}.xlsx`);
    }

    async function saveDashboard()  {
        const wb = new ExcelJS.Workbook();

        const ws = wb.addWorksheet();

        const projectRow = ws.getRow(1);
        projectRow.values = ['', 'Project', project];
        projectRow.getCell(2).font = {
            bold: true,
        };
        projectRow.getCell(2).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        const sd = new Date(startDate);
        sd.setHours(sd.getHours() + 4);

        const startDateRow = ws.getRow(2);
        startDateRow.values = ['', 'Start Date', sd];
        startDateRow.getCell(2).font = {
            bold: true,
        };
        startDateRow.getCell(2).alignment = {
            horizontal: 'right',
            indent: 1,
        };
        startDateRow.getCell(3).style.alignment = {
            horizontal: "center",
        }

        const ed = new Date(endDate);
        ed.setHours(ed.getHours() + 4);

        const endDateRow = ws.getRow(3);
        endDateRow.values = ['', 'End Date', ed];
        endDateRow.getCell(2).font = {
            bold: true,
        };
        endDateRow.getCell(2).alignment = {
            horizontal: 'right',
            indent: 1,
        };
        endDateRow.getCell(3).style.alignment= {
            horizontal: "center",
        }
        ws.getRow(4).values = [];

        const coverageRow = ws.getRow(5);
        coverageRow.values = ['', 'Coverage', 'All', secondColumnData.coverage, thirdColumnData.coverage, fourthColumnData.coverage];
        coverageRow.getCell(2).font = {
            bold: true,
        };
        coverageRow.getCell(2).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        const companyRow = ws.getRow(6);
        companyRow.values = ['', 'Company', 'All', secondColumnData.company, thirdColumnData.company, fourthColumnData.company];
        companyRow.getCell(2).font = {
            bold: true,
        };
        companyRow.getCell(2).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        const publisherRow = ws.getRow(7);
        publisherRow.values = ['', 'Publisher', 'All', secondColumnData.publisher, thirdColumnData.publisher, fourthColumnData.publisher];
        publisherRow.getCell(2).font = {
            bold: true,
        };
        publisherRow.getCell(2).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        const peopleRow = ws.getRow(8);
        peopleRow.values = ['', 'People', 'All', secondColumnData.people, thirdColumnData.people, fourthColumnData.people];
        peopleRow.getCell(2).font = {
            bold: true,
        };
        peopleRow.getCell(2).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        ws.getRow(9).values = [];


        ws.columns = [
            { key: 'attribute', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center',  }}},
            { key: 'layers', width: 30, style: {alignment :{ vertical: 'middle', horizontal: 'right',indent: 1 }} },
            { key: 'firstColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
            { key: 'secondColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
            { key: 'thirdColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
            { key: 'fourthColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
        ];

        const total = ws.addRow({
            id: 'header',
            attribute: '',
            layers: '',
            firstColumn: firstColumnData.filteredArticlesCount || 0,
            secondColumn: secondColumnData.filteredArticlesCount || 0,
            thirdColumn: thirdColumnData.filteredArticlesCount || 0,
            fourthColumn: fourthColumnData.filteredArticlesCount || 0,
        })
        total.eachCell(cell => {
            cell.style.font = {
                bold: true,
            }
        })

        attributes.forEach(attribute => {
            const layers = firstColumnData.layers[attribute];
            const attributeRow = ws.addRow({
                attribute: attributeTexts[attribute],
            })
            attributeRow.getCell(1).style.font = {
                color: { argb: 'FFFFFF' },
                bold: true,
            }
            attributeRow.getCell(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000000' },
            }
            layers.forEach((layer, i) => {
                if (!hiddenLayers[layer.name]) {
                    if (!hiddenLayers[layer.name]) {
                        const row = ws.addRow({
                            id: `${attribute}/${i}`,
                            layers: layer.name,
                            firstColumn: layer.count,
                            secondColumn: secondColumnData.layers[layer.name] || 0,
                            thirdColumn: thirdColumnData.layers[layer.name] || 0,
                            fourthColumn: fourthColumnData.layers[layer.name] || 0,
                        });
                        const firstRowColorNumber = getColorNumber(layer.count, firstColumnData.filteredArticlesCount);
                        const secondRowColorNumber = getColorNumber(secondColumnData.layers[layer.name], secondColumnData.filteredArticlesCount);
                        const thirdRowColorNumber = getColorNumber(thirdColumnData.layers[layer.name], thirdColumnData.filteredArticlesCount);
                        const fourthRowColorNumber = getColorNumber(fourthColumnData.layers[layer.name], fourthColumnData.filteredArticlesCount);

                        row.getCell(3).style.font = {
                            color: { argb: colorScheme[firstRowColorNumber].color }
                        }
                        row.getCell(3).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: colorScheme[firstRowColorNumber].fgColor },
                        }

                        row.getCell(4).style.font = {
                            color: { argb: colorScheme[secondRowColorNumber].color }
                        }
                        row.getCell(4).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: colorScheme[secondRowColorNumber].fgColor },
                        }

                        row.getCell(5).style.font = {
                            color: { argb: colorScheme[thirdRowColorNumber].color }
                        }
                        row.getCell(5).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: colorScheme[thirdRowColorNumber].fgColor },
                        }

                        row.getCell(6).style.font = {
                            color: { argb: colorScheme[fourthRowColorNumber].color }
                        }
                        row.getCell(6).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: colorScheme[fourthRowColorNumber].fgColor },
                        }

                        row.eachCell(cell => {
                            cell.border = {
                                top: {style:'thin', color: {argb:'DDDDDD'}},
                                left: {style:'thin', color: {argb:'DDDDDD'}},
                                bottom: {style:'thin', color: {argb:'DDDDDD'}},
                                right: {style:'thin', color: {argb:'DDDDDD'}}
                            };
                        })
                        row.commit();
                    }
                }
            })
            ws.addRow({});

        })

        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${project}.xlsx`);
    }

    async function savePublishersHeatmap()  {
        const wb = new ExcelJS.Workbook();

        const ws = wb.addWorksheet();

        const projectRow = ws.getRow(1);
        projectRow.values = ['Project', publishersProject];
        projectRow.getCell(1).font = {
            bold: true,
        };
        projectRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        const sd = new Date(publishersStartDate);
        sd.setHours(sd.getHours() + 4);

        const startDateRow = ws.getRow(2);
        startDateRow.values = ['Start Date', sd];
        startDateRow.getCell(1).font = {
            bold: true,
        };
        startDateRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };
        startDateRow.getCell(2).style.alignment = {
            horizontal: "center",
        }

        const ed = new Date(publishersEndDate);
        ed.setHours(ed.getHours() + 4);

        const endDateRow = ws.getRow(3);
        endDateRow.values = ['End Date', ed];
        endDateRow.getCell(1).font = {
            bold: true,
        };
        endDateRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };
        endDateRow.getCell(2).style.alignment= {
            horizontal: "center",
        }
        ws.getRow(4).values = [];

        const coverageRow = ws.getRow(5);
        coverageRow.values = ['Coverage', 'All', publishersSecondColumnData.coverage, publishersThirdColumnData.coverage, publishersFourthColumnData.coverage];
        coverageRow.getCell(1).font = {
            bold: true,
        };
        coverageRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        const companyRow = ws.getRow(6);
        companyRow.values = ['Company', 'All', publishersSecondColumnData.company, publishersThirdColumnData.company, publishersFourthColumnData.company];
        companyRow.getCell(1).font = {
            bold: true,
        };
        companyRow.getCell(1).alignment = {
            horizontal: 'right',
            indent: 1,
        };

        ws.getRow(7).values = [];


        ws.columns = [
            { key: 'layers', width: 30, style: {alignment :{ vertical: 'middle', horizontal: 'right',indent: 1 }} },
            { key: 'firstColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
            { key: 'secondColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
            { key: 'thirdColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
            { key: 'fourthColumn', width: 20, style: {alignment :{ vertical: 'middle', horizontal: 'center' }} },
        ];

        const total = ws.addRow({
            id: 'header',
            layers: '',
            firstColumn: publishersFirstColumnData.filteredArticlesCount || 0,
            secondColumn: publishersSecondColumnData.filteredArticlesCount || 0,
            thirdColumn: publishersThirdColumnData.filteredArticlesCount || 0,
            fourthColumn: publishersFourthColumnData.filteredArticlesCount || 0,
        })
        total.eachCell(cell => {
            cell.style.font = {
                bold: true,
            }
        })

        const layers = publishersFirstColumnData.layers;
        let addedRows = 0;
        for( let i = 0; i < layers.length; i ++) {
            const layer = layers[i];
            if (addedRows === 30) {
                break;
            }
            if (!hiddenPublishers[layer.name]) {
                let row = ws.addRow({
                    id: i,
                    layers: layer.name,
                    firstColumn: layer.count,
                    secondColumn: publishersSecondColumnData.layers[layer.name] || 0,
                    thirdColumn: publishersThirdColumnData.layers[layer.name] || 0,
                    fourthColumn: publishersFourthColumnData.layers[layer.name] || 0,
                });
                const firstRowColorNumber = getColorNumber(layer.count, publishersFirstColumnData.filteredArticlesCount);
                const secondRowColorNumber = getColorNumber(publishersSecondColumnData.layers[layer.name], publishersSecondColumnData.filteredArticlesCount);
                const thirdRowColorNumber = getColorNumber(publishersThirdColumnData.layers[layer.name], publishersThirdColumnData.filteredArticlesCount);
                const fourthRowColorNumber = getColorNumber(publishersFourthColumnData.layers[layer.name], publishersFourthColumnData.filteredArticlesCount);
                addedRows ++;
                row.getCell(2).style.font = {
                    color: { argb: colorScheme[firstRowColorNumber].color }
                }
                row.getCell(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: colorScheme[firstRowColorNumber].fgColor },
                }

                row.getCell(3).style.font = {
                    color: { argb: colorScheme[secondRowColorNumber].color }
                }
                row.getCell(3).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: colorScheme[secondRowColorNumber].fgColor },
                }

                row.getCell(4).style.font = {
                    color: { argb: colorScheme[thirdRowColorNumber].color }
                }
                row.getCell(4).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: colorScheme[thirdRowColorNumber].fgColor },
                }

                row.getCell(5).style.font = {
                    color: { argb: colorScheme[fourthRowColorNumber].color }
                }
                row.getCell(5).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: colorScheme[fourthRowColorNumber].fgColor },
                }

                row.eachCell(cell => {
                    cell.border = {
                        top: {style:'thin', color: {argb:'DDDDDD'}},
                        left: {style:'thin', color: {argb:'DDDDDD'}},
                        bottom: {style:'thin', color: {argb:'DDDDDD'}},
                        right: {style:'thin', color: {argb:'DDDDDD'}}
                    };
                })
                row.commit();
            }
        }

        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${project}.xlsx`);
    }

    return {
        saveHeatmap,
        saveDashboard,
        savePublishersHeatmap
    }
}

export default useDownload;