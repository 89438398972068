import { SAVED_SETTINGS_TYPES } from "../actionTypes/savedSettingsActionTypes";

export const rxSaveDashboardSettings = payload => ({
    type: SAVED_SETTINGS_TYPES.RX_SAVE_DASHBOARD_SETTINGS,
    payload,
});

export const rxSaveHeatmapSettings = payload => ({
    type: SAVED_SETTINGS_TYPES.RX_SAVE_HEATMAP_SETTINGS,
    payload,
});

export const rxSaveChartSettings = payload => ({
    type: SAVED_SETTINGS_TYPES.RX_SAVE_CHART_SETTINGS,
    payload,
});

export const rxSavePublisherHeatmapSettings = payload => ({
    type: SAVED_SETTINGS_TYPES.RX_SAVE_PUBLISHER_HEATMAP_SETTINGS,
    payload,
});