import React, {useEffect, useState} from "react";
import Loader from "../loader";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";
import { useLocation } from "react-router-dom";

function MainSplash({ children }) {
    const [ isLoading, setIsLoading ] = useState(true);
    const { loginWithRefresh } = UseLoginController();
    const { pathname } = useLocation();

    useEffect(() => {
        if(pathname !== '/verify') {
            const email = localStorage.getItem('email');
            if(email){
                loginWithRefresh(email)
                    .then(() => setIsLoading(false));
            } else {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }

    }, []);

    return isLoading ? <Loader /> : <>{children}</>
}

export default MainSplash;