import React, {useState} from "react";
import closeIcon from "../../../images/closeIcon.svg";
import CancelAccountFirstPage from "./cancelAccountFirstPage";
import CancelAccountConfirm from "./cancelAccountConfirm";
import { PLAN_CANCEL_MODAL_CONSTANTS} from "../../../core/constants/planUpdateModalConstants";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";
import {useSelector} from "react-redux";

function CancelAccountPopup({ onClose }) {
    const [ modalState, setModalState ] = useState(PLAN_CANCEL_MODAL_CONSTANTS.FIRST_PAGE);
    const { company } = useSelector(state => state.userInfo.data);

    const { cancelPlan } = UseLoginController();
    const handleCancel = () => {
        cancelPlan({ company }).then(res => {
            if(res instanceof Error) return;
            setModalState(PLAN_CANCEL_MODAL_CONSTANTS.CONFIRM);
        })
    }
    return (
        <div className="project-details-popup">
            <div className="cancel-account-popup-content">
                <img src={closeIcon} alt="" className='close-icon black' onClick={onClose}/>
                <div className="project-details-bar">
                    <div className="project-details-bar-text">
                        Abonnement kündigen
                    </div>
                </div>
                {
                    modalState === PLAN_CANCEL_MODAL_CONSTANTS.FIRST_PAGE &&
                    <CancelAccountFirstPage onClose={onClose} handleCancel={handleCancel}/>
                }
                {
                    modalState === PLAN_CANCEL_MODAL_CONSTANTS.CONFIRM &&
                    <CancelAccountConfirm onClose={onClose}/>
                }


            </div>
        </div>
    )
}

export default CancelAccountPopup;