import Fetch from "../fetch";
import {ABORT_API_KEYS, abortFetch} from "../operations/abortFetch";

const END_POINT = {
    network: 'network',
    entity: 'entity',
    details: 'details',
    filters: 'filters'
};

class GraphApiHandler {

    async getFilters(queryData) {
        return  await Fetch.graphGet(END_POINT.filters, queryData);
    }

    async getNetwork(payload) {
        return await Fetch.graphPost(END_POINT.network, payload);
    }

    async getEntityDetails(payload) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getContestDetails);
        return await Fetch.graphPost(`${END_POINT.entity}/${END_POINT.details}`, payload, abortSignal) ;
    }
}

const graphApiHandler = new GraphApiHandler();
export default graphApiHandler;