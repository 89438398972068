import React, {useState} from "react";
import vector from "../../../images/vector.png";
import arrowUp from "../../../images/arrowUp.png";
import arrowDown from "../../../images/arrowDown.png";
import closeIcon from "../../../images/closeIcon.svg";
import AccountPageDropdown from "../accountPageDropdown";
import UseApiController from "../../../customHooks/controllerHooks/apiController";
import {useDispatch, useSelector} from "react-redux";
import DeleteAccountPopup from "../plansSection/deleteAccountPopup";
import RequestAccessPopup from "./requestAccessPopup";
import {rxGetArticleEntitiesDone, rxGetArticleSemanticsDone} from "../../../state-management/actions/apiActions";

const LANGUAGES = [
    {
        name: 'Unknown',
        key: 'UNKNOWN'
    },
    {
        name: 'German',
        key: 'DE'
    },
    {
        name: 'English',
        key: 'EN'
    },
    {
        name: 'French',
        key: 'FR'
    },
    {
        name: 'Italian',
        key: 'IT'
    },
    {
        name: 'Russian',
        key: 'RU'
    },

]

function ApiSection() {
    const dispatch = useDispatch();
    const [ entitiesOpen, setEntitiesOpen ] = useState(false);
    const [ semanticsOpen, setSemanticsOpen ] = useState(true);
    const [ popupOpen, setPopupOpen ] = useState(false);
    const [ language, setLanguage ] = useState({
        name: 'German',
        key: 'DE'
    });
    const [ text, setText ] = useState('');

    const entities = useSelector(state => state.api.articleEntities.data);
    const semantics = useSelector(state => state.api.articleSemantics.data);

    const {
        getArticleEntities,
        getArticleSemantics
    } = UseApiController();

    const handleSubmitButtonClick = () => {
        getArticleEntities(text, language.key);
        getArticleSemantics(text, language.key);
    };

    const handleClick = () => {
        setEntitiesOpen(prev => !prev);
        setSemanticsOpen(prev => !prev);
    };

    const handleClear = () => {
        setText('');
        dispatch(rxGetArticleEntitiesDone());
        dispatch(rxGetArticleSemanticsDone());
    }


    return (
        <div className="api-section">
            <div className="api-section-top">
                <div className="api-page-description">
                    This page is a demo page for our API. Here you can evaluate our semantics analysis and entity extraction feature.
                    Just copy and paste a text you like into the text area here and click submit to see what we find in the text
                </div>
                <div className="request-access-button" onClick={()=>setPopupOpen(true)}>
                    <img src={vector} alt="" />
                    <span>Request access</span>
                </div>
            </div>
            <div className="api-section-content">
                {/*<div className="dropdown-title">Select language</div>*/}
                {/*<AccountPageDropdown value={language} values={LANGUAGES} setValue={setLanguage} />*/}

                <div className="api-section-content-left">
                    <textarea value={text} onChange={e => setText(e.target.value)} />
                    <div className="buttons">
                        <button type="button" onClick={handleSubmitButtonClick} onClick={handleClear}>
                            Clear
                        </button>
                        <button type="button" onClick={handleSubmitButtonClick}>
                            Submit
                        </button>
                    </div>

                </div>
                <div className="api-section-content-right">
                    <div className={`api-section-right-item ${entitiesOpen? 'open': ''}`}>
                        <div className="api-section-right-item-header" onClick={handleClick}>
                            <span>Entities ({entities.length})</span>
                            <img src={entitiesOpen? arrowUp: arrowDown} alt=""/>
                        </div>
                        <div className="api-section-right-item-body">
                            {/*<input type="text" placeholder="Type here" />*/}
                            {entities.entities.map(entity => {
                                return (
                                    <div className="entity-item" key={entity.entity}>
                                        <div className="entity-title">
                                            {entity.entity} ({entity.values.length})
                                        </div>
                                        <div className="entity-description">
                                            {entity.values.map(value => {
                                                return <div key={value}>{value}</div>
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={`api-section-right-item ${semanticsOpen? 'open': ''}`}>
                        <div className="api-section-right-item-header" onClick={handleClick}>
                            <span>Semantics ({semantics.length})</span>
                            <img src={semanticsOpen? arrowUp: arrowDown} alt=""/>
                        </div>
                        <div className="api-section-right-item-body">
                            {/*<input type="text" placeholder="Type here" />*/}
                            <div className="semantics-list">
                                {semantics.map(semantic => {
                                    const { title, count } = semantic;
                                    return <span key={title}>{title} <span>({count})</span>; </span>
                                })}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            {popupOpen &&
            <div className="project-details-popup" onClick={()=>setPopupOpen(false)}>
                <div className="request-access-popup">
                    <img src={closeIcon} alt="" className='close-icon' onClick={() => {
                    }}/>
                    <div className="project-details-bar">
                        <div className="request-access-bar-text">
                            Request access
                        </div>
                    </div>
                    <div className="request-access-popup-content">
                        <div className="thank-you-header">
                            Thank you for your request!
                        </div>
                        <div className="thank-you-text">
                            Sales received it. We will come back to you shortly!
                        </div>
                        <button className="request-access-ok-button" onClick={()=>setPopupOpen(false)}>
                            Ok
                        </button>
                    </div>
                </div>
            </div>}
            {/*<RequestAccessPopup />*/}
        </div>
    );
}

export default ApiSection;
