import React from "react";
import { useRouteMatch } from 'react-router-dom';

function DataBar() {
    const { path } = useRouteMatch();

    return (
        <div className="data-bar" style={{maxHeight: path === '/verify' ? 264 : 190 }}>
            <div className="outcome-bar-text data-bar-text">
                your data
            </div>
        </div>
    );
}

export default DataBar;