import React, {useEffect, useState} from "react";
import DateSection from "./dateSection";
import DateInput from "./dateInput";
import SubjectSection from "./subjectSection";
import minusIcon from "../../../images/minusIcon.svg";
import plusIcon from "../../../images/btnPlus.svg";
import newsPaper from "../../../images/newspaper.svg";
import SearchFilters from "./searchFilters";
import SearchFilterRow from "./searchFilterRow";
import FilterRowPlusIcon from "./FilterRowPlusIcon";
import moment from "moment";
import {useSelector} from "react-redux";
import UseCompanyController from "../../../customHooks/controllerHooks/companyController";
import UseProjectController from "../../../customHooks/controllerHooks/projectController";
import UseSavedSettingsController from "../../../customHooks/controllerHooks/savedSettingsController";
import useDidUpdateEffect from "../../../customHooks/useDidUpdateEffect";
import UsePublisherController from "../../../customHooks/controllerHooks/publisherController";
import UseCoverageController from "../../../customHooks/controllerHooks/coverageController";

const now = new Date();
const oneYearBefore = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

const endDateIsLess = (startDateStr, endDateStr) => {
    const startDate = new Date(moment(startDateStr).format());
    const endDate = new Date(moment(endDateStr).format());
    return startDate > endDate;
};

const valuesAreEmpty = values => {
    return values.coverage === '' &&
        values.company === '' ;
};

const formatDateForApi = date => moment(date).format('MM/DD/YYYY');

const initialValues = {
    coverage: '',
    company: '',
}

function PublisherSearchSection({ open, setHeatmapLoading, openRequestForm }) {
    const selectedSubject = useSelector(state => state.userInfo.data.projects[0] || '');
    const [ subjectSelectedValue, setSubjectSelectedValue ] = useState(selectedSubject);
    const heatmapSettings = useSelector(state => state.savedSettings.publisherHeatmapSettings);
    const [ showFilters, setShowFilters ] = useState(false);
    const [ showCompanyFilters, setShowCompanyFilters ] = useState(false);

    const secondColumnCoverage = useSelector(state => state.publisherFilters.coverage.secondColumnOptions);
    const thirdColumnCoverage = useSelector(state => state.publisherFilters.coverage.thirdColumnOptions);
    const fourthColumnCoverage = useSelector(state => state.publisherFilters.coverage.fourthColumnOptions);

    const secondColumnCompanies = useSelector(state => state.publisherFilters.companies.secondColumnOptions);
    const thirdColumnCompanies = useSelector(state => state.publisherFilters.companies.thirdColumnOptions);
    const fourthColumnCompanies = useSelector(state => state.publisherFilters.companies.fourthColumnOptions);

    const firstColumnLoading = useSelector(state => state.publisherHeatmap.firstColumn.isLoading);
    const secondColumnLoading = useSelector(state => state.publisherHeatmap.secondColumn.isLoading);
    const thirdColumnLoading = useSelector(state => state.publisherHeatmap.thirdColumn.isLoading);
    const fourthColumnLoading = useSelector(state => state.publisherHeatmap.fourthColumn.isLoading);
    const tableIsLoading = firstColumnLoading || secondColumnLoading || thirdColumnLoading || fourthColumnLoading;

    const {
        getPublishersFirstColumn: getFirstColumn,
        getPublishersSecondColumn: getSecondColumn,
        getPublishersThirdColumn: getThirdColumn,
        getPublishersFourthColumn: getFourthColumn,
        resetPublishersSecondColumn: resetSecondColumn,
        resetPublishersThirdColumn: resetThirdColumn,
        resetPublishersFourthColumn: resetFourthColumn
    } = UsePublisherController();

    const { getHiddenPublishers: getHiddenLayers } = UseProjectController();
    const { savePublisherHeatmapSettings: saveHeatMapSettings } = UseSavedSettingsController();
    const {
        getPublisherFilterFirstColCoverage,
        getPublisherFilterSecondColCoverage,
        getPublisherFilterThirdColCoverage,
        getPublisherFilterFourthColCoverage,
    } = UseCoverageController();

    const {
        getPublisherFilterSecondColCompanies,
        getPublisherFilterThirdColCompanies,
        getPublisherFilterFourthColCompanies,
    } = UseCompanyController();



    const [ dateFrom, setDateFrom ] = useState(oneYearBefore);
    const [ dateTo, setDateTo ] = useState(now);

    const startDate = formatDateForApi(dateFrom);
    const endDate = formatDateForApi(dateTo);


    const [ firstColumnValues, setFirstColumnValues ] = useState({
        ...initialValues,
    });

    const [ secondColumnValues, setSecondColumnValues ] = useState({
        ...initialValues,
    });

    const [ thirdColumnValues, setThirdColumnValues ] = useState({
        ...initialValues,
    });

    const [ fourthColumnValues, setFourthColumnValues ] = useState({
        ...initialValues,
    });


    const changeSubjectSelectedValue = value => {
        const valuesToSave = {
            secondColumnValues: {...secondColumnValues},
            thirdColumnValues: {...thirdColumnValues},
            fourthColumnValues: {...fourthColumnValues},
        }
        saveHeatMapSettings(subjectSelectedValue, valuesToSave);
        setSubjectSelectedValue(value);
    }

    useEffect(() => {
        const els = document.querySelectorAll('.search-first-row input');
        for (let i=0; i < els.length; i++) {
            els[i].setAttribute("readonly", "true");
        }
    }, []);

    useDidUpdateEffect(() => {
        getPublisherFilterSecondColCompanies({
            startDate,
            endDate,
            project: subjectSelectedValue,
            coverage: secondColumnValues.coverage,
        })
    }, [secondColumnValues.coverage, startDate, endDate, subjectSelectedValue]);

    useDidUpdateEffect(() => {
        getPublisherFilterSecondColCoverage({
            startDate,
            endDate,
            project: subjectSelectedValue,
            company: secondColumnValues.company,
        })
    }, [secondColumnValues.company, startDate, endDate, subjectSelectedValue]);

    useDidUpdateEffect(() => {
        getPublisherFilterThirdColCompanies({
            startDate,
            endDate,
            project: subjectSelectedValue,
            coverage: thirdColumnValues.coverage,
        })
    }, [thirdColumnValues.coverage, startDate, endDate, subjectSelectedValue]);

    useDidUpdateEffect(() => {
        getPublisherFilterThirdColCoverage({
            startDate,
            endDate,
            project: subjectSelectedValue,
            company: thirdColumnValues.company,
        })
    }, [thirdColumnValues.company, startDate, endDate, subjectSelectedValue]);

    useDidUpdateEffect(() => {
        getPublisherFilterFourthColCompanies({
            startDate,
            endDate,
            project: subjectSelectedValue,
            coverage: fourthColumnValues.coverage,
        })
    }, [fourthColumnValues.coverage, startDate, endDate, subjectSelectedValue]);

    useDidUpdateEffect(() => {
        getPublisherFilterFourthColCoverage({
            startDate,
            endDate,
            project: subjectSelectedValue,
            company: fourthColumnValues.company,
        })
    }, [fourthColumnValues.company, startDate, endDate, subjectSelectedValue]);

    useDidUpdateEffect(() => {
        setHeatmapLoading(true);
        getFirstColumn({
            startDate,
            endDate,
            project: subjectSelectedValue,
        });
        getPublisherFilterFirstColCoverage({
            startDate,
            endDate,
            project: subjectSelectedValue,
        })
    }, [startDate, endDate, subjectSelectedValue]);

    useDidUpdateEffect(()=> {
        getHiddenLayers({name: subjectSelectedValue});
        if(heatmapSettings[subjectSelectedValue]){
            setSecondColumnValues({...heatmapSettings[subjectSelectedValue].secondColumnValues});
            setThirdColumnValues({...heatmapSettings[subjectSelectedValue].thirdColumnValues});
            setFourthColumnValues({...heatmapSettings[subjectSelectedValue].fourthColumnValues});

            const openCoverageFilters = !!(heatmapSettings[subjectSelectedValue].secondColumnValues.coverage ||
                heatmapSettings[subjectSelectedValue].thirdColumnValues.coverage ||
                heatmapSettings[subjectSelectedValue].fourthColumnValues.coverage);

            const openCompanyFilters = !!(heatmapSettings[subjectSelectedValue].secondColumnValues.company ||
                heatmapSettings[subjectSelectedValue].thirdColumnValues.company ||
                heatmapSettings[subjectSelectedValue].fourthColumnValues.company);

            if(openCompanyFilters) {
                !showFilters && setShowFilters(true);
                !showCompanyFilters && setShowCompanyFilters(true);
            } else if(openCoverageFilters) {
                !showFilters && setShowFilters(true);
            }

        } else {
            setSecondColumnValues({...initialValues});
            setThirdColumnValues({...initialValues});
            setFourthColumnValues({...initialValues});
        }
    }, [subjectSelectedValue])

    useDidUpdateEffect(() => {
        if (valuesAreEmpty(secondColumnValues)){
            resetSecondColumn();
        } else {
            getSecondColumn({
                ...secondColumnValues,
                startDate,
                endDate,
                project: subjectSelectedValue
            });
        }
    }, [secondColumnValues, startDate, endDate]);

    useDidUpdateEffect(() => {
        if (valuesAreEmpty(thirdColumnValues)){
            resetThirdColumn();
        } else {
            getThirdColumn({
                ...thirdColumnValues,
                startDate,
                endDate,
                project: subjectSelectedValue
            });
        }
    }, [thirdColumnValues, startDate, endDate]);

    useDidUpdateEffect(() => {
        if (valuesAreEmpty(fourthColumnValues)){
            resetFourthColumn();
        } else {
            getFourthColumn({
                ...fourthColumnValues,
                startDate,
                endDate,
                project: subjectSelectedValue
            });
        }
    }, [fourthColumnValues, startDate, endDate])


    const startDateInputChange = date => {
        setDateFrom(date);
        if(endDateIsLess(date, endDate)) {
            setDateTo(date);
        }
    };

    const endDateInputChange = date => {
        setDateTo(date);
    };


    const addFilterRow = () => {
        if (!showCompanyFilters) {
            setShowCompanyFilters(true);
        }
    }

    const toggleFiltersSection = () => {
        if(tableIsLoading) {
            return;
        }
        if(showFilters) {
            setSecondColumnValues({...initialValues});
            setThirdColumnValues({...initialValues});
            setFourthColumnValues({...initialValues});
        }
        setShowFilters(!showFilters);
        showCompanyFilters && setShowCompanyFilters(false);
    }

    const onCompaniesIconClick = () => {
        if(tableIsLoading) {
            return;
        }
        setShowCompanyFilters(false);
        setSecondColumnValues({...secondColumnValues, company: ''});
        setThirdColumnValues({...thirdColumnValues, company: ''});
        setFourthColumnValues({...fourthColumnValues, company: ''});
    }


    return (
        <div className={open ? "": "closed"}>
            <div className="search-first-row">
                <DateSection>
                    <DateInput
                        name="date-from"
                        label="From"
                        value={dateFrom}
                        handleChange={startDateInputChange}
                        min={oneYearBefore}
                        max={now}
                    />
                    <DateInput
                        name="date-to"
                        label="To"
                        value={dateTo}
                        handleChange={endDateInputChange}
                        min={dateFrom}
                        max={now}
                    />
                </DateSection>
                <SubjectSection
                    subjectSelectedValue={subjectSelectedValue}
                    setSubjectSelectedValue={changeSubjectSelectedValue}
                    icon={showFilters ? minusIcon : plusIcon}
                    handleClick={toggleFiltersSection}
                />
                <div className="request-button" onClick={()=>openRequestForm()}>
                    <img src={newsPaper} alt="" />
                    <span>Project request form</span>
                </div>
            </div>
            {showFilters &&
            <SearchFilters>
                <SearchFilterRow
                    rowTitle="Coverage"
                    options={['National']}
                    name='coverage'
                    firstColumnValues={firstColumnValues}
                    secondColumnValues={secondColumnValues}
                    setSecondColumnValues={setSecondColumnValues}
                    thirdColumnValues={thirdColumnValues}
                    setThirdColumnValues={setThirdColumnValues}
                    fourthColumnValues={fourthColumnValues}
                    setFourthColumnValues={setFourthColumnValues}
                    secondColumnOptions={secondColumnCoverage.data}
                    thirdColumnOptions={thirdColumnCoverage.data}
                    fourthColumnOptions={fourthColumnCoverage.data}
                />
                {showCompanyFilters &&
                <SearchFilterRow
                    rowTitle="Company"
                    // options={companies}
                    name='company'
                    canClose={true}
                    onIconClick={onCompaniesIconClick}
                    firstColumnValues={firstColumnValues}
                    secondColumnValues={secondColumnValues}
                    setSecondColumnValues={setSecondColumnValues}
                    thirdColumnValues={thirdColumnValues}
                    setThirdColumnValues={setThirdColumnValues}
                    fourthColumnValues={fourthColumnValues}
                    setFourthColumnValues={setFourthColumnValues}
                    secondColumnOptions={secondColumnCompanies.data}
                    thirdColumnOptions={thirdColumnCompanies.data}
                    fourthColumnOptions={fourthColumnCompanies.data}
                    isLoading={false}
                />}

                {!showCompanyFilters &&
                <FilterRowPlusIcon
                    handleClick={addFilterRow} />}
            </SearchFilters>}
        </div>
    );
}

export default PublisherSearchSection;