import React, {useEffect, useRef, useState} from "react";
import closeIcon from "../../../images/closeIcon.svg";
import PlanUpdateFirstPage from './planUpdateFirstPage';
import PlanUpgradeConfirm from './planUpgradeConfirm';
import LargeToPlusWarning from './largeToPlusWarning';
import ToProWarning from './toProWarning';
import DowngradeSelect from './downgradeSelect';
import SelectedProjects from './selectedProjects';
import DownToPlusConfirm from './downToPlusConfirm';
import DowngradeToProConfirm from './downgradeToProConfirm';
import DowngradeToProAbort from './downgradeToProAbort';
import ProjectSelect from './projectSelect';
import PLAN_UPDATE_MODAL_CONSTANTS from "../../../core/constants/planUpdateModalConstants";
import LostProjects from "./lostProjects";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";
import {useSelector} from "react-redux";

const PlanUpdatePopup = ({
                             onClose,
                             selectedPlanName,
                             setSelectedPlanName,
                             currentPlanName
}) => {

    const [ modalState, setModalState ] = useState(PLAN_UPDATE_MODAL_CONSTANTS.FIRST_PAGE);
    const [ selectedProject, setSelectedProject ] = useState(null);
    const [ selectedProjects, setSelectedProjects ] = useState([]);


    const [ warningResponse, setWarningResponse ] = useState(null);

    const sliceIndex = currentPlanName === 'Large' ? 10 : 5;
    const projects = warningResponse?.companyResponse?.projects.slice(0, sliceIndex) || [];

    return (
        <div className="project-details-popup">
            <div className="update-plan-popup-content">
                <img src={closeIcon} alt="" className='close-icon black' onClick={onClose}/>
                <div className="project-details-bar">
                    <div className="project-details-bar-text">
                        Wählen Sie einen Plan
                    </div>
                </div>
                <div className="plan-update-content">
                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.FIRST_PAGE &&
                        <PlanUpdateFirstPage
                            selectedPlanName={selectedPlanName}
                            setSelectedPlanName={setSelectedPlanName}
                            currentPlanName={currentPlanName}
                            setModalState={setModalState}
                            onClose={onClose}
                            setWarningResponse={setWarningResponse}
                        />
                    }
                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.TO_PRO_WARNING &&
                        <ToProWarning setModalState={setModalState} onClose={onClose} />
                    }
                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.TO_PLUS_WARNING &&
                            <LargeToPlusWarning setModalState={setModalState} onClose={onClose} />
                    }

                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_SELECT &&
                        <DowngradeSelect
                            setModalState={setModalState}
                            onClose={onClose}
                            selectedProjects={selectedProjects}
                            setSelectedProjects={setSelectedProjects}
                            projects={projects}
                        />
                    }

                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.SELECTED_PROJECTS &&
                        <SelectedProjects
                            setModalState={setModalState}
                            onClose={onClose}
                            selectedProjects={selectedProjects}
                            selectedPlanName={selectedPlanName}
                        />
                    }

                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_TO_PLUS_CONFIRM &&
                        <DownToPlusConfirm onClose={onClose}/>
                    }

                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.LOST_PROJECTS &&
                            <LostProjects setModalState={setModalState} onClose={onClose} projects={projects}/>
                    }
                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.PROJECT_SELECT &&
                        <ProjectSelect
                            setModalState={setModalState}
                            projects={projects}
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            selectedPlanName={selectedPlanName}
                        />
                    }
                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_TO_PRO_CONFIRM &&
                        <DowngradeToProConfirm onClose={onClose} selectedProject={selectedProject}/>
                    }

                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_TO_PRO_ABORT &&
                        <DowngradeToProAbort onClose={onClose} />
                    }
                    {
                        modalState === PLAN_UPDATE_MODAL_CONSTANTS.PLAN_UPGRADE_CONFIRM &&
                        <PlanUpgradeConfirm selectedPlanName={selectedPlanName} onClose={onClose} />
                    }
                </div>
            </div>
        </div>
    );
};

export default PlanUpdatePopup;
