import React, {useEffect, useRef, useState} from "react";
import closeIcon from '../../../images/closeIcon.svg';
import UseProjectController from "../../../customHooks/controllerHooks/projectController";
import {useSelector} from "react-redux";
import UseCloseOnEscape from "../../../customHooks/useCloseOnEscape";

const LANGUAGES = ['German', 'English', 'French', 'Italian'];
const COUNTRIES = ['Germany', 'UK', 'USA', 'France', 'Italy'];

const LANGUAGES_ABBREVIATIONS = {
    German :'DE',
    English: 'EN',
    French: 'FR',
    Italian: 'IT',
};

const COUNTRIES_ABBREVIATIONS = {
    Germany: 'DE',
    UK: 'GB',
    USA: 'US',
    France: 'FR',
    Italy: 'IT'
}

function RequestPopup({ open, setOpen }) {
    const [ languagesDropdownOpen, setLanguagesDropdownOpen ] = useState(false);
    const [ countriesDropdownOpen, setCountriesDropdownOpen ] = useState(false);
    const [ showSuccessMessage, setShowSuccessMessage ] = useState(false);
    const email = useSelector(state => state.userInfo.data.email);
    const languagesDropdownRef = useRef(null);
    const countriesDropdownRef = useRef(null);

    const [ projectName, setProjectName ] = useState('');
    const [ projectDescription, setProjectDescription ] = useState('');
    const [ language, setLanguage ] = useState('');
    const [ country, setCountry ] = useState('');
    const [ market, setMarket ] = useState('');

    const [ errors, setErrors ] = useState({
        projectName: false,
        projectDescription: false,
        language: false,
        country: false,
    });

    const { requestProject } = UseProjectController();
    UseCloseOnEscape(() => setOpen(false));


    const handleClickOutsideLanguagesDropdown = e => {
        if(languagesDropdownRef.current && !languagesDropdownRef.current.contains(e.target)){
            setLanguagesDropdownOpen(false);
        }
    };

    const handleClickOutsideCountriesDropdown = e => {
        if(countriesDropdownRef.current && !countriesDropdownRef.current.contains(e.target)){
            setCountriesDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideLanguagesDropdown);
        document.addEventListener('mousedown', handleClickOutsideCountriesDropdown);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideLanguagesDropdown);
            document.removeEventListener('mousedown', handleClickOutsideCountriesDropdown);
        }
    });

    const toggleLanguagesDropdown = () => {
        if(languagesDropdownOpen) {
            setLanguagesDropdownOpen(false);
        } else {
            setLanguagesDropdownOpen(true);
        }
    }

    const toggleCountriesDropdown = () => {
        if(countriesDropdownOpen) {
            setCountriesDropdownOpen(false)
        } else {
            setCountriesDropdownOpen(true);
        }
    };

    const handleProjectNameChange = e => {
        setProjectName(e.target.value);
        !!e.target.value.trim() !== !errors.projectName && setErrors({...errors, projectName: !e.target.value.trim() })
    }

    const handleProjectDescriptionChange = e => {
        setProjectDescription(e.target.value);
        !!e.target.value.trim() !== !errors.projectDescription && setErrors({...errors, projectDescription: !e.target.value.trim() })
    }

    const handleLanguagesDropdownClick = language => {
        setLanguage(language);
        setLanguagesDropdownOpen(false);
        errors.language && setErrors({...errors, language: false})
    }

    const handleCountriesDropdownClick = country => {
        setCountry(country);
        setCountriesDropdownOpen(false);
        errors.country && setErrors({...errors, country: false})
    }

    const handleSubmit = e => {
        e.preventDefault();
        if(!projectName.trim() || !projectDescription.trim() || !language || !country ){
            setErrors({
                projectName: !projectName.trim(),
                projectDescription: !projectDescription.trim(),
                language: !language,
                country: !country,
            });
            return;
        }

        requestProject({
            name: projectName,
            description: projectDescription,
            language: LANGUAGES_ABBREVIATIONS[language],
            countryCode: COUNTRIES_ABBREVIATIONS[country],
            email,
            market,
        }).then((response) => {
            if(response instanceof Error){
                return
            }
            setShowSuccessMessage(true);
            setTimeout(() => setOpen(false), 3000)
            // setOpen(false)
        });
            setErrors({
                projectName: false,
                projectDescription: false,
                language: false,
                country: false,
            })
    }
    return(
        <div className="popup-wrapper">
            {showSuccessMessage ?
                <div className="success-message">
                    <div>We received the project request.</div>
                    <div>Thank you!</div>
                </div> :
            <div className="request-popup-content">
                <div className="request-form-bar">
                    <div className="request-form-bar-text">Project request form</div>
                </div>
                <img src={closeIcon} alt="" className='close-icon' onClick={()=>setOpen(false)}/>
                <form className="project-request-form" onSubmit={handleSubmit}>
                    <div className="request-form-label">
                        <label htmlFor="project-name">Project name</label>
                        {errors.projectName && <div className="error-alert">missing</div>}
                    </div>
                    <input
                        autoComplete="off"
                        spellCheck={false}
                        type="text"
                        className={`project-name ${errors.projectName? 'error': ''}`}
                        id="project-name"
                        name="project-name"
                        placeholder="Ex. Global Pandemic Impact on Economy"
                        value={projectName}
                        onChange={handleProjectNameChange}
                    />
                    <div className="request-form-label">
                        <label htmlFor="project-description">Project description</label>
                        {errors.projectDescription && <div className="error-alert">missing</div>}
                    </div>
                    <textarea
                        className={`project-description ${errors.projectDescription? 'error': ''}`}
                        spellCheck={false}
                        id="project-description"
                        name="project-description"
                        placeholder="Please give a free form description for your project"
                        value={projectDescription}
                        onChange={handleProjectDescriptionChange}
                    />
                    <div className="dropdown-section">
                        <div className={`dropdown-section-item ${languagesDropdownOpen? 'open': ''}`}
                             ref={languagesDropdownRef}>
                            <div className="request-form-label language-dropdown">
                                <label htmlFor="language-dropdown"
                                       onClick={()=>setLanguagesDropdownOpen(false)}>Language</label>
                                {errors.language && <div className="error-alert">missing</div>}
                            </div>

                            <div className={`request-dropdown language-dropdown ${language ? '': 'placeholder'} ${errors.language? 'error': ''}`}
                                 id="language-dropdown"
                                 onClick={toggleLanguagesDropdown}>
                                {language ? language : "Please select language"}
                            </div>
                            <div className="request-dropdown-filters language-dropdown-filters">
                                {LANGUAGES.map(item => {
                                    return(
                                        <div className={`request-dropdown-item ${item === language ? 'selected': ''}`}
                                             key={item}
                                             onClick={()=>handleLanguagesDropdownClick(item)}
                                        >
                                            {item}
                                        </div>
                                        )
                                })}
                            </div>
                        </div>
                        <div className={`dropdown-section-item ${countriesDropdownOpen? 'open': ''}`}
                             ref={countriesDropdownRef}>
                            <div className="request-form-label">
                                <label htmlFor="country-dropdown"
                                       onClick={()=>setCountriesDropdownOpen(false)}>Country</label>
                                {errors.country && <div className="error-alert">missing</div>}
                            </div>

                            <div className={`request-dropdown country-dropdown ${country? '':'placeholder'} ${errors.country? 'error': ''}`}
                                 id="country-dropdown"
                                 onClick={toggleCountriesDropdown}
                            >
                                {country ? country : "Choose country"}
                            </div>
                            <div className="request-dropdown-filters country-dropdown-filters">
                                {COUNTRIES.map(item => {
                                    return(
                                        <div className={`request-dropdown-item ${item === country ? 'selected': ''}`}
                                             key={item}
                                             onClick={()=>handleCountriesDropdownClick(item)}
                                             >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                    <label htmlFor="market">Market</label>
                    <input
                        type="text"
                        autoComplete="off"
                        spellCheck={false}
                        className="market"
                        id="market"
                        name="market"
                        placeholder="Ex.Healthcare and Medicine"
                        value={market}
                        onChange={(e)=>setMarket(e.target.value)}
                    />
                    <button type="submit">Submit</button>
                </form>
            </div>}

        </div>
    );
}

export default RequestPopup;