import React from "react";
import closeIcon from "../../../images/closeIcon.svg";
import eyeSolid from "../../../images/eyeSolid.svg";
import eyeSlashSolid from "../../../images/eyeSlashSolid.svg";
import UseCloseOnEscape from "../../../customHooks/useCloseOnEscape";

function DownloadInfoPopup({ setOpen, subject }) {

    UseCloseOnEscape(() => setOpen(false));

    return (
        <div className="popup-wrapper table-popup-wrapper">
            <div className="request-popup-content download-popup-content">
                <div className="request-form-bar">
                    <div className="request-form-bar-text">Download CSV file</div>
                </div>
                <img src={closeIcon} alt="" className='close-icon' onClick={()=>setOpen(false)}/>
                <div className="project-request-form" >
                    <div className="save-header">{`Save ${subject} data:`}</div>
                    <div className="save-text">Maximum of 30 visible layers will be included</div>
                    <div className="save-text">Non-visible layers will not show up</div>
                    <div className="save-text">
                        <span>Hide/Show layers using the Eye icon</span>
                        <img alt="" src={eyeSolid} />
                        <img alt="" src={eyeSlashSolid} />
                    </div>
                    <button type="button" className="ok-button" onClick={()=>setOpen(false)}>Ok</button>
                </div>
            </div>
        </div>
    );
}

export default DownloadInfoPopup;