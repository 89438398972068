import {PROJECT_TYPES} from "../actionTypes/projectActionTypes";

export const rxGetHiddenLayersPending = payload => ({
    type: PROJECT_TYPES.RX_GET_HIDDEN_LAYERS_PENDING,
    payload,
});

export const rxGetHiddenLayersDone = payload => ({
    type: PROJECT_TYPES.RX_GET_HIDDEN_LAYERS_DONE,
    payload,
});

export const rxGetHiddenPublishersPending = payload => ({
    type: PROJECT_TYPES.RX_GET_HIDDEN_PUBLISHERS_PENDING,
    payload,
});

export const rxGetHiddenPublishersDone = payload => ({
    type: PROJECT_TYPES.RX_GET_HIDDEN_PUBLISHERS_DONE,
    payload,
});

export const rxGetProjectDetailsPending = payload => ({
    type: PROJECT_TYPES.RX_GET_PROJECT_DETAILS_PENDING,
    payload,
});

export const rxGetProjectDetailsDone = payload => ({
    type: PROJECT_TYPES.RX_GET_PROJECT_DETAILS_DONE,
    payload,
});