import React from 'react';
import DowngradeConfirmIcon from './downgradeConfirmIcon';
import {useSelector} from "react-redux";

function DowngradeToProConfirm({ onClose, selectedProject }) {
    const { email, projects } = useSelector(state =>state.userInfo.data);
  return (
    <>
      <DowngradeConfirmIcon />
      <div className="downgrade-confirm-text">
        Ab der nächsten Abrechnungsperiode hat <br />
        <span>{email}</span> Zugriff auf folgendes Projekt: <br />
        <span>{ selectedProject || projects[0]}</span> <br />
        Wir freuen uns, dass Sie weiter Entdecker bleiben wollen!
      </div>
      <div className="plan-update-popup-buttons">
        <div className="plan-update-popup-button" onClick={onClose}>
          in Ordnung
        </div>
      </div>
    </>
  );
}

export default DowngradeToProConfirm;
