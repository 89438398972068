import React, {useEffect, useRef, useState} from 'react';
import arrowUp from '../../../images/arrowUp.png';
import arrowDown from '../../../images/arrowDown.png';
import {useSelector} from "react-redux";
import PLAN_UPDATE_MODAL_CONSTANTS from "../../../core/constants/planUpdateModalConstants";
import PLAN_NAMES from "../../../core/constants/planNames";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";



const PLANS = ['Pro', 'Plus', 'Large']

function PlanUpdateFirstPage({ selectedPlanName,
                               setSelectedPlanName,
                               currentPlanName,
                               setModalState,
                               onClose,
                               setWarningResponse
}) {
  const [ openDropdown, setOpenDropdown ] = useState(false);
  const { company, email } = useSelector(state => state.userInfo.data);
  const plans = PLANS.filter(plan => plan !== currentPlanName);

  const ref = useRef(null);

  const { checkWarnings, updatePlan, cancelPlan } = UseLoginController();


  const handleClickOutside = e => {
    if(ref.current && !ref.current.contains(e.target)){
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClick = plan => {
    if(plan !== selectedPlanName) {
      setSelectedPlanName(plan);
    }

    setOpenDropdown(false);
  };


  const handleConfirm = () => {
    checkWarnings({ company, plan: selectedPlanName}).then(res => {
      setWarningResponse(res);
      openNextPopup(res?.warning);
    });
  }

  const openNextPopup = warning => {
    if(currentPlanName === PLAN_NAMES.LARGE){
      if(!warning){
        updatePlan({company, plan: selectedPlanName}).then((res) => {
          // if (res instanceof Error) return;
          if(selectedPlanName === PLAN_NAMES.PRO) {
            setModalState(PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_TO_PRO_CONFIRM);
          } else {
            setModalState(PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_TO_PLUS_CONFIRM);
          }

        });
      }else if(selectedPlanName === PLAN_NAMES.PLUS) {
        setModalState(PLAN_UPDATE_MODAL_CONSTANTS.TO_PLUS_WARNING);
      } else {
        setModalState(PLAN_UPDATE_MODAL_CONSTANTS.TO_PRO_WARNING);
      }
    }

    if(currentPlanName === PLAN_NAMES.PLUS) {
      if(selectedPlanName === PLAN_NAMES.PRO){
        if(!warning){
          updatePlan({company, plan: selectedPlanName}).then((res) => {
            // if (res instanceof Error) return;
            setModalState(PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_TO_PRO_CONFIRM);
          })
        } else {
          setModalState(PLAN_UPDATE_MODAL_CONSTANTS.TO_PRO_WARNING);
        }
      }
      if(selectedPlanName === PLAN_NAMES.LARGE) {
        updatePlan({company, plan: selectedPlanName}).then((res) => {
          // if (res instanceof Error) return;
          setModalState(PLAN_UPDATE_MODAL_CONSTANTS.PLAN_UPGRADE_CONFIRM);
        })
      }
    }

    if(currentPlanName === PLAN_NAMES.PRO) {
      updatePlan({company, plan: selectedPlanName}).then((res) => {
        // if (res instanceof Error) return;
        setModalState(PLAN_UPDATE_MODAL_CONSTANTS.PLAN_UPGRADE_CONFIRM);
      });
    }
  }

  return (
    <>
      <div className="plan-update-dropdown-row">
        <div className="plan-update-dropdown-title">
          Neuer Plan
        </div>
        <div className={`project-dropdown plan-update-dropdown ${openDropdown? 'open': ''}`} ref={ref}>
          <div className="project-dropdown-input update-dropdown-input" onClick={()=>setOpenDropdown(prev => !prev)}>
            <div>{selectedPlanName}</div>
            <img src={openDropdown ? arrowUp: arrowDown} />
          </div>
          <div className="project-dropdown-content">
            {
              plans.map(plan => {
                return (
                  <div className={`project-dropdown-item ${plan === selectedPlanName ? 'active': ''}`}
                       key={plan}
                       onClick={() => handleClick(plan)}
                  >{plan}</div>
                )

              })
            }

          </div>
        </div>
      </div>
      <div className="user-details">
        <div className="user-detail">
          <div className="user-detail-description">
            Vorname
          </div>
          <div className="user-detail-data">
            {/*Davit*/}
          </div>
        </div>
        <div className="user-detail">
          <div className="user-detail-description">
            Nachname
          </div>
          <div className="user-detail-data">
            {/*Hunanyan*/}
          </div>
        </div>
        <div className="user-detail">
          <div className="user-detail-description">
            Unternehmen
          </div>
          <div className="user-detail-data">
            {company}
          </div>
        </div>
        <div className="user-detail">
          <div className="user-detail-description">
            Email
          </div>
          <div className="user-detail-data">
            {email}
          </div>
        </div>
      </div>
      <div className="plan-update-popup-buttons">
        <div className="plan-update-popup-button" onClick={onClose}>
          Abbrechen
        </div>
        <div className="plan-update-popup-button" onClick={handleConfirm}>
          Bestätigen
        </div>
      </div>
    </>
  );
}

export default PlanUpdateFirstPage;
