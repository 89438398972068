import createAsyncReducer from "../createAsyncReducer";
import {PROJECT_TYPES} from "../actionTypes/projectActionTypes";

export const hiddenLayersReducer = createAsyncReducer(PROJECT_TYPES.RX_GET_HIDDEN_LAYERS, {
    name: '',
    layers: {},
});

export const hiddenPublishersReducer = createAsyncReducer(PROJECT_TYPES.RX_GET_HIDDEN_PUBLISHERS, {
    name: '',
    publishers: {},
});

export const projectDetailsReducer = createAsyncReducer(PROJECT_TYPES.RX_GET_PROJECT_DETAILS, []);