import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector} from 'react-redux';

const  IsAuthLayer = ({ children }) => {
    const isAuth = useSelector(state => state.loginInfo.data.token);
    return (
        <>
            {isAuth ? children : <Redirect to={'/login'}/>}
        </>
    );
};

export default IsAuthLayer;
