import React from "react";
import blackConfirmIcon from "../../../images/blackConfirmIcon.png";
import greyConfirmIcon from "../../../images/greyIcon.png";

function SelectItem({ text, selected, handleClick }) {
    return (
        <div className={`select-layers-item ${selected? 'selected': ''}`} onClick={handleClick}>
            <img src={selected? blackConfirmIcon: greyConfirmIcon}/>
            <span title={text}>{text}</span>
        </div>
    );
}

export default SelectItem;