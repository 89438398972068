import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import controllerMiddleware from "../middlewares/controllerMiddleware";
import { semanticsReducer } from "../reducers/semanticsReducer";
import { chartCompaniesReducer } from "../reducers/companyReducer";
import { publisherHeatmapReducer, publisherFiltersReducer } from "../reducers/publisherReducer";
import { loginInfoReducer, userInfoReducer, companyInfoReducer, allPlansReducer, companyDataReducer } from "../reducers/userReducer";
import { hiddenLayersReducer, hiddenPublishersReducer, projectDetailsReducer } from "../reducers/projectReducer";
import { savedSettingsReducer } from "../reducers/savedSettingsReducer";
import { apiReducer } from "../reducers/apiReducer";
import { graphReducer } from "../reducers/graphReducer";

const rootReducers = combineReducers({
    semantics: semanticsReducer,
    chartCompanies: chartCompaniesReducer,
    userInfo: userInfoReducer,
    companyInfo: companyInfoReducer,
    loginInfo: loginInfoReducer,
    hiddenLayers: hiddenLayersReducer,
    hiddenPublishers: hiddenPublishersReducer,
    savedSettings: savedSettingsReducer,
    publisherHeatmap: publisherHeatmapReducer,
    publisherFilters: publisherFiltersReducer,
    api: apiReducer,
    projectDetails: projectDetailsReducer,
    allPlans: allPlansReducer,
    companyData: companyDataReducer,
    graphData: graphReducer,
});

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(controllerMiddleware)
    // other store enhancers if any
);

const store = createStore(rootReducers, enhancer);
export default store;
