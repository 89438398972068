import Fetch from "../fetch";

const END_POINT = {
    prefix: 'project',
    layer: 'layer',
    request: 'request',
    publisher: 'publisher',
};

class ProjectApiHandler {

    async getHiddenLayers(queryData) {
        return await Fetch.userGet(END_POINT.prefix, queryData);
    }

    async changeHiddenLayers(payload) {
        return await Fetch.userPost(`${END_POINT.prefix}/${END_POINT.layer}`, payload);
    }

    async changeHiddenPublishers(payload) {
        return await Fetch.userPost(`${END_POINT.prefix}/${END_POINT.publisher}`, payload);
    }

    async requestProject(payload) {
        return await Fetch.userPost(`${END_POINT.prefix}/${END_POINT.request}`, payload);
    }
}

const projectApiHandler = new ProjectApiHandler();
export default projectApiHandler;