import React, { useEffect, useState } from 'react';
import minusIcon from "../../../images/minusIcon.svg";
import plusIcon from "../../../images/btnPlus.svg";
import DateInput from "./dateInput";
import DateSection from "./dateSection";
import SubjectSection from "./subjectSection";
import SearchFilters from "./searchFilters";
import SearchFilterRow from "./searchFilterRow";
import FilterRowPlusIcon from "./FilterRowPlusIcon";
import { useSelector } from "react-redux";
import moment from 'moment';
import UseSemanticsController from "../../../customHooks/controllerHooks/semanticController";
import useDidUpdateEffect from "../../../customHooks/useDidUpdateEffect";
import UseProjectController from "../../../customHooks/controllerHooks/projectController";
import newsPaper from'../../../images/newspaper.svg';
import UseSavedSettingsController from "../../../customHooks/controllerHooks/savedSettingsController";

const now = new Date();
const oneYearBefore = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
const threeMonthBefore = new Date(new Date().setMonth(new Date().getMonth() - 3))


const endDateIsLess = (startDateStr, endDateStr) => {
    const startDate = new Date(moment(startDateStr).format());
    const endDate = new Date(moment(endDateStr).format());
    return startDate > endDate;
}



const valuesAreEmpty = values => {
    return values.coverage === '' &&
        values.company === '' &&
        values.publisher === '' &&
        values.people === '';
}

const formatDateForApi = date => moment(date).format('MM/DD/YYYY');

const initialValues = {
    coverage: '',
    company: '',
    publisher: '',
    people: '',
}



function HeatmapSearchSection({ openRequestForm, open, setHeatmapLoading }) {
    const selectedSubject = useSelector(state => state.userInfo.data.projects[0] || '');
    // const selectedSubject = 'blackrock'
    const [ subjectSelectedValue, setSubjectSelectedValue ] = useState(selectedSubject);
    const heatmapSettings = useSelector(state => state.savedSettings.heatmapSettings);
    const [ showFilters, setShowFilters ] = useState(false);
    const [ showCompanyFilters, setShowCompanyFilters ] = useState(false);
    const [ showPublisherFilters, setShowPublisherFilters ] = useState(false);
    const [ showPeopleFilters, setShowPeopleFilters ] = useState(false);

    const {
        isLoading: firstColumnLoading,
        data : {
            coverages: firstColumnCoverages,
            companies: firstColumnCompanies,
            publishers: firstColumnPublishers,
            peoples: firstColumnPeoples
        }
    } = useSelector(state => state.semantics.semantics.firstColumnV3);

    const {
        isLoading: secondColumnLoading,
        data : {
            coverages: secondColumnCoverages,
            companies: secondColumnCompanies,
            publishers: secondColumnPublishers,
            peoples: secondColumnPeoples
        }
    } = useSelector(state => state.semantics.semantics.secondColumnV3);

    const {
        isLoading: thirdColumnLoading,
        data : {
            coverages: thirdColumnCoverages,
            companies: thirdColumnCompanies,
            publishers: thirdColumnPublishers,
            peoples: thirdColumnPeoples
        }
    } = useSelector(state => state.semantics.semantics.thirdColumnV3);

    const {
        isLoading: fourthColumnLoading,
        data : {
            coverages: fourthColumnCoverages,
            companies:fourthColumnCompanies,
            publishers: fourthColumnPublishers,
            peoples: fourthColumnPeoples
        }
    } = useSelector(state => state.semantics.semantics.fourthColumnV3);
    const tableIsLoading = firstColumnLoading || secondColumnLoading || thirdColumnLoading || fourthColumnLoading;

    const {
        getFirstColumnV3,
        getSecondColumnV3,
        getThirdColumnV3,
        getFourthColumnV3,
        resetSecondColumnV3,
        resetThirdColumnV3,
        resetFourthColumnV3
    } = UseSemanticsController();

    const { getHiddenLayers } = UseProjectController();
    const { saveHeatMapSettings } = UseSavedSettingsController();


    const [ dateFrom, setDateFrom ] = useState(threeMonthBefore);
    const [ dateTo, setDateTo ] = useState(now);

    const startDate = formatDateForApi(dateFrom);
    const endDate = formatDateForApi(dateTo);


    const [ firstColumnValues, setFirstColumnValues ] = useState({
        ...initialValues,
    });

    const [ secondColumnValues, setSecondColumnValues ] = useState({
        ...initialValues,
    });

    const [ thirdColumnValues, setThirdColumnValues ] = useState({
        ...initialValues,
    });

    const [ fourthColumnValues, setFourthColumnValues ] = useState({
        ...initialValues,
    });


    const changeSubjectSelectedValue = value => {
        const valuesToSave = {
            secondColumnValues: {...secondColumnValues},
            thirdColumnValues: {...thirdColumnValues},
            fourthColumnValues: {...fourthColumnValues},
        };
        saveHeatMapSettings(subjectSelectedValue, valuesToSave);
        setSubjectSelectedValue(value);
    };

    useEffect(() => {
        const els = document.querySelectorAll('.search-first-row input');
        for (let i=0; i < els.length; i++) {
            els[i].setAttribute("readonly", "true");
        }
    }, []);

    useDidUpdateEffect(() => {
        setHeatmapLoading(true);
        getFirstColumnV3({
            startDate,
            endDate,
            project: subjectSelectedValue
        });
    }, [startDate, endDate, subjectSelectedValue]);

    useDidUpdateEffect(()=> {
        getHiddenLayers({name: subjectSelectedValue});
        if(heatmapSettings[subjectSelectedValue]){
            setSecondColumnValues({...heatmapSettings[subjectSelectedValue].secondColumnValues});
            setThirdColumnValues({...heatmapSettings[subjectSelectedValue].thirdColumnValues});
            setFourthColumnValues({...heatmapSettings[subjectSelectedValue].fourthColumnValues});

            const openCoverageFilters = !!(heatmapSettings[subjectSelectedValue].secondColumnValues.coverage ||
                heatmapSettings[subjectSelectedValue].thirdColumnValues.coverage ||
                heatmapSettings[subjectSelectedValue].fourthColumnValues.coverage);

            const openCompanyFilters = !!(heatmapSettings[subjectSelectedValue].secondColumnValues.company ||
                heatmapSettings[subjectSelectedValue].thirdColumnValues.company ||
                heatmapSettings[subjectSelectedValue].fourthColumnValues.company);

            const openPublisherFilters = !!(heatmapSettings[subjectSelectedValue].secondColumnValues.publisher ||
                heatmapSettings[subjectSelectedValue].thirdColumnValues.publisher ||
                heatmapSettings[subjectSelectedValue].fourthColumnValues.publisher);

            const openPeopleFilters = !!(heatmapSettings[subjectSelectedValue].secondColumnValues.people ||
                heatmapSettings[subjectSelectedValue].thirdColumnValues.people ||
                heatmapSettings[subjectSelectedValue].fourthColumnValues.people);
            if(openPeopleFilters) {
                !showFilters && setShowFilters(true);
                !showCompanyFilters && setShowCompanyFilters(true);
                !showPublisherFilters && setShowPublisherFilters(true);
                !showPeopleFilters && setShowPeopleFilters(true);
            }else if(openPublisherFilters){
                !showFilters && setShowFilters(true);
                !showCompanyFilters && setShowCompanyFilters(true);
                !showPublisherFilters && setShowPublisherFilters(true);
            } else if(openCompanyFilters) {
                !showFilters && setShowFilters(true);
                !showCompanyFilters && setShowCompanyFilters(true);
            } else if(openCoverageFilters) {
                !showFilters && setShowFilters(true);
            }

            // openPublisherFilters && !showPublisherFilters && setShowCompanyFilters(true) && setShowPublisherFilters(true);
            // openCompanyFilters && !showCompanyFilters && setShowCompanyFilters(true);
        } else {
            setSecondColumnValues({...initialValues});
            setThirdColumnValues({...initialValues});
            setFourthColumnValues({...initialValues});
        }
    }, [subjectSelectedValue])

    useDidUpdateEffect(() => {
        if (valuesAreEmpty(secondColumnValues)){
            resetSecondColumnV3();
        } else {
            getSecondColumnV3({
                ...secondColumnValues,
                startDate,
                endDate,
                project: subjectSelectedValue
            });
        }
    }, [secondColumnValues, startDate, endDate]);

    useDidUpdateEffect(() => {
        if (valuesAreEmpty(thirdColumnValues)){
            resetThirdColumnV3();
        } else {
            getThirdColumnV3({
                ...thirdColumnValues,
                startDate,
                endDate,
                project: subjectSelectedValue
            });
        }
    }, [thirdColumnValues, startDate, endDate]);

    useDidUpdateEffect(() => {
        if (valuesAreEmpty(fourthColumnValues)){
            resetFourthColumnV3();
        } else {
            getFourthColumnV3({
                ...fourthColumnValues,
                startDate,
                endDate,
                project: subjectSelectedValue
            });
        }
    }, [fourthColumnValues, startDate, endDate])


    const startDateInputChange = date => {
        setDateFrom(date);
        if(endDateIsLess(date, endDate)) {
            setDateTo(date);
        }
    };

    const endDateInputChange = date => {
        setDateTo(date);
    };


    const addFilterRow = () => {
        if(!showCompanyFilters) {
            setShowCompanyFilters(true);
        } else if (!showPublisherFilters) {
            setShowPublisherFilters(true);
        } else {
            setShowPeopleFilters(true);
        }
    };

    const toggleFiltersSection = () => {
        if(tableIsLoading) {
            return;
        }
        if(showFilters) {
            setSecondColumnValues({...initialValues});
            setThirdColumnValues({...initialValues});
            setFourthColumnValues({...initialValues});
        }
        setShowFilters(!showFilters);
        showCompanyFilters && setShowCompanyFilters(false)
        showPublisherFilters && setShowPublisherFilters(false);
        showPeopleFilters && setShowPeopleFilters(false);
    };

    const onCompaniesIconClick = () => {
        if(tableIsLoading) {
            return;
        }
        setShowCompanyFilters(false);

        if (secondColumnValues.company) {
            setSecondColumnValues({...secondColumnValues, company: ''});
        }

        if (thirdColumnValues.company) {
            setThirdColumnValues({...thirdColumnValues, company: ''});
        }

        if (fourthColumnValues.company) {
            setFourthColumnValues({...fourthColumnValues, company: ''});
        }
    }

    const onPublishersIconClick = () => {
        if(tableIsLoading) {
            return;
        }

        setShowPublisherFilters(false);
        if (secondColumnValues.publisher) {
            setSecondColumnValues({...secondColumnValues, publisher: ''});
        }
        if (thirdColumnValues.publisher) {
            setThirdColumnValues({...thirdColumnValues, publisher: ''});
        }

        if (fourthColumnValues.publisher) {
            setFourthColumnValues({...fourthColumnValues, publisher: ''});
        }
    };

    const onPeopleIconClick = () => {
        if(tableIsLoading) {
            return;
        }
        setShowPeopleFilters(false);
        if (secondColumnValues.people) {
            setSecondColumnValues({...secondColumnValues, people: ''});
        }
        if (thirdColumnValues.people) {
            setThirdColumnValues({...thirdColumnValues, people: ''});
        }
        if (fourthColumnValues.people) {
            setFourthColumnValues({...fourthColumnValues, people: ''});
        }
    };

    return (
        <div className={open ? "": "closed"}>
            <div className="search-first-row">
                <DateSection>
                    <DateInput
                        name="date-from"
                        label="From"
                        value={dateFrom}
                        handleChange={startDateInputChange}
                        min={oneYearBefore}
                        max={now}
                    />
                    <DateInput
                        name="date-to"
                        label="To"
                        value={dateTo}
                        handleChange={endDateInputChange}
                        min={dateFrom}
                        max={now}
                    />
                </DateSection>
                <SubjectSection
                    subjectSelectedValue={subjectSelectedValue}
                    setSubjectSelectedValue={changeSubjectSelectedValue}
                    icon={showFilters ? minusIcon : plusIcon}
                    handleClick={toggleFiltersSection}
                />
                <div className="request-button" onClick={()=>openRequestForm()}>
                    <img src={newsPaper} alt="" />
                    <span>Project request form</span>
                </div>
            </div>
            {showFilters &&
            <SearchFilters>
                <SearchFilterRow
                    rowTitle="Coverage"
                    name='coverage'
                    firstColumnValues={firstColumnValues}
                    secondColumnValues={secondColumnValues}
                    setSecondColumnValues={setSecondColumnValues}
                    thirdColumnValues={thirdColumnValues}
                    setThirdColumnValues={setThirdColumnValues}
                    fourthColumnValues={fourthColumnValues}
                    setFourthColumnValues={setFourthColumnValues}
                    secondColumnOptions={valuesAreEmpty(secondColumnValues)? firstColumnCoverages: secondColumnCoverages}
                    thirdColumnOptions={valuesAreEmpty(thirdColumnValues)? firstColumnCoverages: thirdColumnCoverages}
                    fourthColumnOptions={valuesAreEmpty(fourthColumnValues)? firstColumnCoverages: fourthColumnCoverages}
                    secondColumnLoading={secondColumnLoading || firstColumnLoading}
                    thirdColumnLoading={thirdColumnLoading || firstColumnLoading}
                    fourthColumnLoading={fourthColumnLoading || firstColumnLoading}
                />
                {showCompanyFilters &&
                <SearchFilterRow
                    rowTitle="Company"
                    name='company'
                    canClose={!showPublisherFilters}
                    onIconClick={onCompaniesIconClick}
                    firstColumnValues={firstColumnValues}
                    secondColumnValues={secondColumnValues}
                    setSecondColumnValues={setSecondColumnValues}
                    thirdColumnValues={thirdColumnValues}
                    setThirdColumnValues={setThirdColumnValues}
                    fourthColumnValues={fourthColumnValues}
                    setFourthColumnValues={setFourthColumnValues}
                    secondColumnOptions={valuesAreEmpty(secondColumnValues)? firstColumnCompanies: secondColumnCompanies}
                    thirdColumnOptions={valuesAreEmpty(thirdColumnValues)? firstColumnCompanies: thirdColumnCompanies}
                    fourthColumnOptions={valuesAreEmpty(fourthColumnValues)? firstColumnCompanies: fourthColumnCompanies}
                    secondColumnLoading={secondColumnLoading || firstColumnLoading}
                    thirdColumnLoading={thirdColumnLoading || firstColumnLoading}
                    fourthColumnLoading={fourthColumnLoading || firstColumnLoading}
                />}
                {showPublisherFilters &&
                <SearchFilterRow
                    rowTitle="Publisher"
                    name='publisher'
                    canClose={!showPeopleFilters}
                    onIconClick={onPublishersIconClick}
                    firstColumnValues={firstColumnValues}
                    secondColumnValues={secondColumnValues}
                    setSecondColumnValues={setSecondColumnValues}
                    thirdColumnValues={thirdColumnValues}
                    setThirdColumnValues={setThirdColumnValues}
                    fourthColumnValues={fourthColumnValues}
                    setFourthColumnValues={setFourthColumnValues}
                    secondColumnOptions={valuesAreEmpty(secondColumnValues)? firstColumnPublishers: secondColumnPublishers}
                    thirdColumnOptions={valuesAreEmpty(thirdColumnValues)? firstColumnPublishers: thirdColumnPublishers}
                    fourthColumnOptions={valuesAreEmpty(fourthColumnValues)? firstColumnPublishers: fourthColumnPublishers}
                    secondColumnLoading={secondColumnLoading || firstColumnLoading}
                    thirdColumnLoading={thirdColumnLoading || firstColumnLoading}
                    fourthColumnLoading={fourthColumnLoading || firstColumnLoading}
                />}
                {showPeopleFilters &&
                <SearchFilterRow
                    rowTitle="People"
                    name='people'
                    canClose={true}
                    onIconClick={onPeopleIconClick}
                    firstColumnValues={firstColumnValues}
                    secondColumnValues={secondColumnValues}
                    setSecondColumnValues={setSecondColumnValues}
                    thirdColumnValues={thirdColumnValues}
                    setThirdColumnValues={setThirdColumnValues}
                    fourthColumnValues={fourthColumnValues}
                    setFourthColumnValues={setFourthColumnValues}
                    secondColumnOptions={valuesAreEmpty(secondColumnValues)? firstColumnPeoples: secondColumnPeoples}
                    thirdColumnOptions={valuesAreEmpty(thirdColumnValues)? firstColumnPeoples: thirdColumnPeoples}
                    fourthColumnOptions={valuesAreEmpty(fourthColumnValues)? firstColumnPeoples: fourthColumnPeoples}
                    secondColumnLoading={secondColumnLoading}
                    thirdColumnLoading={thirdColumnLoading}
                    fourthColumnLoading={fourthColumnLoading}
                />}
                {!showPeopleFilters &&
                <FilterRowPlusIcon
                    handleClick={addFilterRow} />}
            </SearchFilters>}
        </div>
    )
}

export default HeatmapSearchSection;