import Operations from "./operations";
import coverageApiHandler from "../apiHandlers/coverageApiHandler";

class CoverageOperations extends Operations {
    constructor(coverageApiHandler) {
        super();
        this.coverageApiHandler = coverageApiHandler;
    }

    async getPublisherFilterCoverage(queryData, abortSignal) {
        const response =  await this.coverageApiHandler.getPublisherFilterCoverage(queryData, abortSignal);
        return response && response.map(item => ({name: item.coverage, number: item.number}));
    }
}

const coverageOperations = new CoverageOperations(coverageApiHandler);

export default coverageOperations;