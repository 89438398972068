import React from 'react';
import SubjectSelect from "./subjectSelect";

function SubjectSection({ icon, handleClick,subjectSelectedValue, setSubjectSelectedValue, showExtendedFilters = true }) {
    return (
        <div className="subject-section">
            <SubjectSelect
                subjectSelectedValue={subjectSelectedValue}
                setSubjectSelectedValue={setSubjectSelectedValue}/>
            {/*<button type="button" className="project-details-button">*/}
            {/*    Project details*/}
            {/*</button>*/}
            {
                showExtendedFilters &&
                <div className="subject-right-side">
                    <span className="subject">Apply extended filters</span>
                    <div className="icon-container minus-icon" onClick={handleClick}>
                        <img src={icon} alt=""/>
                    </div>
                </div>
            }
        </div>
    );
}

export default SubjectSection;