import React, {useEffect, useState} from "react";
import UserInfo from "../../components/userInfo";
import {ACCOUNT_TABS} from "../../../core/constants/accountTabs";
import AccountTabs from "../../components/accountTabs";
import MySettingsBar from "../../components/mySettingsBar";
import PlansSection from "../../components/plansSection";
import RequestPopup from "../../components/requestPopup";
import ProjectsSection from "../../components/projectsSection";
import ApiSection from "../../components/apiSection";
import UseLoginController from '../../../customHooks/controllerHooks/loginController';
import {useSelector} from 'react-redux';
import InvoiceSection from '../../components/invoiceSection';

function AccountPage() {
    const [ activeTab, setActiveTab ] = useState(ACCOUNT_TABS.MY_PROJECTS);
    const [ open, setOpen ] = useState(false);
    const { getAllPlans, getCompanyData } = UseLoginController();
    const companyName = useSelector(state => state.userInfo.data.company);

    useEffect(() => {
      getAllPlans();
      getCompanyData({name: companyName})
    } , []);

    return (
        <>
            <UserInfo />
            <AccountTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="settings-section">
                <MySettingsBar />
                {activeTab===ACCOUNT_TABS.MY_PLAN && <PlansSection />}
                {activeTab===ACCOUNT_TABS.MY_PROJECTS && <ProjectsSection openRequestPopup={()=>setOpen(true)} />}
                {/*{activeTab===ACCOUNT_TABS.INVOICES && <InvoiceSection />}*/}
                {activeTab===ACCOUNT_TABS.API && <ApiSection />}
            </div>
            {open && <RequestPopup open={open} setOpen={setOpen}/>}

        </>
    );
}

export default AccountPage;
