import React, {useEffect, useState} from 'react';
import Header from "../../components/header";
import emailIcon from '../../../images/emailIcon.svg';
import passwordIcon from '../../../images/passwordIcon.svg';
import AccessBar from "../../components/accessBar";
import DataBar from "../../components/dataBar";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";
import { useSelector } from "react-redux";
import Recaptcha from 'react-recaptcha';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { getUrlSearchParam} from "../../../core/helpers/getUrlSearchParam";

const VERIFY_URL =' https://api.evai-intelligence.com/user/company/register/verify-account';

const verifyUrl = async(url) => {
    const response =  await fetch(`${VERIFY_URL}${url}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if(!response.ok) {
        return;
    }

    return await response.clone().text();
}


function LoginPage() {

    const [ password, setPassword ] = useState('');
    const error = useSelector(state => state.loginInfo.data.error);
    const { login, verifyAccount } = UseLoginController();
    const { path } = useRouteMatch();
    const { search } = useLocation();

    const showRecaptcha = path === '/verify';
    const paramCode = getUrlSearchParam('code');

    const [ email, setEmail ] = useState('');
    const [ verified, setVerified ] = useState(true);


    // useEffect(() => {
    //     if(path === '/verify') {
    //         setVerified(false);
    //     } else {
    //         setVerified(true);
    //     }
    // }, [path]);


    useEffect(() => {
        if(showRecaptcha && paramCode) {
            verifyUrl(search)
                .then(res => {
                    if (typeof res ==='string') {
                        setEmail(res);
                    }
                })
        }
    }, []);


    const handleSubmit = e => {
        e.preventDefault();
        const payload = {
            email,
            password
        };
        verified &&
        login(payload);
    }

    const verifyCallback = () => {
        setVerified(true);
    }

    const expiredCallback = () => {
        setVerified(false);
    }

    const onloadCallback = () => {
        setVerified(false);
    }

    return(
        <div className='main-wrapper'>
            <Header />
            <div className={`login-section ${error? 'error' : ''}`}>
                <AccessBar />
                <div className="login-block">
                    <DataBar />
                    <div>
                        <form className="login-inputs" onSubmit={handleSubmit}>
                            <div className="input-row">
                                <img src={emailIcon} className="input-icon" alt="" />
                                <input type="text"
                                       // name="email"
                                       placeholder="john.dow@evai-intelligence.com"
                                       spellCheck={false}
                                       value={email}
                                       onChange={e=>setEmail(e.target.value.trim())}
                                       // autoComplete="off"
                                />
                            </div>
                            <div className="input-row">
                                <img src={passwordIcon} className="input-icon" alt=""/>
                                <input type="password"
                                       // name="password"
                                       placeholder="enter your password"
                                       value={password}
                                       onChange={e=>setPassword(e.target.value.trim())}
                                       autoComplete="new-password"
                                />
                            </div>
                            {
                                showRecaptcha &&
                                <div className="recaptcha-block">
                                    <Recaptcha
                                        // sitekey="6Leu8RYaAAAAADlaYNGcNpPS-_zq8KUuyBVL_Tuh"
                                        sitekey="6LdsOsgUAAAAACKyl6hv8KD96h_vCTVLodg69AUE"
                                        render="explicit"
                                        verifyCallback={verifyCallback}
                                        expiredCallback={expiredCallback}
                                        onloadCallback={onloadCallback}
                                    />
                                </div>
                            }

                            <div className="input-row">
                                <button type="submit">login</button>
                            </div>
                        </form>
                        <div className="error-block">
                            <p>The login or password is not correct.</p>
                            <p>Please try again.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;
