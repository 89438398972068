import Fetch from "../fetch";

const END_POINT = {
    entities: 'entities',
    semantics: 'semantics',
    article: 'article',
};

class ApiApiHandler {

    async getArticleEntities(data, language) {
        return  await Fetch.apiPost(`${END_POINT.entities}/${END_POINT.article}?Language=${language}`, data);
    }

    async getArticleSemantics(data, language) {
        return  await Fetch.apiPost(`${END_POINT.semantics}/${END_POINT.article}?language=${language}`, data);
    }
}

const apiApiHandler = new ApiApiHandler();
export default apiApiHandler;