import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import PlanUpdatePopup from "./planUpdatePopup";
import moment from "moment";
import DeleteAccountPopup from "./deleteAccountPopup";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";
import CancelAccountPopup from "./cancelAccountPopup";
import PLAN_NAMES from "../../../core/constants/planNames";

const Plans = [
    {
        "name": "Plus",
        "description": "2 data pipeline: fixed sources",
        "period": 1,
        "userCount": 5,
        "exportsCount": 10,
        "selfSelectedProjectsCount": 5,
        "sharedProjectsCount": 0,
        "price": null
    },
    {
        "name": "Pro",
        "description": "1 data pipeline: fixed sources",
        "period": 1,
        "userCount": 1,
        "exportsCount": 2,
        "selfSelectedProjectsCount": 0,
        "sharedProjectsCount": 1,
        "price": null
    },
    {
        "name": "Large",
        "description": "1 data pipleine: plus TV, radio, clippings (lead media)",
        "period": 1,
        "userCount": 5,
        "exportsCount": 50,
        "selfSelectedProjectsCount": 10,
        "sharedProjectsCount": 0,
        "price": null
    },
    {
        "name": "ENTERPRISE",
        "description": "1 data pipleine: plus TV, radio including custom sources",
        "period": 1,
        "userCount": 100000,
        "exportsCount": 100000,
        "selfSelectedProjectsCount": 100000,
        "sharedProjectsCount": 100000,
        "price": null
    }
]

function PlansSection() {
    const allPlans = useSelector(state => state.allPlans.data);
    const planName = useSelector(state => state.companyData.data.plan?.name);
    // const planName = 'Pro';
    const planExpirationDate = useSelector(state => state.companyData.data?.planExpirationDate);

    const [ openPlanUpdateModal, setOpenPlanUpdateModal ] = useState(false);
    const [ openCancelModal, setOpenCancelModal ] = useState(false);
    const [ selectedPlanName, setSelectedPlanName ] = useState(null);


    const handleSubscribeButtonClick = (planName) => {
        setOpenPlanUpdateModal(true);
        setSelectedPlanName(planName)
    }

    return (
        <div className="plans-section">
            {allPlans.map(plan => {
                const planIsActive = plan.name === planName;
                const planIsEnterPrise = plan.name === 'ENTERPRISE';
                return (
                  <div className={`plan-item ${plan.name === 'Plus'? 'recommended': ''}`} key={plan.name}>
                      <div className="plan-status">
                          {plan.name === 'Plus'? 'Recommended': ''}
                          {/*{planIsActive ? 'Active Plan': plan.name === 'Plus'? 'Recommended': ''}*/}
                      </div>
                      <div className="plan-block">
                          <div className="plan-block-header">
                              {plan.name.toLowerCase()}
                          </div>
                          <div className="plan-block-data">
                              <div className="plan-block-data-item">
                                  <span>{plan.period}</span> {`year${plan.period > 1 ? 's': ''} of data`}
                              </div>
                              <div className="plan-block-data-item plan-description">
                                  {plan.description}
                              </div>
                              <div className="plan-block-data-item">
                                  <span>{planIsEnterPrise ? 'X': plan.userCount}</span> {`user${plan.userCount > 1 ? 's': ''}`}
                              </div>
                              <div className="plan-block-data-item">
                                  <span>{planIsEnterPrise? 'X':plan.exportsCount}</span> exports
                              </div>
                              {
                                  !!plan.selfSelectedProjectsCount &&
                                  <div className="plan-block-data-item">
                                      <span>{planIsEnterPrise? 'X': plan.selfSelectedProjectsCount}</span> self selected projects
                                  </div>
                              }
                              {
                                  !!plan.sharedProjectsCount &&
                                  <div className="plan-block-data-item">
                                      <span>{planIsEnterPrise?  'X':plan.sharedProjectsCount}</span> {`shared project${plan.sharedProjectsCount > 1 ? 's': ''}`}
                                  </div>
                              }
                          </div>
                          <div className="plan-block-footer">
                              {plan.price &&
                                <>
                                  <div className="plan-block-footer-title">
                                      Price
                                  </div>
                                  <div className="plan-block-footer-description">
                                      €{plan.price}
                                  </div>
                                  </>}

                          </div>
                      </div>
                      {
                          planIsActive ?
                            <>
                                {/*{*/}
                                {/*    planName !== PLAN_NAMES.ENTERPRISE &&*/}
                                {/*    <button type="button" className="plan-action-button cancel" onClick={()=>setOpenCancelModal(true)}>*/}
                                {/*        Cancel plan*/}
                                {/*    </button>*/}
                                {/*}*/}
                                {
                                    !!planExpirationDate &&
                                    <div className="plan-expire">
                                        <div className="plan-block-footer-title">
                                            Laufzeit
                                        </div>
                                        <div className="plan-block-expire-date">
                                            {moment(planExpirationDate).format('DD/MM/YYYY')}
                                        </div>
                                    </div>
                                }
                            </> : planIsEnterPrise && planName !== PLAN_NAMES.ENTERPRISE ?
                                <button type="button" className="plan-action-button">
                                    Ask us
                                </button> :
                              planName !== PLAN_NAMES.ENTERPRISE ?
                                  null:
                                // <button type="button" className="plan-action-button" onClick={()=>handleSubscribeButtonClick(plan.name)}>
                                //     Subscribe
                                // </button>:
                                  null
                      }
                  </div>
                )
            })}
            {openPlanUpdateModal &&
            <PlanUpdatePopup
                onClose={()=>setOpenPlanUpdateModal(false)}
                selectedPlanName={selectedPlanName}
                setSelectedPlanName={setSelectedPlanName}
                currentPlanName={planName}
            />}
            {openCancelModal &&
            <CancelAccountPopup onClose={()=>setOpenCancelModal(false)}/>
            }

            {/*<DeleteAccountPopup />*/}
        </div>
    );
}

export default PlansSection;
