import React from "react";
import {useSelector} from "react-redux";
import DygraphChart from "./dygraphChart";

function Charts() {

    const firstChartData = useSelector(state => state.semantics.semantics.firstChartData.data);
    const secondChartData = useSelector(state => state.semantics.semantics.secondChartData.data);
    const thirdChartData = useSelector(state => state.semantics.semantics.thirdChartData.data);
    const fourthChartData =useSelector(state => state.semantics.semantics.fourthChartData.data);

    return(
        <div style = {{ marginLeft: 20}}>
            <DygraphChart chartData={firstChartData} />
            <DygraphChart chartData={secondChartData} />
            <DygraphChart chartData={thirdChartData} />
            <DygraphChart chartData={fourthChartData} />
        </div>
    );
}

export default Charts;