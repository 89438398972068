import React, { useEffect, useState } from 'react';
import SearchSection from "../../components/searchSection";
import TableSection from "../../components/tableSection";
import { formatDateForApi } from "../../components/splash";
import RequestPopup from "../../components/requestPopup";
import { TABLE_TABS } from "../../../core/constants/tableTabs";
import UseCompanyController from "../../../customHooks/controllerHooks/companyController";
import UseCoverageController from "../../../customHooks/controllerHooks/coverageController";
import { useSelector } from "react-redux";

const now = new Date();
const oneYearBefore = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

function MainPage() {
    const [ requestPopupOpen, setRequestPopupOpen ] = useState(false);
    const [ activeTab, setActiveTab ] = useState(TABLE_TABS.DASHBOARD);
    const [ dashboardLoading, setDashboardLoading ] = useState(false);
    const [ heatmapLoading, setHeatmapLoading ] = useState(false);
    const [ publisherHeatmapLoading, setPublisherHeatmapLoading ] = useState(false);

    const selectedSubject = useSelector(state => state.userInfo.data.projects[0] || '');
    const [ networkSelectedValue, setNetworkSelectedValue ] = useState(selectedSubject);
    // const [ networkSelectedValue, setNetworkSelectedValue ] = useState('artificialint');

    const startDate = formatDateForApi(oneYearBefore);
    const endDate = formatDateForApi(now);

    const { getPublisherFilterAllColumnCompanies } = UseCompanyController();
    const { getPublisherFilterAllColumnCoverage } = UseCoverageController();

    useEffect(() => {
        getPublisherFilterAllColumnCoverage({project: selectedSubject, startDate, endDate});
        getPublisherFilterAllColumnCompanies({project: selectedSubject, startDate, endDate});
    }, []);


    return (
            <>
                <SearchSection
                    openRequestForm={()=>setRequestPopupOpen(true)}
                    activeTab={activeTab}
                    setDashboardLoading={setDashboardLoading}
                    setHeatmapLoading={setHeatmapLoading}
                    setPublisherHeatmapLoading={setPublisherHeatmapLoading}
                    networkSelectedValue={networkSelectedValue}
                    setNetworkSelectedValue={setNetworkSelectedValue}
                />
                <TableSection
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    heatmapLoading={heatmapLoading}
                    dashboardLoading={dashboardLoading}
                    setDashboardLoading={setDashboardLoading}
                    setHeatmapLoading={setHeatmapLoading}
                    publisherHeatmapLoading={publisherHeatmapLoading}
                    setPublisherHeatmapLoading={setPublisherHeatmapLoading}
                    networkSelectedValue={networkSelectedValue}
                />
                {requestPopupOpen && <RequestPopup open={requestPopupOpen} setOpen={setRequestPopupOpen}/>}
            </>
    );
}

export default MainPage;
