import React from "react";
import {useSelector} from "react-redux";
import EntityDescription from "./entityDescription";
import LoadingText from "./loadingText";
import GraphLoader from "./graphLoader";


function EntityDetails({ fullScreen }) {

    const { data: entityDetails, isLoading } = useSelector(state => state.graphData.entityDetails);

    return (
        <div className={fullScreen ? "full-screen-details-section": "entity-details"}>
            {
                isLoading ?
                    <GraphLoader controlled={false} text="Loading..." />:
                    entityDetails ?
                        <>
                            <div className="entity-title">
                                Entity:
                                <span>{entityDetails.name}</span>
                            </div>
                            <div className="entity-title">
                                No of Articles:
                                <span>{entityDetails.n_of_articles}</span>
                            </div>

                            <EntityDescription title='Nearness' layers={entityDetails.neighbors_1st_level} />

                            <EntityDescription title='Topic Environment' layers={entityDetails.article_layers} />

                            <EntityDescription title='Context' layers={entityDetails.context} isContext/>

                        </> :
                        null

            }
        </div>
    );
}

export default EntityDetails;