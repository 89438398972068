import React, {useEffect, useRef, useState} from "react";
import minusIcon from "../../../images/minusIcon.svg";
import plusIcon from "../../../images/btnPlus.svg";
import {useSelector} from "react-redux";
import FiltersTable from "./filtersTable";
import LayersBlock from "./layersBlock";


const filterOptions = (options, layer1, layer2, layer3, layer4) => {
    return options.filter(option => {
        return option.name !== layer1 &&
               option.name !== layer2 &&
               option.name !== layer3 &&
               option.name !== layer4
    })
}
function ChartFilterRow({ values, setValues, showLayersHeader, handleClose, selectedSubject }) {
    const chartCompanies = useSelector(state => state.chartCompanies.data);
    const isLoading = useSelector(state => state.chartCompanies.isLoading);
    const chartLayers = useSelector(state => state.semantics.chartLayers.data);
    const { firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer } = values;
    const firstLayerOptions = filterOptions(chartLayers, secondLayer, thirdLayer, fourthLayer, fifthLayer);
    const secondLayerOptions = filterOptions(chartLayers, firstLayer, thirdLayer, fourthLayer, fifthLayer);
    const thirdLayerOptions = filterOptions(chartLayers, firstLayer, secondLayer, fourthLayer, fifthLayer);
    const fourthLayerOptions = filterOptions(chartLayers, firstLayer, secondLayer, thirdLayer, fifthLayer);
    const fifthLayerOptions = filterOptions(chartLayers, firstLayer, secondLayer, thirdLayer, fourthLayer);


    const [ filterValue, setFilterValue ] = useState('');
    const [ showSelect, setShowSelect ] = useState(false);
    const [ plusIconClicked, setPlusIconClicked ] = useState(false);

    const showSecondLayer = !!values.secondLayer || values.firstLayer && plusIconClicked;
    const showThirdLayer = !!values.thirdLayer || values.secondLayer && plusIconClicked;
    const showFourthLayer = !!values.fourthLayer || values.thirdLayer && plusIconClicked;
    const showFifthLayer =!!values.fifthLayer || values.fourthLayer && plusIconClicked;

    const showPlusIcon = values.firstLayer && !showSecondLayer ||
                         values.secondLayer && !showThirdLayer ||
                         values.thirdLayer && !showFourthLayer ||
                         values.fourthLayer && !showFifthLayer

    const ref = useRef(null);

    const closeDropdown = () => {
        setShowSelect(false);
        setFilterValue('');
    };

    const handleClickOutside = e => {
        if(ref.current && !ref.current.contains(e.target)){
            closeDropdown();
        }
    };

    const toggleDropdown = () => {
        if(showSelect){
            setShowSelect(false);
            setFilterValue('');
        } else {
            setShowSelect(true);
        }
    };

    const minusIconClick = () => {
        setValues({
            ...values,
            firstLayer: '',
            secondLayer: '',
            thirdLayer: '',
            fourthLayer: '',
            fifthLayer: '',
        })
        setPlusIconClicked(false);
    }

    const plusIconClick = () => {
        setPlusIconClicked(true);
    }

    const onClose = () => {
        handleClose();
        setPlusIconClicked(false);
    }


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    return (
        <div className="chart-filters-row">
            {showLayersHeader && values.company &&
            <div className="layers-header">
                Layers
            </div>}
            <div className="icon-container icon-light" onClick={onClose}>
                <img src={minusIcon} alt="" />
            </div>
            <div className={`company-name-block ${showSelect? "open": ''}`} ref={ref}>
                <div className="company-name-value" onClick={toggleDropdown} title={values.company}>
                    {values.company ? values.company : <div className="company-name-placeholder">Choose company</div>}
                </div>
                <FiltersTable  filterValue={filterValue}
                               setFilterValue={setFilterValue}
                               options={chartCompanies}
                               selectedValue={values.company}
                               handleChange={value=>setValues({...values, company: value})}
                               setShowSelect={setShowSelect}
                               showAllOption
                               showAllSelected
                               isLoading={isLoading}/>
            </div>
            {values.company &&
            <div style={{display: 'flex'}}>
                <div className="icon-container icon-light" onClick={minusIconClick}>
                    <img src={minusIcon} alt=""/>
                </div>
                <LayersBlock color="orange"
                             options={firstLayerOptions}
                             selectedValue={values.firstLayer}
                             handleChange={value=>{
                                 setValues({...values, firstLayer: value});
                                 setPlusIconClicked(false);
                             }}/>
                {showSecondLayer &&
                <LayersBlock color="green"
                             options={secondLayerOptions}
                             selectedValue={values.secondLayer}
                             handleChange={value=>{
                                 setValues({...values, secondLayer: value});
                                 setPlusIconClicked(false);
                             }}
                />}
                {showThirdLayer &&
                <LayersBlock color="sky-blue"
                             options={thirdLayerOptions}
                             selectedValue={values.thirdLayer}
                             handleChange={value=>{
                                 setValues({...values, thirdLayer: value});
                                 setPlusIconClicked(false);
                             }}
                />}
                {showFourthLayer &&
                <LayersBlock color="purple"
                             options={fourthLayerOptions}
                             selectedValue={values.fourthLayer}
                             handleChange={value=>{
                                 setValues({...values, fourthLayer: value});
                                 setPlusIconClicked(false);
                             }}
                />}
                {showFifthLayer &&
                <LayersBlock color="blue"
                             options={fifthLayerOptions}
                             selectedValue={values.fifthLayer}
                             handleChange={value=>{
                                 setValues({...values, fifthLayer: value});
                                 setPlusIconClicked(false);
                             }}
                />}
                {showPlusIcon &&
                <div className="icon-container icon-light" onClick={plusIconClick}>
                    <img src={plusIcon} alt=""/>
                </div>}
            </div>}


        </div>
    )
}

export default ChartFilterRow;