export const SEMANTICS_TYPES = {

    RX_GET_CHART_COMPANIES: 'RX_GET_CHART_COMPANIES',
    RX_GET_CHART_COMPANIES_PENDING: 'RX_GET_CHART_COMPANIES_PENDING',
    RX_GET_CHART_COMPANIES_DONE: 'RX_GET_CHART_COMPANIES_DONE',

    RX_GET_FIRST_COLUMN: 'RX_GET_FIRST_COLUMN',
    RX_GET_FIRST_COLUMN_PENDING: 'RX_GET_FIRST_COLUMN_PENDING',
    RX_GET_FIRST_COLUMN_DONE: 'RX_GET_FIRST_COLUMN_DONE',

    RX_GET_SECOND_COLUMN: 'RX_GET_SECOND_COLUMN',
    RX_GET_SECOND_COLUMN_PENDING: 'RX_GET_SECOND_COLUMN_PENDING',
    RX_GET_SECOND_COLUMN_DONE: 'RX_GET_SECOND_COLUMN_DONE',

    RX_GET_THIRD_COLUMN: 'RX_GET_THIRD_COLUMN',
    RX_GET_THIRD_COLUMN_PENDING: 'RX_GET_THIRD_COLUMN_PENDING',
    RX_GET_THIRD_COLUMN_DONE: 'RX_GET_THIRD_COLUMN_DONE',

    RX_GET_FOURTH_COLUMN: 'RX_GET_FOURTH_COLUMN',
    RX_GET_FOURTH_COLUMN_PENDING: 'RX_GET_FOURTH_COLUMN_PENDING',
    RX_GET_FOURTH_COLUMN_DONE: 'RX_GET_FOURTH_COLUMN_DONE',

    RX_GET_FIRST_COLUMN_V3: 'RX_GET_FIRST_COLUMN_V3',
    RX_GET_FIRST_COLUMN_V3_PENDING: 'RX_GET_FIRST_COLUMN_V3_PENDING',
    RX_GET_FIRST_COLUMN_V3_DONE: 'RX_GET_FIRST_COLUMN_V3_DONE',

    RX_GET_SECOND_COLUMN_V3: 'RX_GET_SECOND_COLUMN_V3',
    RX_GET_SECOND_COLUMN_V3_PENDING: 'RX_GET_SECOND_COLUMN_V3_PENDING',
    RX_GET_SECOND_COLUMN_V3_DONE: 'RX_GET_SECOND_COLUMN_V3_DONE',

    RX_GET_THIRD_COLUMN_V3: 'RX_GET_THIRD_COLUMN_V3',
    RX_GET_THIRD_COLUMN_V3_PENDING: 'RX_GET_THIRD_COLUMN_V3_PENDING',
    RX_GET_THIRD_COLUMN_V3_DONE: 'RX_GET_THIRD_COLUMN_V3_DONE',

    RX_GET_FOURTH_COLUMN_V3: 'RX_GET_FOURTH_COLUMN_V3',
    RX_GET_FOURTH_COLUMN_V3_PENDING: 'RX_GET_FOURTH_COLUMN_V3_PENDING',
    RX_GET_FOURTH_COLUMN_V3_DONE: 'RX_GET_FOURTH_COLUMN_V3_DONE',

    RX_GET_CHART_LAYERS: 'RX_GET_CHART_LAYERS',
    RX_GET_CHART_LAYERS_PENDING: 'RX_GET_CHART_LAYERS_PENDING',
    RX_GET_CHART_LAYERS_DONE: 'RX_GET_CHART_LAYERS_DONE',

    RX_GET_FIRST_CHART_ALL_LAYERS: 'RX_GET_FIRST_CHART_ALL_LAYERS',
    RX_GET_FIRST_CHART_ALL_LAYERS_PENDING: 'RX_GET_FIRST_CHART_ALL_LAYERS_PENDING',
    RX_GET_FIRST_CHART_ALL_LAYERS_DONE: 'RX_GET_FIRST_CHART_ALL_LAYERS_DONE',

    RX_GET_SECOND_CHART_ALL_LAYERS: 'RX_GET_SECOND_CHART_ALL_LAYERS',
    RX_GET_SECOND_CHART_ALL_LAYERS_PENDING: 'RX_GET_SECOND_CHART_ALL_LAYERS_PENDING',
    RX_GET_SECOND_CHART_ALL_LAYERS_DONE: 'RX_GET_SECOND_CHART_ALL_LAYERS_DONE',

    RX_GET_THIRD_CHART_ALL_LAYERS: 'RX_GET_THIRD_CHART_ALL_LAYERS',
    RX_GET_THIRD_CHART_ALL_LAYERS_PENDING: 'RX_GET_THIRD_CHART_ALL_LAYERS_PENDING',
    RX_GET_THIRD_CHART_ALL_LAYERS_DONE: 'RX_GET_THIRD_CHART_ALL_LAYERS_DONE',

    RX_GET_FOURTH_CHART_ALL_LAYERS: 'RX_GET_FOURTH_CHART_ALL_LAYERS',
    RX_GET_FOURTH_CHART_ALL_LAYERS_PENDING: 'RX_GET_FOURTH_CHART_ALL_LAYERS_PENDING',
    RX_GET_FOURTH_CHART_ALL_LAYERS_DONE: 'RX_GET_FOURTH_CHART_ALL_LAYERS_DONE',

    RX_GET_FIRST_CHART_DATA: 'RX_GET_FIRST_CHART_DATA',
    RX_GET_FIRST_CHART_DATA_PENDING: 'RX_GET_FIRST_CHART_DATA_PENDING',
    RX_GET_FIRST_CHART_DATA_DONE: 'RX_GET_FIRST_CHART_DATA_DONE',

    RX_GET_SECOND_CHART_DATA: 'RX_GET_SECOND_CHART_DATA',
    RX_GET_SECOND_CHART_DATA_PENDING: 'RX_GET_SECOND_CHART_DATA_PENDING',
    RX_GET_SECOND_CHART_DATA_DONE: 'RX_GET_SECOND_CHART_DATA_DONE',

    RX_GET_THIRD_CHART_DATA: 'RX_GET_THIRD_CHART_DATA',
    RX_GET_THIRD_CHART_DATA_PENDING: 'RX_GET_THIRD_CHART_DATA_PENDING',
    RX_GET_THIRD_CHART_DATA_DONE: 'RX_GET_THIRD_CHART_DATA_DONE',

    RX_GET_FOURTH_CHART_DATA: 'RX_GET_FOURTH_CHART_DATA',
    RX_GET_FOURTH_CHART_DATA_PENDING: 'RX_GET_FOURTH_CHART_DATA_PENDING',
    RX_GET_FOURTH_CHART_DATA_DONE: 'RX_GET_FOURTH_CHART_DATA_DONE',
}

