import { useDispatch, useSelector } from "react-redux";
import {
    rxGetHiddenLayersDone,
    rxGetHiddenLayersPending, rxGetHiddenPublishersDone,
    rxGetHiddenPublishersPending, rxGetProjectDetailsDone, rxGetProjectDetailsPending
} from "../../state-management/actions/projectActions";
import projectOperations from "../../services/operations/projectOperations";

function UseProjectController() {
    const dispatch = useDispatch();
    const hiddenLayers = useSelector(state => state.hiddenLayers.data);
    const hiddenPublishers = useSelector(state => state.hiddenPublishers.data);

    async function getHiddenLayers(queryData) {
        dispatch(rxGetHiddenLayersPending());
        const response = await projectOperations.getHiddenLayers(queryData);
        dispatch(rxGetHiddenLayersDone(response));
    }

    async function getHiddenPublishers(queryData) {
        dispatch(rxGetHiddenPublishersPending());
        const response = await projectOperations.getHiddenPublishers(queryData);
        dispatch(rxGetHiddenPublishersDone(response));
    }

    async function changeHiddenLayers(payload) {
        await projectOperations.changeHiddenLayers(payload);
        const { layer, hide } = payload;
        const layers = {...hiddenLayers.layers};
        layers[layer] = hide;
        dispatch(rxGetHiddenLayersDone({...hiddenLayers, layers: {...layers}}))
    }

    async function changeHiddenPublishers(payload) {
        await projectOperations.changeHiddenPublishers({
            project: payload.project,
            publisher: payload.layer,
            hide: payload.hide
        });
        const { layer, hide } = payload;
        const publishers = {...hiddenPublishers.publishers};
        publishers[layer] = hide;
        dispatch(rxGetHiddenPublishersDone({...hiddenPublishers, publishers: {...publishers}}));
    }

    async function requestProject(payload) {
        return  await projectOperations.requestProject(payload);
    }

    async function getProjectDetails(queryData) {
        dispatch(rxGetProjectDetailsPending());
        const response = await projectOperations.getProjectDetails(queryData);
        dispatch(rxGetProjectDetailsDone(response));
    }

    return {
        getHiddenLayers,
        getHiddenPublishers,
        changeHiddenLayers,
        changeHiddenPublishers,
        requestProject,
        getProjectDetails,
    }
}

export default UseProjectController;