export const USER_TYPES = {
    RX_GET_USER_INFO: 'RX_GET_USER_INFO',
    RX_GET_USER_INFO_PENDING: 'RX_GET_USER_INFO_PENDING',
    RX_GET_USER_INFO_DONE: 'RX_GET_USER_INFO_DONE',

    RX_GET_LOGIN_INFO: 'RX_GET_LOGIN_INFO',
    RX_GET_LOGIN_INFO_PENDING: 'RX_GET_LOGIN_INFO_PENDING',
    RX_GET_LOGIN_INFO_DONE: 'RX_GET_LOGIN_INFO_DONE',

    RX_GET_COMPANY_INFO: 'RX_GET_COMPANY_INFO',
    RX_GET_COMPANY_INFO_PENDING: 'RX_GET_COMPANY_INFO_PENDING',
    RX_GET_COMPANY_INFO_DONE: 'RX_GET_COMPANY_INFO_DONE',

    RX_GET_ALL_PLANS: 'RX_GET_ALL_PLANS',
    RX_GET_ALL_PLANS_PENDING: 'RX_GET_ALL_PLANS_PENDING',
    RX_GET_ALL_PLANS_DONE: 'RX_GET_ALL_PLANS_DONE',

    RX_GET_COMPANY_DATA: 'RX_GET_COMPANY_DATA',
    RX_GET_COMPANY_DATA_PENDING: 'RX_GET_COMPANY_DATA_PENDING',
    RX_GET_COMPANY_DATA_DONE: 'RX_GET_COMPANY_DATA_DONE',
};
