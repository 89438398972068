import React from "react";

function TableTitle() {
    return (
        <div className="articles">
            Articles in Database
        </div>
    );
}

export default TableTitle;