import { useDispatch, useSelector } from "react-redux";
import {
    rxSaveChartSettings, rxSaveDashboardSettings,
    rxSaveHeatmapSettings,
    rxSavePublisherHeatmapSettings
} from "../../state-management/actions/savedSettingsActions";

function UseSavedSettingsController() {
    const dispatch = useDispatch();
    const dashboardSettings = useSelector(state => state.savedSettings.dashboardSettings);
    const heatmapSettings = useSelector(state => state.savedSettings.heatmapSettings);
    const chartSettings = useSelector(state => state.savedSettings.chartSettings);
    const publisherHeatmapSettings = useSelector(state => state.savedSettings.publisherHeatmapSettings)

    function saveDashboardSettings(project, settings) {
        const newSettings = {...dashboardSettings, [project]: settings};
        dispatch(rxSaveDashboardSettings(newSettings));
    }

    function saveHeatMapSettings(project, settings) {
        const newSettings = {...heatmapSettings, [project]: settings};
        dispatch(rxSaveHeatmapSettings(newSettings));
    }

    function saveChartSettings(project, settings) {
        const newSettings = {...chartSettings, [project]: settings};
        dispatch(rxSaveChartSettings(newSettings));
    }

    function savePublisherHeatmapSettings(project, settings) {
        const newSettings = {...publisherHeatmapSettings, [project]: settings};
        dispatch(rxSavePublisherHeatmapSettings(newSettings));
    }

    return {
        saveDashboardSettings,
        saveHeatMapSettings,
        saveChartSettings,
        savePublisherHeatmapSettings,
    }
}

export default UseSavedSettingsController;