import {API_TYPES} from "../actionTypes/apiActionTypes";

export const rxGetArticleEntitiesPending = payload => ({
    type: API_TYPES.RX_GET_ARTICLE_ENTITIES_PENDING,
    payload,
});

export const rxGetArticleEntitiesDone = payload => ({
    type: API_TYPES.RX_GET_ARTICLE_ENTITIES_DONE,
    payload,
});

export const rxGetArticleSemanticsPending = payload => ({
    type: API_TYPES.RX_GET_ARTICLE_SEMANTICS_PENDING,
    payload,
});

export const rxGetArticleSemanticsDone = payload => ({
    type: API_TYPES.RX_GET_ARTICLE_SEMANTICS_DONE,
    payload,
});