export const PUBLISHER_TYPES = {
    RX_GET_PUBLISHERS_FIRST_COLUMN: 'RX_GET_PUBLISHERS_FIRST_COLUMN',
    RX_GET_PUBLISHERS_FIRST_COLUMN_PENDING: 'RX_GET_PUBLISHERS_FIRST_COLUMN_PENDING',
    RX_GET_PUBLISHERS_FIRST_COLUMN_DONE: 'RX_GET_PUBLISHERS_FIRST_COLUMN_DONE',

    RX_GET_PUBLISHERS_SECOND_COLUMN: 'RX_GET_PUBLISHERS_SECOND_COLUMN',
    RX_GET_PUBLISHERS_SECOND_COLUMN_PENDING: 'RX_GET_PUBLISHERS_SECOND_COLUMN_PENDING',
    RX_GET_PUBLISHERS_SECOND_COLUMN_DONE: 'RX_GET_PUBLISHERS_SECOND_COLUMN_DONE',

    RX_GET_PUBLISHERS_THIRD_COLUMN: 'RX_GET_PUBLISHERS_THIRD_COLUMN',
    RX_GET_PUBLISHERS_THIRD_COLUMN_PENDING: 'RX_GET_PUBLISHERS_THIRD_COLUMN_PENDING',
    RX_GET_PUBLISHERS_THIRD_COLUMN_DONE: 'RX_GET_PUBLISHERS_THIRD_COLUMN_DONE',

    RX_GET_PUBLISHERS_FOURTH_COLUMN: 'RX_GET_PUBLISHERS_FOURTH_COLUMN',
    RX_GET_PUBLISHERS_FOURTH_COLUMN_PENDING: 'RX_GET_PUBLISHERS_FOURTH_COLUMN_PENDING',
    RX_GET_PUBLISHERS_FOURTH_COLUMN_DONE: 'RX_GET_PUBLISHERS_FOURTH_COLUMN_DONE',

    RX_GET_PUBLISHER_FILTER_SECOND_COL_COMPANIES: 'RX_GET_PUBLISHER_FILTER_SECOND_COL_COMPANIES',
    RX_GET_PUBLISHER_FILTER_SECOND_COL_COMPANIES_PENDING: 'RX_GET_PUBLISHER_FILTER_SECOND_COL_COMPANIES_PENDING',
    RX_GET_PUBLISHER_FILTER_SECOND_COL_COMPANIES_DONE: 'RX_GET_PUBLISHER_FILTER_SECOND_COL_COMPANIES_DONE',

    RX_GET_PUBLISHER_FILTER_THIRD_COL_COMPANIES: 'RX_GET_PUBLISHER_FILTER_THIRD_COL_COMPANIES',
    RX_GET_PUBLISHER_FILTER_THIRD_COL_COMPANIES_PENDING: 'RX_GET_PUBLISHER_FILTER_THIRD_COL_COMPANIES_PENDING',
    RX_GET_PUBLISHER_FILTER_THIRD_COL_COMPANIES_DONE: 'RX_GET_PUBLISHER_FILTER_THIRD_COL_COMPANIES_DONE',

    RX_GET_PUBLISHER_FILTER_FOURTH_COL_COMPANIES: 'RX_GET_PUBLISHER_FILTER_FOURTH_COL_COMPANIES',
    RX_GET_PUBLISHER_FILTER_FOURTH_COL_COMPANIES_PENDING: 'RX_GET_PUBLISHER_FILTER_FOURTH_COL_COMPANIES_PENDING',
    RX_GET_PUBLISHER_FILTER_FOURTH_COL_COMPANIES_DONE: 'RX_GET_PUBLISHER_FILTER_FOURTH_COL_COMPANIES_DONE',

    RX_GET_PUBLISHER_FILTER_FIRST_COL_COVERAGE: 'RX_GET_PUBLISHER_FILTER_FIRST_COL_COVERAGE',
    RX_GET_PUBLISHER_FILTER_FIRST_COL_COVERAGE_PENDING: 'RX_GET_PUBLISHER_FILTER_FIRST_COL_COVERAGE_PENDING',
    RX_GET_PUBLISHER_FILTER_FIRST_COL_COVERAGE_DONE: 'RX_GET_PUBLISHER_FILTER_FIRST_COL_COVERAGE_DONE',

    RX_GET_PUBLISHER_FILTER_SECOND_COL_COVERAGE: 'RX_GET_PUBLISHER_FILTER_SECOND_COL_COVERAGE',
    RX_GET_PUBLISHER_FILTER_SECOND_COL_COVERAGE_PENDING: 'RX_GET_PUBLISHER_FILTER_SECOND_COL_COVERAGE_PENDING',
    RX_GET_PUBLISHER_FILTER_SECOND_COL_COVERAGE_DONE: 'RX_GET_PUBLISHER_FILTER_SECOND_COL_COVERAGE_DONE',

    RX_GET_PUBLISHER_FILTER_THIRD_COL_COVERAGE: 'RX_GET_PUBLISHER_FILTER_THIRD_COL_COVERAGE',
    RX_GET_PUBLISHER_FILTER_THIRD_COL_COVERAGE_PENDING: 'RX_GET_PUBLISHER_FILTER_THIRD_COL_COVERAGE_PENDING',
    RX_GET_PUBLISHER_FILTER_THIRD_COL_COVERAGE_DONE: 'RX_GET_PUBLISHER_FILTER_THIRD_COL_COVERAGE_DONE',

    RX_GET_PUBLISHER_FILTER_FOURTH_COL_COVERAGE: 'RX_GET_PUBLISHER_FILTER_FOURTH_COL_COVERAGE',
    RX_GET_PUBLISHER_FILTER_FOURTH_COL_COVERAGE_PENDING: 'RX_GET_PUBLISHER_FILTER_FOURTH_COL_COVERAGE_PENDING',
    RX_GET_PUBLISHER_FILTER_FOURTH_COL_COVERAGE_DONE: 'RX_GET_PUBLISHER_FILTER_FOURTH_COL_COVERAGE_DONE',
};