import React from 'react';
import SearchBar from "./searchBar";
import { useSelector } from "react-redux";
import newsPaper from'../../../images/newspaper.svg';
import DashboardSearchSection from "./dashboardSearchSection";
import { TABLE_TABS } from "../../../core/constants/tableTabs";
import ChartSearchSection from "./chartSearchSeaction";
import PublisherSearchSection from "./publisherSearchSection";
import NetworkSearchSection from "./networkSearchSection";
import HeatmapSearchSection from "./heatmapSearchSection";

function SearchSection({
                           openRequestForm,
                           activeTab,
                           setHeatmapLoading,
                           setDashboardLoading,
                           setPublisherHeatmapLoading,
                           networkSelectedValue,
                           setNetworkSelectedValue
}) {
    const selectedSubject = useSelector(state => state.userInfo.data.projects[0] || '');

    return (
        <div className='search-section'>
            <SearchBar />
            {selectedSubject ?
                <>
                    <DashboardSearchSection
                        openRequestForm={openRequestForm}
                        open={activeTab === TABLE_TABS.DASHBOARD}
                        setHeatmapLoading={setDashboardLoading}
                    />
                    <HeatmapSearchSection
                        openRequestForm={openRequestForm}
                        open={activeTab === TABLE_TABS.HEATMAP}
                        setHeatmapLoading={setHeatmapLoading}
                    />
                    <ChartSearchSection open={activeTab === TABLE_TABS.TOPIC_TRENDS} />
                    <PublisherSearchSection
                        open = {activeTab === TABLE_TABS.PUBLISHER}
                        openRequestForm={openRequestForm}
                        setHeatmapLoading={setPublisherHeatmapLoading}
                    />
                    <NetworkSearchSection
                        open={activeTab === TABLE_TABS.NETWORK}
                        openRequestForm={openRequestForm}
                        networkSelectedValue={networkSelectedValue}
                        setNetworkSelectedValue={setNetworkSelectedValue}
                    />
                </>:
                <div  className="project-request-section">
                    <div>
                        You haven't started any project yet
                    </div>
                    <div className="request-button" onClick={()=>openRequestForm()}>
                        <img alt="" src={newsPaper} />
                        <span>Project request form</span>
                    </div>
                </div>}
        </div>
    )
}

export default SearchSection;