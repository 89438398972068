import React from "react";

function CancelAccountFirstPage({ onClose, handleCancel }) {
    return (
        <>
            <div className="plan-update-content">
                <div className="cancel-title">
                    Sie wollen pausieren?!
                </div>
                <div className="cancel-text">
                    Wenn Sie sicher sind, bestätigen Sie einfach noch einmal mit Klick auf den<br />
                    Button unten.
                </div>
                <div className="cancel-text">
                    <div className="cancel-dot" />
                    <span>Mit Beginn der nächsten Abrechnungsperiode wird Ihr Account pausiert.</span>
                </div>
                <div className="cancel-text">
                    <div className="cancel-dot" />
                    <span><span>Jederzeit Willkommen!</span> Kommen Sie jederzeit wieder zurück, wenn es für <br />
                    Sie wieder passt oder ein anderes Projekt für Sie interessant wird. Ihr<br />
                    Account und wir warten auf Sie und heissen Sie gerne wieder herzlich<br />
                    willkommen!</span>
                </div>
            </div>
            <div className="plan-update-popup-buttons">
                <div className="plan-update-popup-button" style={{width: 191}} onClick={handleCancel}>
                    Account pausieren
                </div>
                <div className="plan-update-popup-button" onClick={onClose}>
                    Zurück
                </div>
            </div>
        </>
    )
}

export default CancelAccountFirstPage;