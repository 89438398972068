import React from 'react';
import PLAN_UPDATE_MODAL_CONSTANTS from "../../../core/constants/planUpdateModalConstants";

function ToProWarning({ setModalState, onClose }) {

    const handleConfirm = () => {
        setModalState(PLAN_UPDATE_MODAL_CONSTANTS.LOST_PROJECTS)
    }
  return (
    <>
      <div className="downgrade-title">
        Wenn Sie Ihr Downgrade
      </div>
      <div className="downgrade-text warning">
        durchführen werden Ihre eigenen Projekte gelöscht und das Wissen steht <br />
        nicht mehr zur Verfügung. Mit dem PRO Account können Sie aber eines aus <br />
        vorhandenen Projekten wählen. Wollen Sie wirklich auf Ihr eigenes Wissen <br />
        verzichten?
      </div>
      <div className="downgrade-text">
        Sind Sie sicher?
      </div>
      <div className="plan-update-popup-buttons">
        <div className="plan-update-popup-button" onClick={handleConfirm}>
          Ja
        </div>
        <div className="plan-update-popup-button" onClick={onClose}>
          Abbrechen
        </div>
      </div>
    </>
  );
}

export default ToProWarning;
