import TypeChecker from "./typeChecker";

export function createQuery(queryFields) {
    // when queryFields is undefined
    if (!queryFields) {
        return ''
    }

    // when queryFields is string or number
    if (typeof queryFields === 'string' || typeof queryFields === 'number') {
        return `${queryFields}`;
    }

    // when queryFields is array
    if (Array.isArray(queryFields)) {
        let query = '?';
        queryFields.forEach((queryField, index) => {
            if (index) {
                query += `&${queryField.key}=${queryField.value}`
            } else {
                query += `${queryField.key}=${queryField.value}`
            }
        });
        return query;
    }

    //when queryFields is object
    if (Object.keys(queryFields).length) {
        let searchQuery = '';
        for(let key in queryFields){
            if (Array.isArray(queryFields[key])) {
                queryFields[key].forEach(value => {
                    searchQuery = `${searchQuery}&${key}=${value}`
                })
            } else if(!TypeChecker.isNull(queryFields[key]) && !TypeChecker.isUndefined(queryFields[key]) && !TypeChecker.isEmptyString(queryFields[key])){
                searchQuery = `${searchQuery}&${key}=${queryFields[key]}`
            }
        }
        return `?${searchQuery.substring(1)}`;
    }
    return ;
}
