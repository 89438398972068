import React from 'react';
import roundIcon from '../../../images/roundIcon.png';
import blackConfirmIcon from '../../../images/blackConfirmIcon.png';
import DowngradeConfirmIcon from './downgradeConfirmIcon';

function DownToPlusConfirm({ onClose }) {
  return (
    <div style={{marginTop: 30}}>
      <DowngradeConfirmIcon />
      <div className="downgrade-confirm-text">
        Ihr Downgrade wurde wie gewünscht durchgeführt. <br />
        Es wird mit der nächsten Abrechnungsperiode gültig.
      </div>
      <div className="plan-update-popup-buttons">
        <div className="plan-update-popup-button" onClick={onClose}>
          in Ordnung
        </div>
      </div>

    </div>
  );
}

export default DownToPlusConfirm;
