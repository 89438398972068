import {combineReducers} from "redux";
import createAsyncReducer from "../createAsyncReducer";
import {GRAPH_TYPES} from "../actionTypes/graphActionTypes";

export const graphReducer = combineReducers({
    graphFilters: createAsyncReducer(GRAPH_TYPES.RX_GET_FILTERS, {
        entity_types: [],
        article_layers: [],
    }),

    network: createAsyncReducer(GRAPH_TYPES.RX_GET_NETWORK, {
        entities: [],
        relationships: [],
    }),

    entityDetails: createAsyncReducer(GRAPH_TYPES.RX_GET_ENTITY_DETAILS, null),
});

//     {
//     n_of_articles: 0,
//     context: [],
//     article_layers: [],
//     context_layers: [],
//     neighbors_1st_level: []
// }