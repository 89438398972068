import React from "react";
import OutcomeBar from "./outcomeBar";
import Heatmap from "./heatmap";
import {useSelector} from "react-redux";
import { TABLE_TABS } from "../../../core/constants/tableTabs";
import Charts from "./charts";
import PublisherHeatmap from "./publisherHeatmap";
import NetworkGraph from "../networkGraph";
import Dashboard from "./dashboard";


function TableSection({
                          activeTab,
                          setActiveTab,
                          dashboardLoading,
                          setDashboardLoading,
                          heatmapLoading,
                          setHeatmapLoading,
                          publisherHeatmapLoading,
                          setPublisherHeatmapLoading,
                          networkSelectedValue
}) {

    const selectedSubject = useSelector(state => state.userInfo.data.projects[0] || '');

    return (
        <div className="full-table">
            {selectedSubject &&
            <div className="tab-section">
                <div className={`tab ${activeTab===TABLE_TABS.DASHBOARD ? 'active': ''}`}
                     onClick={() => setActiveTab(TABLE_TABS.DASHBOARD)}>
                    dashboard
                </div>
                <div className={`tab ${activeTab===TABLE_TABS.HEATMAP ? 'active': ''}`}
                     onClick={() => setActiveTab(TABLE_TABS.HEATMAP)}>
                    heatmap
                </div>
                <div className={`tab ${activeTab===TABLE_TABS.TOPIC_TRENDS ? 'active': ''}`}
                     onClick={() => setActiveTab(TABLE_TABS.TOPIC_TRENDS)}>
                    topic trends
                </div>
                <div className={`tab ${activeTab===TABLE_TABS.PUBLISHER ? 'active': ''}`}
                     onClick={() => setActiveTab(TABLE_TABS.PUBLISHER)}>
                    publisher
                </div>
                <div className={`tab ${activeTab===TABLE_TABS.NETWORK ? 'active': ''}`}
                     onClick={() => setActiveTab(TABLE_TABS.NETWORK)}>
                    network
                </div>

            </div>}
            <div className="table-section">
                <OutcomeBar />
                {
                    activeTab === TABLE_TABS.DASHBOARD &&
                    <Dashboard
                        heatmapLoading={dashboardLoading}
                        setHeatmapLoading={setDashboardLoading}
                    />
                }
                {
                    activeTab === TABLE_TABS.HEATMAP &&
                        <Heatmap
                            heatmapLoading={heatmapLoading}
                            setHeatmapLoading={setHeatmapLoading}
                        />
                }
                {
                    activeTab === TABLE_TABS.TOPIC_TRENDS &&
                    <Charts />
                }
                {
                    activeTab === TABLE_TABS.PUBLISHER &&
                    <PublisherHeatmap
                        heatmapLoading={publisherHeatmapLoading}
                        setHeatmapLoading={setPublisherHeatmapLoading}
                    />
                }
                {
                    activeTab === TABLE_TABS.NETWORK &&
                        <NetworkGraph networkSelectedValue={networkSelectedValue} />
                }
            </div>
            {!selectedSubject && <div className="popup-wrapper table-wrapper"/>}
        </div>
    )
}

export default TableSection;