import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import DateSection from "./dateSection";
import DateInput from "./dateInput";
import SubjectSection from "./subjectSection";
import minusIcon from "../../../images/minusIcon.svg";
import newsPaper from "../../../images/newspaper.svg";

const now = new Date();
const oneYearBefore = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

const endDateIsLess = (startDateStr, endDateStr) => {
    const startDate = new Date(moment(startDateStr).format());
    const endDate = new Date(moment(endDateStr).format());
    return startDate > endDate;
};

function NetworkSearchSection({ open, openRequestForm, networkSelectedValue, setNetworkSelectedValue }) {

    const changeSubjectSelectedValue = value => {
        setNetworkSelectedValue(value);
    }

    useEffect(() => {
        const els = document.querySelectorAll('.search-first-row input');
        for (let i=0; i < els.length; i++) {
            els[i].setAttribute("readonly", "true");
        }
    }, []);


    return (
        <div className={open ? "": "closed"}>
            <div className="network-search-first-row">
                <SubjectSection
                    subjectSelectedValue={networkSelectedValue}
                    setSubjectSelectedValue={changeSubjectSelectedValue}
                    icon={minusIcon}
                    showExtendedFilters={false}
                />
                <div className="request-button" onClick={()=>openRequestForm()}>
                    <img src={newsPaper} alt="" />
                    <span>Project request form</span>
                </div>
            </div>
        </div>
    )
}

export default NetworkSearchSection;