import React, {useEffect, useState} from "react";

const borders = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

function LoaderSquare({ percent, border }) {
    const isFilled = border <= percent;
    return (
        <div className={`loader-square ${isFilled? 'filled': ''}`} />
    );
}

function GraphLoader({ percent, controlled = true, text = 'Loading the data...' }) {

    const [ loaderPercent, setLoaderPercent ] = useState(0);

    useEffect(() => {
        let intervalId;
        if (!controlled) {
            intervalId = setInterval(() => {
                setLoaderPercent(prev => (prev + 10) % 100)
            }, 100)
        }
        return(() => {
            if(intervalId) {
                clearInterval(intervalId);
            }
        })

    }, []);

    return (
        <div className={controlled? 'loader-container' : ''}>
            <div className="loader-text">{text}</div>
            <div className="loader-squares">
                {
                    borders.map(border => {
                        return (
                            <LoaderSquare border={border} percent={controlled? percent: loaderPercent} key={border} />
                        );
                    })
                }
            </div>
        </div>
    );
}

export default GraphLoader;