import React from "react";

function OutcomeBar() {
    return (
        <div className="outcome-bar">
            <div className="outcome-bar-text">outcome</div>
        </div>
    );
}

export default OutcomeBar;