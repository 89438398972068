import React from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function DateInput({ name, label, value, handleChange, min, max, disabled = false }) {
    return (
        <div className="input-block" >
            <label htmlFor={name} className="label">{label}</label><br />
            <DatePicker selected={value}
                        minDate={min}
                        maxDate={max}
                        onChange={handleChange}
                        disabled={disabled}
                        />
            {/*<input type="date"*/}
            {/*       id={name}*/}
            {/*       name={name}*/}
            {/*       className="date-input"*/}
            {/*       required*/}
            {/*       value={value}*/}
            {/*       onChange={handleChange}*/}
            {/*       min={min}*/}
            {/*       max={max}*/}
            {/*/>*/}
        </div>
    );
}

export default DateInput;