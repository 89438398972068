import Operations from "./operations";
import semanticsApiHandler from "../apiHandlers/semanticsApiHandler";
import { PROJECT_ATTRIBUTES } from "../../core/constants/projectAttributes";

const getAttributeList = attributeObj => Object.keys(attributeObj || {}).map(item => ({
    name: item,
    count: attributeObj[item]
}));

class SemanticsOperations extends Operations {
    constructor(semanticsApiHandler) {
        super();
        this.semanticsApiHandler = semanticsApiHandler;
    }

    async getFirstColumnV5 (queryData) {
        const { startDate, endDate } = queryData;
        const response = await this.semanticsApiHandler.getFirstColumnV5(queryData);
        if(response && response.layers){
            const layers = {
                [PROJECT_ATTRIBUTES.SCORECARD]: getAttributeList(response.attributeScorecard),
                [PROJECT_ATTRIBUTES.INDUSTRY]: getAttributeList(response.attributeIndustry),
                [PROJECT_ATTRIBUTES.RISKS]: getAttributeList(response.attributeRisks),
                [PROJECT_ATTRIBUTES.SECURITY]: getAttributeList(response.attributeSecurity),
                [PROJECT_ATTRIBUTES.GENERAL]: getAttributeList(response.attributeGeneral),
            }

            const coverages = Object.keys(response.coverages || {}).map (item => ({
                name: item,
                number: response.coverages[item],
            }));
            const companies = Object.keys(response.companies || {}).map (item => ({
                name: item,
                number: response.companies[item],
            }));
            const publishers = Object.keys(response.publishers || {}).map (item => ({
                name: item.replaceAll('#', '.'),
                number: response.publishers[item],
            }));
            const peoples = Object.keys(response.people || {}).map (item => ({
                name: item,
                number: response.people[item],
            }));
            return {
                ...response,
                layers,
                startDate,
                endDate,
                coverages,
                companies,
                publishers,
                peoples,
                people: ''
            }
        }
    }

    async getSecondColumnV5 (queryData) {
        const response =  await this.semanticsApiHandler.getSecondColumnV5(queryData);
        if (response) {
            const { coverage, company, publisher, people } = queryData;

            const coverages = Object.keys(response.coverages || {}).map (item => ({
                name: item,
                number: response.coverages[item],
            }));
            const companies = Object.keys(response.companies || {}).map (item => ({
                name: item,
                number: response.companies[item],
            }));
            const publishers = Object.keys(response.publishers || {}).map (item => ({
                name: item.replaceAll('#', '.'),
                number: response.publishers[item],
            }));
            const peoples = Object.keys(response.people || {}).map (item => ({
                name: item,
                number: response.people[item],
            }));
            const layers = {
                ...response.attributeScorecard,
                ...response.attributeIndustry,
                ...response.attributeRisks,
                ...response.attributeSecurity,
                ...response.attributeGeneral,
            }
            return {
                ...response,
                layers,
                coverage,
                company,
                publisher,
                people,
                coverages,
                companies,
                publishers,
                peoples,
            };
        }
    }

    async getThirdColumnV5 (queryData) {
        const response =  await this.semanticsApiHandler.getThirdColumnV5(queryData);
        if (response) {
            const { coverage, company, publisher, people } = queryData;

            const coverages = Object.keys(response.coverages || {}).map (item => ({
                name: item,
                number: response.coverages[item],
            }));
            const companies = Object.keys(response.companies || {}).map (item => ({
                name: item,
                number: response.companies[item],
            }));
            const publishers = Object.keys(response.publishers || {}).map (item => ({
                name: item.replaceAll('#', '.'),
                number: response.publishers[item],
            }));
            const peoples = Object.keys(response.people || {}).map (item => ({
                name: item,
                number: response.people[item],
            }));
            const layers = {
                ...response.attributeScorecard,
                ...response.attributeIndustry,
                ...response.attributeRisks,
                ...response.attributeSecurity,
                ...response.attributeGeneral,
            }
            return {
                ...response,
                layers,
                coverage,
                company,
                publisher,
                people,
                coverages,
                companies,
                publishers,
                peoples,
            };
        }
    }

    async getFourthColumnV5 (queryData) {
        const response =  await this.semanticsApiHandler.getFourthColumnV5(queryData);
        if (response) {
            const { coverage, company, publisher, people } = queryData;

            const coverages = Object.keys(response.coverages || {}).map (item => ({
                name: item,
                number: response.coverages[item],
            }));
            const companies = Object.keys(response.companies || {}).map (item => ({
                name: item,
                number: response.companies[item],
            }));
            const publishers = Object.keys(response.publishers || {}).map (item => ({
                name: item.replaceAll('#', '.'),
                number: response.publishers[item],
            }));
            const peoples = Object.keys(response.people || {}).map (item => ({
                name: item,
                number: response.people[item],
            }));
            const layers = {
                ...response.attributeScorecard,
                ...response.attributeIndustry,
                ...response.attributeRisks,
                ...response.attributeSecurity,
                ...response.attributeGeneral,
            }
            return {
                ...response,
                layers,
                coverage,
                company,
                publisher,
                people,
                coverages,
                companies,
                publishers,
                peoples,
            };
        }
    }

    async getFirstColumnV3 (queryData) {
        const { startDate, endDate } = queryData;
        const response = await this.semanticsApiHandler.getFirstColumnV3(queryData);
        if(response && response.layers){
            const layers = Object.keys(response.layers || {}).map (item => ({
                name: item,
                count: response.layers[item]
            }));
            const sortedLayers = [...layers.sort((a, b) => b.count - a.count)];
            const coverages = Object.keys(response.coverages || {}).map (item => ({
                name: item,
                number: response.coverages[item],
            }));
            const companies = Object.keys(response.companies || {}).map (item => ({
                name: item,
                number: response.companies[item],
            }));
            const publishers = Object.keys(response.publishers || {}).map (item => ({
                name: item.replaceAll('#', '.'),
                number: response.publishers[item],
            }));
            const peoples = Object.keys(response.people || {}).map (item => ({
                name: item,
                number: response.people[item],
            }));
            return {
                ...response,
                layers: sortedLayers,
                startDate,
                endDate,
                coverages,
                companies,
                publishers,
                peoples,
                people: ''
            }
        }
    }

    async getSecondColumnV3 (queryData) {
        const response =  await this.semanticsApiHandler.getSecondColumnV3(queryData);
        if (response) {
            const { coverage, company, publisher, people } = queryData;
            const coverages = Object.keys(response.coverages  || {}).map (item => ({
                name: item,
                number: response.coverages[item],
            }));
            const companies = Object.keys(response.companies || {}).map (item => ({
                name: item,
                number: response.companies[item],
            }));
            const publishers = Object.keys(response.publishers || {}).map (item => ({
                name: item.replaceAll('#', '.'),
                number: response.publishers[item],
            }));
            const peoples = Object.keys(response.people || {}).map (item => ({
                name: item,
                number: response.people[item],
            }));
            return {
                ...response,
                coverage,
                company,
                publisher,
                people,
                coverages,
                companies,
                publishers,
                peoples,
            };
        }
    }

    async getThirdColumnV3 (queryData) {
        const response =  await this.semanticsApiHandler.getThirdColumnV3(queryData);
        if (response) {
            const { coverage, company, publisher, people } = queryData;
            const coverages = Object.keys(response.coverages || {}).map (item => ({
                name: item,
                number: response.coverages[item],
            }));
            const companies = Object.keys(response.companies || {}).map (item => ({
                name: item,
                number: response.companies[item],
            }));
            const publishers = Object.keys(response.publishers ||{}).map (item => ({
                name: item.replaceAll('#', '.'),
                number: response.publishers[item],
            }));
            const peoples = Object.keys(response.people || {}).map (item => ({
                name: item,
                number: response.people[item],
            }));
            return {
                ...response,
                coverage,
                company,
                publisher,
                people,
                coverages,
                companies,
                publishers,
                peoples,
            };
        }
    }

    async getFourthColumnV3 (queryData) {
        const response =  await this.semanticsApiHandler.getFourthColumnV3(queryData);
        if (response) {
            const { coverage, company, publisher, people } = queryData;
            const coverages = Object.keys(response.coverages || {}).map (item => ({
                name: item,
                number: response.coverages[item],
            }));
            const companies = Object.keys(response.companies || {}).map (item => ({
                name: item,
                number: response.companies[item],
            }));
            const publishers = Object.keys(response.publishers || {}).map (item => ({
                name: item.replaceAll('#', '.'),
                number: response.publishers[item],
            }));
            const peoples = Object.keys(response.people || {}).map (item => ({
                name: item,
                number: response.people[item],
            }));
            return {
                ...response,
                coverage,
                company,
                publisher,
                people,
                coverages,
                companies,
                publishers,
                peoples,
            };
        }
    }

    async getChartCompaniesAndLayers(queryData) {
        const response = await this.semanticsApiHandler.getChartCompaniesAndLayers(queryData);
        if (response && response.companies && response.layers) {
            return {
                companies: Object.keys(response.companies).map (item => ({
                    name: item,
                    number: response.companies[item],
                })),
                layers: Object.keys(response.layers).map (item => ({
                    name: item,
                    number: response.layers[item],
                }))
            }
        } else {
            return {
                companies: [],
                layers: [],
            };
        }
    }

    async getChartData(queryData, abortSignal) {
        return await this.semanticsApiHandler.getChartData(queryData, abortSignal);
    }
}

const semanticsOperations = new SemanticsOperations(semanticsApiHandler);

export default semanticsOperations;
