import React, {useEffect, useRef, useState} from 'react';
import arrowUp from '../../../images/arrowUp.png';
import arrowDown from '../../../images/arrowDown.png';
import PLAN_UPDATE_MODAL_CONSTANTS from "../../../core/constants/planUpdateModalConstants";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";
import {useSelector} from "react-redux";

const PROJECTS = [
  'ProjectName 1',
  'ProjectName 6',
  'ProjectName 2',
  'ProjectName 7',
  'ProjectName 3',
  'ProjectName 8',
  'ProjectName 4',
  'ProjectName 9',
  'ProjectName 5',
  'ProjectName 10',
];

function ProjectSelect({ setModalState, projects, selectedProject, setSelectedProject, selectedPlanName }) {

  const { company } = useSelector(state => state.userInfo.data);
  const [ openDropdown, setOpenDropdown ] = useState(false);
  // const [ value, setValue ] = useState(PROJECTS[0]);
  const ref = useRef(null);

  const { updatePlan } = UseLoginController();
  useEffect(() => {
    if(!selectedProject){
      setSelectedProject(projects[0] || '');
    }
  }, []);

  const handleClickOutside = e => {
    if(ref.current && !ref.current.contains(e.target)){
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClick = plan => {
    if(plan !== setSelectedProject) {
      setSelectedProject(plan);
    }

    setOpenDropdown(false);
  };

  const handleConfirm = () => {
    updatePlan({company, plan: selectedPlanName, projects: selectedProject})
        .then(res => {
          // if(res instanceof Error) return;
          setModalState(PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_TO_PRO_CONFIRM);
        })
  }

  const handleAbort = () => {
    setModalState(PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_TO_PRO_ABORT);
  }

  return(
    <>
      <div className="downgrade-title">
        Sie können nun Ihr Projekt auswählen:
      </div>

      <div className={`project-dropdown plan-update-dropdown ${openDropdown? 'open': ''}`} ref={ref}>
        <div className="project-dropdown-input update-dropdown-input" onClick={()=>setOpenDropdown(prev => !prev)}>
          <div>{selectedProject}</div>
          <img src={openDropdown ? arrowUp: arrowDown} />
        </div>
        <div className="project-dropdown-content" style={{maxHeight: 160}}>
          {
            projects.map(project => {
              return (
                <div className={`project-dropdown-item ${project === selectedProject ? 'active': ''}`}
                     key={project}
                     onClick={() => handleClick(project)}
                >{project}</div>
              )

            })
          }
        </div>
      </div>
      <div className="plan-update-popup-buttons">
        <div className="plan-update-popup-button" onClick={handleAbort}>
          Abbrechen
        </div>
        <div className="plan-update-popup-button" onClick={handleConfirm}>
          Bestätigen
        </div>
      </div>
    </>
  );
}

export default ProjectSelect;
