import {combineReducers} from "redux";
import createAsyncReducer from "../createAsyncReducer";
import {API_TYPES} from "../actionTypes/apiActionTypes";

export const apiReducer = combineReducers({
    articleSemantics: createAsyncReducer(API_TYPES.RX_GET_ARTICLE_SEMANTICS, []),
    articleEntities: createAsyncReducer(API_TYPES.RX_GET_ARTICLE_ENTITIES, {
        entities: [],
        length: 0
    }),
});