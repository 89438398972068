import React, {useState} from "react";
import sortSolid from "../../../images/sort-solid.png";
import searchSolid from "../../../images/search-solid.png";
import FilterTableRow from "./filterTableRow";

const SORTED_STATES = {
    NUMBER_ASCENDING: 'NUMBER_ASCENDING',
    NUMBER_DESCENDING: 'NUMBER_DESCENDING',
    NAME_ASCENDING: 'NAME_ASCENDING',
    NAME_DESCENDING: 'NAME_DESCENDING',
}

function getSortedOptions(options, sortedState){
    let copy;
    switch (sortedState) {
        case SORTED_STATES.NUMBER_DESCENDING:
            copy = [...options];
            copy.sort((a, b) => b.number - a.number);
            return copy;
        case SORTED_STATES.NUMBER_ASCENDING:
            copy = [...options];
            copy.sort((a, b) => a.number - b.number);
            return copy;
        case SORTED_STATES.NAME_ASCENDING:
            copy =  [...options];
            copy.sort((a, b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase()){
                    return -1;
                }
                return 1;
            });
            return copy;
        case SORTED_STATES.NAME_DESCENDING:
            copy =  [...options];
            copy.sort((a, b) => {
                if(a.name.toLowerCase() > b.name.toLowerCase()){
                    return -1;
                }
                return 1;
            });
            return copy;
        default:
            return [...options];
    }
}

function FiltersTable ({
                           options,
                           filterValue,
                           setFilterValue,
                           selectedValue,
                           handleChange,
                           setShowSelect,
                           showAllOption,
                           showAllSelected,
                           isLoading }) {
    const [ sortedState, setSortedState ] = useState(SORTED_STATES.NUMBER_DESCENDING);

    const sortedOptions = getSortedOptions(options, sortedState);
    const filteredOptions = sortedOptions.filter(option => {
        return option.name.toLowerCase().includes(filterValue.trim().toLowerCase());
    });

    const sortByName = () => {
        if(sortedState === SORTED_STATES.NAME_ASCENDING) {
            setSortedState(SORTED_STATES.NAME_DESCENDING);
        } else {
            setSortedState(SORTED_STATES.NAME_ASCENDING);
        }
    }

    const sortByCount = () => {
        if(sortedState === SORTED_STATES.NUMBER_DESCENDING) {
            setSortedState(SORTED_STATES.NUMBER_ASCENDING);
        } else {
            setSortedState(SORTED_STATES.NUMBER_DESCENDING);
        }
    }

    const handleRowClick = value => {
        if(selectedValue !== value) {
            handleChange(value);
        }
        setShowSelect(false);
    }

    return(
        <div className="filters">
            <div className="filter-input">
                <div className="filter-icon">
                    <img src={searchSolid} alt="" />
                </div>
                <input type="text"
                       placeholder="Filter"
                       value={filterValue}
                       onChange={(e)=>setFilterValue(e.target.value)}
                />
            </div>
            <div className="dropdown-table">
                <div className="filters-table-header">
                    <div className="table-name">
                        <div className="name-block" onClick={sortByName}>
                            <img src={sortSolid} alt="" />
                            <div>Name</div>
                        </div>

                    </div>
                    <div className="table-count">
                        <div className="count-block" onClick={sortByCount}>
                            <div>#</div>
                            <img src={sortSolid} alt="" />
                        </div>
                    </div>
                </div>
                {isLoading ?
                    <div className="circle-loader" /> :
                    <table className="filters-table">
                    <tbody>
                    {showAllOption &&
                    'all'.includes(filterValue.trim().toLowerCase()) &&
                    <FilterTableRow
                        key='All'
                        name='All'
                        count=''
                        handleClick={() => handleRowClick(showAllSelected ? 'All' : '')}
                        selectedValue={selectedValue}
                    />}
                    {filteredOptions.map(({name, number}) => {
                        return (
                            <FilterTableRow
                                key={name}
                                name={name}
                                count={number}
                                handleClick={() => handleRowClick(name)}
                                selectedValue={selectedValue}
                            />
                            )
                    })}
                    </tbody>
                </table>}
            </div>
        </div>
    );
}

FiltersTable.defaultProps = {
    options: [],
}

export default FiltersTable;