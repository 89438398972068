import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import TableTitle from "./tableTitle";
import TableFirstRow from "./tableFirstRow";
import TableLeftBlock from "./tableLeftBlock";
import HeatmapLoader from "./heatmapLoader";
import OutcomeTable from "./outcomeTable";
import ColorIndicatorAndDownloadSection from "./colorIndicatorAndDownloadSection";
import DownloadInfoPopup from "./downloadInfoPopup";
import InterestSection from "./InterestSection";
import UseProjectController from "../../../customHooks/controllerHooks/projectController";
import useDownload from "../../../customHooks/useDownload";

function PublisherHeatmap({ heatmapLoading, setHeatmapLoading }) {
    const firstColumnLoading = useSelector(state => state.publisherHeatmap.firstColumn.isLoading);
    const secondColumnLoading = useSelector(state => state.publisherHeatmap.secondColumn.isLoading);
    const thirdColumnLoading = useSelector(state => state.publisherHeatmap.thirdColumn.isLoading);
    const fourthColumnLoading = useSelector(state => state.publisherHeatmap.fourthColumn.isLoading);
    const loading = firstColumnLoading || secondColumnLoading || thirdColumnLoading || fourthColumnLoading;

    const [ open, setOpen ] = useState(false);

    const [ showHiddenLayers, setShowHiddenLayers ] = useState(false);
    const hiddenLayers = useSelector(state => state.hiddenPublishers.data.publishers);
    const project = useSelector(state => state.hiddenPublishers.data.name);
    const firstColumn = useSelector(state => state.publisherHeatmap.firstColumn.data.layers);
    const secondColumn = useSelector(state => state.publisherHeatmap.secondColumn.data.layers);
    const thirdColumn = useSelector(state => state.publisherHeatmap.thirdColumn.data.layers);
    const fourthColumn = useSelector(state => state.publisherHeatmap.fourthColumn.data.layers);
    const totalArticlesCount = useSelector(state => state.publisherHeatmap.firstColumn.data.totalArticlesCount);
    const firstColumnArticlesCount = useSelector(state => state.publisherHeatmap.firstColumn.data.filteredArticlesCount);
    const secondColumnArticlesCount = useSelector(state => state.publisherHeatmap.secondColumn.data.filteredArticlesCount);
    const thirdColumnArticlesCount = useSelector(state => state.publisherHeatmap.thirdColumn.data.filteredArticlesCount);
    const fourthColumnArticlesCount = useSelector(state => state.publisherHeatmap.fourthColumn.data.filteredArticlesCount);
    const { changeHiddenPublishers: changeHiddenLayers } = UseProjectController();
    const { savePublishersHeatmap: saveAsExcel } = useDownload();

    useEffect(() => {
        !loading && setHeatmapLoading(false);
    }, [loading])


    return(
        <>
            <TableTitle />
            <div className="outer-table">
                <TableFirstRow
                    totalArticlesCount={totalArticlesCount}
                    firstColumnCount={firstColumnArticlesCount}
                    secondColumnCount={secondColumnArticlesCount}
                    thirdColumnCount={thirdColumnArticlesCount}
                    fourthColumnCount={fourthColumnArticlesCount}
                />
                <div className="inner-table-wrapper">
                    <TableLeftBlock />
                    <div className="table-right-block">
                        {(heatmapLoading || firstColumnLoading) &&
                        <HeatmapLoader />}
                        <InterestSection />
                        <OutcomeTable
                            showHiddenLayers={showHiddenLayers}
                            setShowHiddenLayers={setShowHiddenLayers}
                            hiddenLayers={hiddenLayers}
                            project={project}
                            firstColumn={firstColumn}
                            secondColumn={secondColumn}
                            thirdColumn={thirdColumn}
                            fourthColumn={fourthColumn}
                            firstColumnArticlesCount={firstColumnArticlesCount}
                            secondColumnArticlesCount={secondColumnArticlesCount}
                            thirdColumnArticlesCount={thirdColumnArticlesCount}
                            fourthColumnArticlesCount={fourthColumnArticlesCount}
                            changeHiddenLayers={changeHiddenLayers}
                        />
                    </div>
                </div>
            </div>
            <ColorIndicatorAndDownloadSection setOpen={setOpen} saveAsExcel={saveAsExcel}/>
            {open && <DownloadInfoPopup setOpen={setOpen} subject="publisher"/>}
        </>
    );
}

export default PublisherHeatmap;