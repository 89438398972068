import React, { useEffect, useState } from "react";
import TableFirstRow from "./tableFirstRow";
import PercentSection from "./percentSection";
import OutcomeTable from "./outcomeTable";
import ColorIndicatorAndDownloadSection from "./colorIndicatorAndDownloadSection";
import {useSelector} from "react-redux";
import HeatmapLoader from "./heatmapLoader";
import DownloadInfoPopup from "./downloadInfoPopup";
import UseProjectController from "../../../customHooks/controllerHooks/projectController";
import useDownload from "../../../customHooks/useDownload";
import SemanticTable from "./SemanticTable";
import { PROJECT_ATTRIBUTES } from "../../../core/constants/projectAttributes";

const attributes = [
    PROJECT_ATTRIBUTES.SCORECARD,
    PROJECT_ATTRIBUTES.INDUSTRY,
    PROJECT_ATTRIBUTES.RISKS,
    PROJECT_ATTRIBUTES.SECURITY,
    PROJECT_ATTRIBUTES.GENERAL,
];

function Dashboard({ heatmapLoading, setHeatmapLoading }) {
    const firstColumnLoading = useSelector(state => state.semantics.semantics.firstColumn.isLoading);
    const secondColumnLoading = useSelector(state => state.semantics.semantics.secondColumn.isLoading);
    const thirdColumnLoading = useSelector(state => state.semantics.semantics.thirdColumn.isLoading);
    const fourthColumnLoading = useSelector(state => state.semantics.semantics.fourthColumn.isLoading);
    const loading = firstColumnLoading || secondColumnLoading || thirdColumnLoading || fourthColumnLoading;

    const [ infoPopupOpen, setInfoPopupOpen ] = useState(false);

    const [ showHiddenLayers, setShowHiddenLayers ] = useState(false);
    const hiddenLayers = useSelector(state => state.hiddenLayers.data.layers);
    const project = useSelector(state => state.hiddenLayers.data.name);
    const firstColumn = useSelector(state => state.semantics.semantics.firstColumn.data.layers);
    const secondColumn = useSelector(state => state.semantics.semantics.secondColumn.data.layers);
    const thirdColumn = useSelector(state => state.semantics.semantics.thirdColumn.data.layers);
    const fourthColumn = useSelector(state => state.semantics.semantics.fourthColumn.data.layers);
    const totalArticlesCount = useSelector(state => state.semantics.semantics.firstColumn.data.totalArticlesCount);
    const firstColumnArticlesCount = useSelector(state => state.semantics.semantics.firstColumn.data.filteredArticlesCount);
    const secondColumnArticlesCount = useSelector(state => state.semantics.semantics.secondColumn.data.filteredArticlesCount);
    const thirdColumnArticlesCount = useSelector(state => state.semantics.semantics.thirdColumn.data.filteredArticlesCount);
    const fourthColumnArticlesCount = useSelector(state => state.semantics.semantics.fourthColumn.data.filteredArticlesCount);

    const { changeHiddenLayers } = UseProjectController();
    const { saveDashboard: saveAsExcel } = useDownload();

    useEffect(() => {
        !loading && setHeatmapLoading(false);
    }, [loading]);



    return(
        <>
            <div className="articles" />
            <div className="outer-table">
                <TableFirstRow
                    totalArticlesCount={totalArticlesCount}
                    firstColumnCount={firstColumnArticlesCount}
                    secondColumnCount={secondColumnArticlesCount}
                    thirdColumnCount={thirdColumnArticlesCount}
                    fourthColumnCount={fourthColumnArticlesCount}
                />
                <div>
                    <div className="semantic-table-body">
                        {(heatmapLoading || firstColumnLoading) && <HeatmapLoader />}

                        <PercentSection
                            firstRowCount={firstColumnArticlesCount}
                            secondRowCount={secondColumnArticlesCount}
                            thirdRowCount={thirdColumnArticlesCount}
                            fourthRowCount={fourthColumnArticlesCount}
                        />

                        {
                            attributes.map(attribute => {
                                return (
                                    <SemanticTable title={attribute} defaultOpen={attribute !== PROJECT_ATTRIBUTES.GENERAL} key={attribute}>
                                        <OutcomeTable
                                            key={attribute}
                                            showEyeIcon={attribute === attributes[0]}
                                            showHiddenLayers={showHiddenLayers}
                                            setShowHiddenLayers={setShowHiddenLayers}
                                            hiddenLayers={hiddenLayers}
                                            project={project}
                                            firstColumn={firstColumn[attribute]}
                                            secondColumn={secondColumn}
                                            thirdColumn={thirdColumn}
                                            fourthColumn={fourthColumn}
                                            firstColumnArticlesCount={firstColumnArticlesCount}
                                            secondColumnArticlesCount={secondColumnArticlesCount}
                                            thirdColumnArticlesCount={thirdColumnArticlesCount}
                                            fourthColumnArticlesCount={fourthColumnArticlesCount}
                                            changeHiddenLayers={changeHiddenLayers}
                                        />
                                    </SemanticTable>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <ColorIndicatorAndDownloadSection setOpen={setInfoPopupOpen} saveAsExcel={saveAsExcel}/>
            {infoPopupOpen && <DownloadInfoPopup setOpen={setInfoPopupOpen} subject="heatmap"/>}
        </>
    );
}

export default Dashboard;
