import React from "react";
import datePicker from "../../../images/datePicker.svg";

function DateSection({ children }) {
    return (
        <div className="date-section">
            <div className="datepicker-icon icon-container">
                <img src={datePicker} alt="" />
            </div>
            <div className="date-inputs">
                {children}
            </div>
        </div>
    )
}

export default DateSection;