import React, {useEffect, useRef, useState} from "react";
import fullScreenIcon from '../../../images/fullScreen.svg';
import { DataSet } from "vis-data/peer";
import { Network } from "vis-network/peer";
import SelectItem from "./selectItem";
import EditLayerSection from "./editLayersSection";
import { createPortal } from "react-dom";
import FullScreenGraph from "./fullScreenGraph";
import useGraphController from "../../../customHooks/controllerHooks/graphController";
import {useDispatch, useSelector} from "react-redux";
import {
    rxGetEntityDetailsDone,
    rxGetFiltersDone,
    rxGetNetworkDone
} from "../../../state-management/actions/graphActions";
import EntityDetails from "./entityDetails";
import useDidUpdateEffect from "../../../customHooks/useDidUpdateEffect";
import GraphLoader from "./graphLoader";

let options = {
    "configure": {
        "enabled": false,
    },
    "edges": {
        "color": {
            "inherit": true
        },
        "smooth": {
            "enabled": false,
            "type": "continuous"
        }
    },
    nodes: {
        font: {
            size: 75,
        }
    },
    "interaction": {
        "dragNodes": true,
        "hideEdgesOnDrag": false,
        "hideNodesOnDrag": false
    },
    "physics": {
        "barnesHut": {
            "avoidOverlap": 0,
            "centralGravity": 0.3,
            "damping": 0.09,
            "gravitationalConstant": -80000,
            "springConstant": 0.001,
            "springLength": 250
        },
        "enabled": true,
        "stabilization": {
            "enabled": true,
            "fit": true,
            "iterations": 1000,
            "onlyDynamicEdges": false,
            "updateInterval": 50
        }
    }
};


function NetworkGraph({ networkSelectedValue }) {

    const dispatch = useDispatch();
    const networkRef = useRef(null);

    let network;


    const { getFilters, getEntityDetails, getNetwork } = useGraphController();

    const filters = useSelector(state => state.graphData.graphFilters.data);

    const networkData = useSelector(state => state.graphData.network.data);

    const networkDataForVis = {
        nodes: networkData.entities.map(item => ({id: item.id, label: item.name, shape: 'dot'})),
        edges: networkData.relationships.map(item => ({
            from: item.source_id,
            to: item.target_id,
        }))
    }

    const [ fullScreen, setFullScreen ] = useState(false);

    const fullScreenWrapper = document.getElementById('full-screen-wrapper');

    const [ selectedEntities, setSelectedEntities ] = useState([]);
    const [ entityError, setEntityError ] = useState(false);

    const [ openEditLayers, setOpenEditLayers ] = useState(false);

    const [ currentLayers, setCurrentLayers ] = useState([]);

    const [ selectedLayers, setSelectedLayers ] = useState([]);
    const [ allLayersSelected, setAllLayersSelected ] = useState(true);
    const [ layerError, setLayerError ] = useState(false);

    const [ buttonDisabled, setButtonDisabled ] = useState(false);

    const [ loadingPercent, setLoadingPercent ] = useState(0);

    useEffect(() => {
        getNetworkData();
        return () => {
            dispatch(rxGetFiltersDone());
            dispatch(rxGetEntityDetailsDone());
            dispatch(rxGetNetworkDone());
        }
    }, []);

    useEffect(() => {
        getFilters({project: networkSelectedValue});
        if(localStorage.getItem(networkSelectedValue)) {
            setCurrentLayers(JSON.parse(localStorage.getItem(networkSelectedValue)));
        } else {
            setCurrentLayers([]);
        }

        setSelectedLayers([]);
        setAllLayersSelected(true);
        layerError && setLayerError(false);
        entityError && setEntityError(false);

    }, [networkSelectedValue]);

    useDidUpdateEffect(() => {
        if(selectedLayers.length) {
            setSelectedLayers(selectedLayers.filter(layer => currentLayers.includes(layer)));
        }
    }, [currentLayers]);

    const checkErrorsAndGetNetworkData = () => {
        if (!selectedLayers.length && !allLayersSelected) {
            setLayerError(true);
        }
        if (!selectedEntities.length && filters.entity_types.length > 0) {
            setEntityError(true);
        }
        if ((selectedLayers.length || allLayersSelected) && (selectedEntities.length || filters.entity_types.length === 0)) {
            getNetworkData();
        }
    }

    const getNetworkData = () => {
        if(!buttonDisabled) {
            setButtonDisabled(true);
            const entities = selectedEntities.length === filters.entity_types.length ? []: selectedEntities;
            getNetwork({
                project: networkSelectedValue,
                entity_types: entities,
                article_layers: selectedLayers,
                relationship_weight_threshold: 0.006,
            });
        }
    };

    const getNodeDetails = entityName => {
        getEntityDetails({
            project: networkSelectedValue,
            entity: entityName,
            entity_types: selectedEntities,
            article_layers: selectedLayers,
            "relationship_weight_threshold": 0.006,
            "context_max_segments": 50
        })
    }

    useDidUpdateEffect(() => {
        drawGraph();
        dispatch(rxGetEntityDetailsDone());
    }, [networkData]);

    useEffect(() => {
        setSelectedEntities([...filters.entity_types])
    }, [filters]);

    const changeCurrentLayers = layers => {
        setCurrentLayers(layers);
        localStorage.setItem(networkSelectedValue, JSON.stringify(layers));
    }

    const handleEntitySelectClick = entity => {
        if(selectedEntities.includes(entity)) {
            setSelectedEntities(selectedEntities.filter(item => item !== entity));
        } else {
            setSelectedEntities([...selectedEntities, entity]);
            entityError && setEntityError(false);
        }
    };

    const handleSelectAllEntitiesClick = () => {
        if(selectedEntities.length === filters.entity_types.length) {
            setSelectedEntities([]);
        } else {
            setSelectedEntities([...filters.entity_types]);
            entityError && setEntityError(false);

        }
    }

    const handleLayerSelectClick = layer => {
        if(selectedLayers.includes(layer)) {
            setSelectedLayers(selectedLayers.filter(item => item !== layer));
        } else {
            setSelectedLayers([...selectedLayers, layer]);
            layerError && setLayerError (false);
        }
        if (allLayersSelected) {
            setAllLayersSelected (false);
        }
    };

    const handleSelectAllLayersClick = () => {
        setAllLayersSelected(prev => !prev);
        if (selectedLayers.length) {
            setSelectedLayers([]);
        }
        layerError && setLayerError (false);
    }

    function drawGraph() {
        let nodes = new DataSet(networkDataForVis.nodes);
        let edges = new DataSet(networkDataForVis.edges);

        // adding nodes and edges to the graph
        let data = {nodes: nodes, edges: edges};


        // if this network requires displaying the configure window,
        // put it in its div
        // options.configure["container"] = document.getElementById("config");


        network = new Network(networkRef.current, data, options);
        network.on( 'click', function(properties) {
            let ids = properties.nodes;
            let clickedNodes = nodes.get(ids);
            !!clickedNodes.length && getNodeDetails(clickedNodes[0].label);
        });


        network.on("stabilizationProgress", function(params) {
            if(document.getElementById('loadingBar')) {
                document.getElementById('loadingBar').removeAttribute("style");
                let widthFactor = params.iterations/params.total;
                setLoadingPercent(widthFactor * 100);
            }

        });
        network.once("stabilizationIterationsDone", function() {
            if(document.getElementById('loadingBar')) {
                setLoadingPercent(100);
                setTimeout(function () {
                    document.getElementById('loadingBar').style.display = 'none';
                    setButtonDisabled(false);
                    }, 500);
            }
        });
        if(networkDataForVis.nodes.length === 0){
            setButtonDisabled(false);
        }
    }

    const goToFullScreen = () => {
        const mainWrapper = document.getElementById('main-wrapper');
        setFullScreen(true);
        window.scrollTo(0, 0);
        mainWrapper.style.display = 'none';
    }
    
    return (
        <>
            {
               createPortal(
                   <FullScreenGraph
                       fullScreen={fullScreen}
                       setFullScreen={setFullScreen}
                       selectedLayers={selectedLayers}
                       currentLayers={currentLayers}
                       handleLayerSelectClick={handleLayerSelectClick}
                       handleSelectAllLayersClick={handleSelectAllLayersClick}
                       filters={filters}
                       handleSelectAllEntitiesClick={handleSelectAllEntitiesClick}
                       handleEntitySelectClick={handleEntitySelectClick}
                       selectedEntities={selectedEntities}
                       setCurrentLayers={changeCurrentLayers}
                       getNetworkData={getNetworkData}
                       buttonDisabled={buttonDisabled}
                       getNodeDetails={getNodeDetails}
                       allLayersSelected={allLayersSelected}
                       layerError={layerError}
                       entityError={entityError}
                       checkErrorsAndGetNetworkData={checkErrorsAndGetNetworkData}
                   />, fullScreenWrapper)
            }
            <div className="network-graph-container">
                <div className="graph-select-container">
                    <div className="graph-select">
                        <div className="select-title" >
                            Select Layer
                        </div>
                        <div className="selected-layers">
                            <div className="selected-layers-scroll">
                                <SelectItem
                                    text='All layers'
                                    selected={allLayersSelected}
                                    handleClick={handleSelectAllLayersClick}
                                />
                                {
                                    currentLayers.map(item => {
                                        return (
                                            <SelectItem
                                                key={item}
                                                text={item}
                                                selected={selectedLayers.includes(item)}
                                                handleClick={()=>{handleLayerSelectClick(item)}}
                                            />)

                                    })
                                }
                            </div>

                            <div className="edit-layers-link" onClick={() => setOpenEditLayers(true)}>
                                Edit Settings
                            </div>
                            {
                                layerError &&
                                <div className="edit-section-title error">
                                    Sie müssen mindestens ein Layer auswählen.
                                </div>
                            }

                        </div>
                    </div>
                    <div className="graph-select" >
                        <div className="select-title">
                            Select Entity Type
                        </div>
                        <div className="selected-layers">
                            <SelectItem
                                text='All'
                                selected={selectedEntities.length === filters.entity_types.length}
                                handleClick={handleSelectAllEntitiesClick}
                            />
                            {
                                filters.entity_types.map(item => {
                                    return (
                                        <SelectItem
                                            key={item}
                                            text={item}
                                            selected={selectedEntities.includes(item)}
                                            handleClick={() => handleEntitySelectClick(item) }
                                        />
                                    )
                                })
                            }
                            {
                                entityError &&
                                <div className="edit-section-title error">
                                    Sie müssen mindestens eine Option auswählen.
                                </div>
                            }

                        </div>
                    </div>
                    <div className="button-container">
                        <div className={`select-layers-button ${buttonDisabled? 'disabled': ''}`} onClick={checkErrorsAndGetNetworkData}>
                            Rerun
                        </div>
                    </div>

                    {
                        openEditLayers &&
                        <EditLayerSection
                            layers={filters.article_layers}
                            onClose={() => setOpenEditLayers(false)}
                            currentLayers={currentLayers}
                            setCurrentLayers={changeCurrentLayers}
                            fullScreen={fullScreen}
                        />
                    }
                </div>
                <div className="network-graph">
                    <div id="mynetwork" ref={networkRef}>
                    </div>
                    <div id="loadingBar" style={{display: "none"}}>
                        <GraphLoader percent={loadingPercent} />
                    </div>
                    <div id="config"></div>
                    <img src={fullScreenIcon} className="full-screen-icon" onClick={goToFullScreen}/>
                </div>
                <EntityDetails />
            </div>
        </>

    );
}

export default NetworkGraph;