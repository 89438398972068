import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./state-management/store";
import { Provider } from 'react-redux';
import { HashRouter, Switch, Route } from 'react-router-dom';
import defineGuards from "./routing/defineGuards";
import IsNotAuthLayer from "./routing/isNotAuthLayer";
import IsAuthLayer from "./routing/isAuthLayer";
import LoginPage from "./view/pages/loginPage";
import MainSplash from "./view/components/mainSplash";

function AppWrapper() {
    return (
        <Provider store={store}>
            <React.StrictMode>
                <HashRouter>
                    <MainSplash>
                        <Switch>
                            <Route exact path='/login' render={() => defineGuards([IsNotAuthLayer], LoginPage)} />
                            <Route exact path='/verify' render={() => defineGuards([IsNotAuthLayer], LoginPage)} />
                            <Route path='/' render={() => defineGuards([IsAuthLayer], App)}/>
                        </Switch>
                    </MainSplash>
                </HashRouter>
            </React.StrictMode>
        </Provider>
    );
}

ReactDOM.render(
  <AppWrapper />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
