import Fetch from "../fetch";
import {CONTENT_TYPES} from "../../core/constants/contentTypes";

const END_POINT = {
    prefix: 'login',
    token: 'token',
    info: 'info',
    company: 'company',
    enterprise: 'enterprise',
    plan: 'plan',
    all: 'all',
    check: 'check',
    warnings: 'warnings',
    update: 'update',
    cancel: 'cancel',
    upload: 'upload',
    register: 'register',
    verifyAccount: 'verify-account'
};

class LoginApiHandler {

    async login(data) {
        return  await Fetch.userPost(END_POINT.prefix, data);

    }

    async refresh(data) {
        return await Fetch.userPost(END_POINT.token, data);
    }

    async getUserInfo(queryData) {
        return await Fetch.userGet(END_POINT.info, queryData);
    }

    async getCompanyInfo(queryData) {
        return await Fetch.userGet(`${END_POINT.company}/${END_POINT.info}`, queryData);
    }

    async uploadCompanyImage(payload) {
        return await Fetch.userPut(`${END_POINT.company}/${END_POINT.info}/${END_POINT.upload}?company=${payload.company}`, payload.data, CONTENT_TYPES.formData);
    }

    async getAllPlans() {
        return await Fetch.userGet(`${END_POINT.enterprise}/${END_POINT.plan}/${END_POINT.all}`);
    }

    async getCompanyData(queryData) {
        return await Fetch.userGet(END_POINT.company, queryData);
    }

    async checkWarnings(queryData) {
        const relativePath = `${END_POINT.company}/${END_POINT.check}/${END_POINT.warnings}`
        return await Fetch.userGet(relativePath, queryData);
    }

    async updatePlan(queryData) {
        let queryProjects;
        if(!queryData.projects) {
            queryProjects = '';
        } else if(typeof queryData.projects === 'string'){
            queryProjects = `&projects=${queryData.projects}`
        }
        if(Array.isArray(queryData.projects)){
            queryProjects = '';
            queryData.projects.forEach(project => {
                queryProjects += `&projects=${project}`;
            });
        }
        const relativePath = `${END_POINT.company}/${END_POINT.update}/${END_POINT.plan}?company=${queryData.company}&plan=${queryData.plan}${queryProjects}`;
        return await Fetch.userPut(relativePath);
    }

    async cancelPlan(queryData) {
        const relativePath = `${END_POINT.company}/${END_POINT.cancel}/${END_POINT.plan}?company=${queryData.company}`;
        return await Fetch.userPut(relativePath);
    }

    async verifyAccount(queryData) {
        const  relativePath = `${END_POINT.company}/${END_POINT.register}/${END_POINT.verifyAccount}`;
        const res =  await Fetch.userGet(relativePath, queryData);
        console.log({res});
        return res;
    }

}

const loginApiHandler = new LoginApiHandler();
export default loginApiHandler;
