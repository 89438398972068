import React, {useEffect, useRef, useState} from "react";
import newsPaper from "../../../images/newspaper.svg";
import closeIcon from "../../../images/closeIcon.svg";
import {useDispatch, useSelector} from "react-redux";
import UseProjectController from "../../../customHooks/controllerHooks/projectController";
import {rxGetProjectDetailsDone} from "../../../state-management/actions/projectActions";

function ProjectsSection({ openRequestPopup }) {
    const [ openProjectsPopup, setOpenProjectsPopup ] = useState(false);
    const dispatch = useDispatch();
    const projects = useSelector(state => state.userInfo.data.projects);
    const [ value, setValue ] = useState(projects[0] || '');
    const [ openDropdown, setOpenDropdown ] = useState(true);
    const ref = useRef(null);


    const projectDetails = useSelector(state => state.projectDetails.data);

    const { getProjectDetails } = UseProjectController();

    useEffect(() => {
        getProjectDetails({name: value});
        return () => dispatch(rxGetProjectDetailsDone());
    }, [value])

    const handleClick = project => {
        if(project !== value) {
            setValue(project);
        }

        setOpenDropdown(false);
    }

    const handleClickOutside = e => {
        if(ref.current && !ref.current.contains(e.target)){
            setOpenDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    return (
        <div className="projects-section">
            <div className={`project-dropdown ${openDropdown? 'open': ''}`} ref={ref}>
                <div className="project-dropdown-input" onClick={()=>setOpenDropdown(prev => !prev)}>{value}</div>
                <div className="project-dropdown-content">
                    {
                        projects.map(project => {
                            return (
                                <div className={`project-dropdown-item ${project === value ? 'active': ''}`}
                                     key={project}
                                     onClick={() => handleClick(project)}
                                >{project}</div>
                                )

                        })
                    }

                </div>
            </div>
            <button type="button" className="project-details-button" onClick={()=>setOpenProjectsPopup(true)}>
                Project details
            </button>
            <div className="request-button"
                 onClick={openRequestPopup}
            >
                <img src={newsPaper} alt="" />
                <span>Project request form</span>
            </div>
            {openProjectsPopup &&
            <div className="project-details-popup">
                <div className="project-details">
                    <img src={closeIcon} alt="" className='close-icon' onClick={()=>setOpenProjectsPopup(false)}/>
                    <div className="project-details-bar">
                        <div className="project-details-bar-text">
                            Project-details
                        </div>
                    </div>
                    <div className="project-details-content">
                        <div className="project-title">
                            {value}
                        </div>
                        <div className="collected-data">
                            Collected data: <span></span>
                        </div>
                        <div className="project-details-result">
                            {
                                projectDetails.map(detail => {
                                    return (
                                        <div className="project-details-result-item">
                                            <div className="project-details-term">
                                                <span>Term:</span> {detail.term}
                                            </div>
                                            {
                                                !!detail.site.length && detail.site.map(item => {
                                                    return (
                                                        <>
                                                            {
                                                                item ?
                                                                    <div className="project-details-site">
                                                                        <span>Site:</span> {item}
                                                                    </div>:
                                                                    null
                                                            }
                                                        </>

                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default ProjectsSection;
