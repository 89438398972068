import React from 'react';
import redConfirmIcon from '../../../images/redConfirmIcon.png';
import PLAN_UPDATE_MODAL_CONSTANTS from "../../../core/constants/planUpdateModalConstants";
import {useSelector} from "react-redux";
const PROJECTS = [
  'ProjectName 1',
  'ProjectName 6',
  'ProjectName 2',
  'ProjectName 7',
  'ProjectName 3',
  // 'ProjectName 9',
  // 'ProjectName 4',
  // 'ProjectName 5',
  // 'ProjectName 8',
  // 'ProjectName 10',

];

function LostProjects({ setModalState, onClose, projects }) {

    const { email } = useSelector(state => state.userInfo.data);

  const marginTop = PROJECTS.length === 10 ? 0 : 20;
  const marginBottom = PROJECTS.length === 10 ? 10: 12;
  const downMarginTop = PROJECTS.length === 10 ? 0 : 10;

  const handleConfirm = () =>  {
      setModalState(PLAN_UPDATE_MODAL_CONSTANTS.PROJECT_SELECT);
  }

  return (
    <>
      <div className="downgrade-title">
        Hier sind die Projekte, die Sie verlieren:
      </div>

      <div className="downgrade-select-section" style={{marginTop:0}}>
        {
          projects.map(project => {
            return (
              <div className="downgrade-select-item" key={project} style={{marginBottom}}>
                <img src={redConfirmIcon} />
                <span>{project}</span>
              </div>
            )
          })
        }

      </div>
      <div className="downgrade-text" style={{lineHeight: '20px', marginTop: downMarginTop }}>
        Nur noch ein Account wird Zugriff auf die Daten haben: <br />
        <span>{email}</span>   Sind Sie sicher?

      </div>

      <div className="plan-update-popup-buttons">
        <div className="plan-update-popup-button" onClick={onClose}>
          Abbrechen
        </div>
        <div className="plan-update-popup-button" onClick={handleConfirm}>
          Bestätigen
        </div>
      </div>
    </>
  );
}

export default LostProjects;
