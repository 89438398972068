import coverageOperations from "../../services/operations/coverageOperations";
import { useDispatch } from "react-redux";
import {
    rxGetPublisherFilterFirstColCoverageDone,
    rxGetPublisherFilterFirstColCoveragePending,
    rxGetPublisherFilterFourthColCoverageDone,
    rxGetPublisherFilterFourthColCoveragePending,
    rxGetPublisherFilterSecondColCoverageDone,
    rxGetPublisherFilterSecondColCoveragePending,
    rxGetPublisherFilterThirdColCoverageDone,
    rxGetPublisherFilterThirdColCoveragePending
} from "../../state-management/actions/publisherActions";
import {ABORT_API_KEYS, abortFetch} from "../../services/operations/abortFetch";

function UseCoverageController() {
    const dispatch = useDispatch();

    async function getPublisherFilterAllColumnCoverage(queryData) {
        dispatch(rxGetPublisherFilterFirstColCoveragePending());
        dispatch(rxGetPublisherFilterSecondColCoveragePending());
        dispatch(rxGetPublisherFilterThirdColCoveragePending());
        dispatch(rxGetPublisherFilterFourthColCoveragePending());
        const response = await coverageOperations.getPublisherFilterCoverage(queryData);
        dispatch(rxGetPublisherFilterFirstColCoverageDone(response));
        dispatch(rxGetPublisherFilterSecondColCoverageDone(response));
        dispatch(rxGetPublisherFilterThirdColCoverageDone(response));
        dispatch(rxGetPublisherFilterFourthColCoverageDone(response));
    }

    async function getPublisherFilterFirstColCoverage(queryData) {
        dispatch(rxGetPublisherFilterFirstColCoveragePending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublisherFilterFirstColCoverage);
        const response = await coverageOperations.getPublisherFilterCoverage(queryData, abortSignal);
        dispatch(rxGetPublisherFilterFirstColCoverageDone(response));
    }

    async function getPublisherFilterSecondColCoverage(queryData) {
        dispatch(rxGetPublisherFilterSecondColCoveragePending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublisherFilterSecondColCoverage);
        const response = await coverageOperations.getPublisherFilterCoverage(queryData, abortSignal);
        dispatch(rxGetPublisherFilterSecondColCoverageDone(response));
    }

    async function getPublisherFilterThirdColCoverage(queryData) {
        dispatch(rxGetPublisherFilterThirdColCoveragePending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublisherFilterThirdColCoverage);
        const response = await coverageOperations.getPublisherFilterCoverage(queryData, abortSignal);
        dispatch(rxGetPublisherFilterThirdColCoverageDone(response));
    }

    async function getPublisherFilterFourthColCoverage(queryData) {
        dispatch(rxGetPublisherFilterFourthColCoveragePending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublisherFilterFourthColCoverage);
        const response = await coverageOperations.getPublisherFilterCoverage(queryData, abortSignal);
        dispatch(rxGetPublisherFilterFourthColCoverageDone(response));
    }

    return {
        getPublisherFilterAllColumnCoverage,
        getPublisherFilterFirstColCoverage,
        getPublisherFilterSecondColCoverage,
        getPublisherFilterThirdColCoverage,
        getPublisherFilterFourthColCoverage,
    }
}

export default UseCoverageController;