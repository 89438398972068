import React from "react";
import {useSelector} from "react-redux";

function getInterests(arr){
    let obj = {};
    arr.forEach(item => {
        obj[item.name] = item.number;
    });
    return obj;
}


function InterestSection() {
    const coverage = useSelector(state => state.publisherFilters.coverage);
    const heatmapData = useSelector(state => state.publisherHeatmap);

    const firstColumnOptions = coverage.firstColumnOptions.data;
    const secondColumnOptions = coverage.secondColumnOptions.data;
    const thirdColumnOptions = coverage.thirdColumnOptions.data;
    const fourthColumnOptions = coverage.fourthColumnOptions.data;

    const { filteredArticlesCount: secondColumnCount, coverage: secondColumnCoverage } = heatmapData.secondColumn.data;
    const { filteredArticlesCount: thirdColumnCount, coverage: thirdColumnCoverage } = heatmapData.thirdColumn.data;
    const { filteredArticlesCount: fourthColumnCount, coverage: fourthColumnCoverage } = heatmapData.fourthColumn.data;


    const firstColumnInterests = getInterests(firstColumnOptions);
    const secondColumnInterests = secondColumnCoverage ? {[secondColumnCoverage]: true} : getInterests(secondColumnOptions);
    const thirdColumnInterests = thirdColumnCoverage ? {[thirdColumnCoverage]: true} :getInterests(thirdColumnOptions);
    const fourthColumnInterests = fourthColumnCoverage ? {[fourthColumnCoverage]: true} : getInterests(fourthColumnOptions);

    return (
        <div className="interest-section">
            <div className="interest">
                <div>International</div>
                <div>National</div>
                <div>Regional</div>
                <div>Special interest</div>
            </div>
            <div className="block-section">
                <div>
                    <div className={firstColumnInterests.INTERNATIONAL? "interest-international": "interest-block"} />
                    <div className={secondColumnCount && secondColumnInterests.INTERNATIONAL? "interest-international": "interest-block"} />
                    <div className={thirdColumnCount && thirdColumnInterests.INTERNATIONAL? "interest-international": "interest-block"} />
                    <div className={fourthColumnCount && fourthColumnInterests.INTERNATIONAL? "interest-international": "interest-block"} />
                </div>
                <div>
                    <div className={firstColumnInterests.NATIONAL? "interest-national": "interest-block"} />
                    <div className={secondColumnCount && secondColumnInterests.NATIONAL? "interest-national": "interest-block"} />
                    <div className={thirdColumnCount && thirdColumnInterests.NATIONAL? "interest-national": "interest-block"} />
                    <div className={fourthColumnCount && fourthColumnInterests.NATIONAL? "interest-national": "interest-block"} />
                </div>
                <div>
                    <div className={firstColumnInterests.REGIONAL? "interest-regional": "interest-block"} />
                    <div className={secondColumnCount && secondColumnInterests.REGIONAL? "interest-regional": "interest-block"} />
                    <div className={thirdColumnCount && thirdColumnInterests.REGIONAL? "interest-regional": "interest-block"} />
                    <div className={fourthColumnCount && fourthColumnInterests.REGIONAL? "interest-regional": "interest-block"} />
                </div>
                <div>
                    <div className={firstColumnInterests.SPECIAL? "interest-special": "interest-block"} />
                    <div className={secondColumnCount && secondColumnInterests.SPECIAL? "interest-special": "interest-block"} />
                    <div className={thirdColumnCount && thirdColumnInterests.SPECIAL? "interest-special": "interest-block"} />
                    <div className={fourthColumnCount && fourthColumnInterests.SPECIAL? "interest-special": "interest-block"} />
                </div>
            </div>
        </div>
    );
};

export default InterestSection;