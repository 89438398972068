import createAsyncReducer from "../createAsyncReducer";
import {combineReducers} from "redux";
import {PUBLISHER_TYPES} from "../actionTypes/publisherActionTypes";

export const publisherHeatmapReducer = combineReducers({
        firstColumn: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHERS_FIRST_COLUMN, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: [],
            startDate: '',
            endDate: '',
        }),
        secondColumn: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHERS_SECOND_COLUMN, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: {},
            coverage: '',
            company: '',
        }),
        thirdColumn: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHERS_THIRD_COLUMN, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: {},
            coverage: '',
            company: '',
        }),
        fourthColumn: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHERS_FOURTH_COLUMN, {
            project: '',
            totalArticlesCount: '',
            filteredArticlesCount: '',
            layers: {},
            coverage: '',
            company: '',
        }),
});

export const publisherFiltersReducer = combineReducers({
    coverage: combineReducers({
        firstColumnOptions: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_FIRST_COL_COVERAGE, []),
        secondColumnOptions: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_SECOND_COL_COVERAGE, []),
        thirdColumnOptions: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_THIRD_COL_COVERAGE, []),
        fourthColumnOptions: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_FOURTH_COL_COVERAGE, []),
    }),
    companies:  combineReducers({
        secondColumnOptions: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_SECOND_COL_COMPANIES, []),
        thirdColumnOptions: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_THIRD_COL_COMPANIES, []),
        fourthColumnOptions: createAsyncReducer(PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_FOURTH_COL_COMPANIES, []),
    }),
});
