const PLAN_UPDATE_MODAL_CONSTANTS = {
    FIRST_PAGE: 'FIRS_PAGE',
    TO_PLUS_WARNING: 'TO_PLUS_WARNING',
    TO_PRO_WARNING: 'TO_PRO_WARNING',
    DOWNGRADE_SELECT: 'DOWNGRADE_SELECT',
    SELECTED_PROJECTS: 'SELECTED_PROJECTS',
    DOWNGRADE_TO_PLUS_CONFIRM: 'DOWNGRADE_TO_PLUS_CONFIRM',
    LOST_PROJECTS: 'LOST_PROJECTS',
    PROJECT_SELECT: 'PROJECT_SELECT',
    DOWNGRADE_TO_PRO_CONFIRM: 'DOWNGRADE_TO_PRO_CONFIRM',
    DOWNGRADE_TO_PRO_ABORT: 'DOWNGRADE_TO_PRO_ABORT',
    PLAN_UPGRADE_CONFIRM: 'PLAN_UPGRADE_CONFIRM',
}

export const PLAN_CANCEL_MODAL_CONSTANTS = {
    FIRST_PAGE: 'FIRST_PAGE',
    CONFIRM: 'CONFIRM',
}

export default PLAN_UPDATE_MODAL_CONSTANTS;