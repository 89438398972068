import React from "react";

function TableLeftBlock( { withDivider }) {
    return (
        <div className="table-left-block">
            {withDivider && <div className="left-block-divider"/>}
        </div>
    );
}

export default TableLeftBlock;