import React from "react";

function AccessBar() {
    return (
        <div className="outcome-bar access-bar">
            <div className="outcome-bar-text access-bar-text">
                access
            </div>
        </div>
    );
}

export default AccessBar;