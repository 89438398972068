import React from 'react'

function TableData({ data, totalCount }) {
    const numberInPercents = data ? Math.round(data / totalCount * 100): 0;
    const colorNumber = numberInPercents < 20 ? 0 : numberInPercents === 20 ? 20 :  Math.ceil(numberInPercents/10);

    return (
        <td className={`color-scheme-${colorNumber}`}>{data}</td>
    );
}

TableData.defaultProps = {
    data: 0,
    totalCount: 0,
}

export default TableData;