import { ENVIRONMENT_CONSTANTS } from "../constants/environmentConstants";

let apiUrl;

if (process.env.NODE_ENV === ENVIRONMENT_CONSTANTS.development) {
    apiUrl = 'https://api.evai-intelligence.com'
} else {
    apiUrl = 'https://api.evai-intelligence.com'
    
}

export const DOMAIN = `${apiUrl}/enterprise`;
export const LOGIN_URL = `${apiUrl}/user`;
export const API_URL = `${apiUrl}/api`;
export const GRAPH_URL = `${apiUrl}:444/project/visualization`;
