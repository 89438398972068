import React from "react";
import questionMark from "../../../images/question-mark.svg";
import downloadIcon from "../../../images/download.svg";

function DownloadSection({ setOpen, saveAsExcel }) {

    return (
        <div className="download-section" >
            <div className="icon-container" onClick={()=>setOpen(true)}>
                <img src={questionMark} alt="" />
            </div>
            <div className="download-button" onClick={saveAsExcel}>
                <img src={downloadIcon} alt=""/>
                <span>Download data</span>
            </div>
        </div>
    );
}

export default DownloadSection;