import React, {useEffect, useRef, useState} from "react";
import FiltersTable from "./filtersTable";

function LayersBlock({ color, options, selectedValue, handleChange }) {
    const [ filterValue, setFilterValue ] = useState('');
    const [ showSelect, setShowSelect ] = useState(false);

    const ref = useRef(null);

    const closeDropdown = () => {
        setShowSelect(false);
        setFilterValue('');
    };

    const handleClickOutside = e => {
        if(ref.current && !ref.current.contains(e.target)){
            closeDropdown();
        }
    };

    const toggleDropdown = () => {
        if(showSelect){
            setShowSelect(false);
            setFilterValue('');
        } else {
            setShowSelect(true);
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    return (
        <div className={`layers-block ${showSelect? "open": ""}`} ref={ref}>
            <div className={`layers-content ${color}`} onClick={toggleDropdown} title={selectedValue}>
                {selectedValue ? selectedValue:
                    <div className="layer-placeholder" >Choose layer</div>}
            </div>
            <FiltersTable
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                options={options}
                selectedValue={selectedValue}
                handleChange={handleChange}
                setShowSelect={setShowSelect}
                />
        </div>

    )
}

export default LayersBlock;