import React from "react";
import minusIcon from "../../../images/minusIcon.svg";
import plusIcon from "../../../images/btnPlus.svg";

const SemanticTableTitle = ({ title, isOpen, handleClick }) => {
    return (
        <div className="semantic-table-title">
            <div className="semantic-attribute">
                {title}
            </div>
            <div className="icon-container semantic-table-button" onClick={handleClick}>
                <img src={isOpen ? minusIcon : plusIcon} alt="" className={!isOpen ? 'semantic-table-icon' : ''}/>
            </div>
        </div>
    )
};

export default SemanticTableTitle;