import React from 'react';
import signOut from "../../../images/signOut.svg";
import userProfile from "../../../images/userProfile.png"
import titleLogo from "../../../images/TitleLogo.svg";
import linkedinIcon from "../../../images/linkedinIcon.svg";
import twitterIcon from "../../../images/twitterIcon.svg";
import {useSelector, useDispatch } from "react-redux";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";
import { useHistory } from 'react-router-dom';
import UsePublisherController from "../../../customHooks/controllerHooks/publisherController";
import UseSemanticsController from "../../../customHooks/controllerHooks/semanticController";
import UseProjectController from "../../../customHooks/controllerHooks/projectController";
import {formatDateForApi} from "../splash";
import {
    rxGetFirstColumnDone,
    rxGetFourthColumnDone,
    rxGetSecondColumnDone,
    rxGetThirdColumnDone,
    rxGetFirstColumnV3Done,
    rxGetSecondColumnV3Done,
    rxGetThirdColumnV3Done,
    rxGetFourthColumnV3Done,
} from "../../../state-management/actions/semanticsActions";
import {rxGetHiddenLayersDone, rxGetHiddenPublishersDone} from "../../../state-management/actions/projectActions";
import {
    rxSaveDashboardSettings,
    rxSaveChartSettings,
    rxSaveHeatmapSettings,
    rxSavePublisherHeatmapSettings
} from "../../../state-management/actions/savedSettingsActions";
import {
    rxGetPublisherFilterFirstColCoverageDone,
    rxGetPublisherFilterFourthColCompaniesDone,
    rxGetPublisherFilterFourthColCoverageDone,
    rxGetPublisherFilterSecondColCompaniesDone,
    rxGetPublisherFilterSecondColCoverageDone,
    rxGetPublisherFilterThirdColCompaniesDone,
    rxGetPublisherFilterThirdColCoverageDone,
    rxGetPublishersFirstColumnDone, rxGetPublishersFourthColumnDone,
    rxGetPublishersSecondColumnDone,
    rxGetPublishersThirdColumnDone
} from "../../../state-management/actions/publisherActions";

const now = new Date();
const oneYearBefore = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
const threeMonthBefore = new Date(new Date().setMonth(new Date().getMonth() - 3));

function Header({ isAuth }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const email = useSelector(state => state.userInfo.data.email);
    const { logout } = UseLoginController();

    const selectedSubject = useSelector(state => state.userInfo.data.projects[0] || '');

    const startDate = formatDateForApi(oneYearBefore);
    const threeMonthsBeforeStartDate = formatDateForApi(threeMonthBefore);
    const endDate = formatDateForApi(now);

    const { getPublishersFirstColumn } = UsePublisherController();
    const {
        getFirstColumnV5, getFirstColumnV3,
    } = UseSemanticsController();
    const { getHiddenLayers, getHiddenPublishers } = UseProjectController();

    const handleLogoClick = () => {
        history.push('/');
        getHiddenLayers({name: selectedSubject});
        getHiddenPublishers({name: selectedSubject});
        getFirstColumnV5({
            startDate: threeMonthsBeforeStartDate,
            endDate,
            project: selectedSubject,
        });
        getFirstColumnV3({
            startDate: threeMonthsBeforeStartDate,
            endDate,
            project: selectedSubject,
        });
        getPublishersFirstColumn({
            project: selectedSubject,
            startDate, endDate
        });
    }

    const goToAccountPage = () => {
        history.push('/account-page');
        dispatch(rxGetFirstColumnDone());
        dispatch(rxGetSecondColumnDone());
        dispatch(rxGetThirdColumnDone());
        dispatch(rxGetFourthColumnDone());
        dispatch(rxGetFirstColumnV3Done());
        dispatch(rxGetSecondColumnV3Done());
        dispatch(rxGetThirdColumnV3Done());
        dispatch(rxGetFourthColumnV3Done());
        dispatch(rxGetHiddenLayersDone());
        dispatch(rxGetHiddenPublishersDone());
        dispatch(rxSaveDashboardSettings({}));
        dispatch(rxSaveHeatmapSettings({}));
        dispatch(rxSaveChartSettings({}));
        dispatch(rxSavePublisherHeatmapSettings({}));
        dispatch(rxGetPublisherFilterFirstColCoverageDone());
        dispatch(rxGetPublisherFilterSecondColCoverageDone());
        dispatch(rxGetPublisherFilterThirdColCoverageDone())
        dispatch(rxGetPublisherFilterFourthColCoverageDone());
        dispatch(rxGetPublisherFilterSecondColCompaniesDone());
        dispatch(rxGetPublisherFilterThirdColCompaniesDone());
        dispatch(rxGetPublisherFilterFourthColCompaniesDone());
        dispatch(rxGetPublishersFirstColumnDone());
        dispatch(rxGetPublishersSecondColumnDone());
        dispatch(rxGetPublishersThirdColumnDone());
        dispatch(rxGetPublishersFourthColumnDone());
    }

    return (
        <div className='header'>
            <div className='sign-out'>
                {isAuth &&
                <>
                    <img src={userProfile} alt="" className="user-profile-icon"  onClick={goToAccountPage}/>
                    <span>{email}</span>
                    <img src={signOut} alt="" className="sign-out-icon" onClick={logout} />
                </>}
            </div>
            <div style={{display: "flex"}}>
                <div>
                    <img src={titleLogo} alt="" className='title-logo'  onClick={handleLogoClick}/>
                </div>
                <div style={{marginLeft: "auto", marginTop: 25}}>
                    <a href="https://www.linkedin.com/company/evai-intelligence"
                       target="_blank" >
                        <img src={linkedinIcon} alt="" style={{position: "relative", top: 1}}/>
                    </a>
                    <a href="https://twitter.com/evaintelligence"
                       target="_blank">
                        <img src={twitterIcon} alt="" style={{marginLeft: 3}}/>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Header;