import React from "react";

function HeatmapLoader() {
    return (
        <div className="lds-wrapper">
            <div className="lds-default">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    );
}

export default HeatmapLoader;