import React, {useEffect, useRef} from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import MainPage from "./view/pages/mainPage";
import UseLoginController from "./customHooks/controllerHooks/loginController";
import Splash from "./view/components/splash";
import Header from "./view/components/header";
import AccountPage from "./view/pages/accountPage";

const ACTIVITY_TIMEOUT_TIME = 119 * 60 * 1000; //2 hours;
const TROTTLE_TIME = 60 * 1000; //1 minute


function App() {
    const userActivityTimeout = useRef(null);
    const userActivityTrottlerTimeout = useRef(null);
    const { logout } = UseLoginController();

    useEffect(() => {
        userActivityTimeout.current = setTimeout(logout, ACTIVITY_TIMEOUT_TIME);
        activateActivityTracker();
        return () =>{
            deactivateActivityTracker();
            clearTimeout((userActivityTimeout.current));
            clearTimeout((userActivityTrottlerTimeout.current))
        }
    }, []);

    const resetUserActivityTimeout = () => {
        clearTimeout(userActivityTimeout.current);
        userActivityTimeout.current = setTimeout(logout, ACTIVITY_TIMEOUT_TIME);
    };

    const userActivityTrottler = () => {
        if(!userActivityTrottlerTimeout.current){
            userActivityTrottlerTimeout.current = setTimeout(() => {
                resetUserActivityTimeout();
                userActivityTrottlerTimeout.current = null;
            }, TROTTLE_TIME);
        }
    }

    const activateActivityTracker = () => {
        window.addEventListener("mousemove", userActivityTrottler);
        window.addEventListener("scroll", userActivityTrottler);
        window.addEventListener("keydown", userActivityTrottler);
        window.addEventListener("resize", userActivityTrottler);
    }

    const deactivateActivityTracker = () => {
        window.removeEventListener("mousemove", userActivityTrottler);
        window.removeEventListener("scroll", userActivityTrottler);
        window.removeEventListener("keydown", userActivityTrottler);
        window.removeEventListener("resize", userActivityTrottler);
    }



  return (
    <div className="root" id="full-screen-wrapper">
        <Splash>
            <div className="main-wrapper" id="main-wrapper">
                <Header isAuth={true}/>
                <Switch>
                    <Route path='/' exact key=''>
                        <MainPage />
                    </Route>
                    <Route path='/account-page' exact key='/account-page' >
                        <AccountPage />
                    </Route>
                </Switch>
            </div>
        </Splash>
    </div>
  );
}

export default App;
