import React from "react";

function SearchFilters({ children }) {
    return (
        <div className="search-filters-wrapper">
            <div className="search-filters">
                {children}
            </div>
        </div>
    )
}

export default SearchFilters;