import React, {useState} from "react";
import minusIcon from "../../../images/minusIcon.svg";
import SearchRowBlock from "./searchRowBlock";

function SearchFilterRow({   rowTitle,
                             onIconClick,
                             canClose,
                             secondColumnValues,
                             setSecondColumnValues,
                             thirdColumnValues,
                             setThirdColumnValues,
                             fourthColumnValues,
                             setFourthColumnValues,
                             secondColumnOptions,
                             thirdColumnOptions,
                             fourthColumnOptions,
                             name,
                             isLoading,
                             secondColumnLoading,
                             thirdColumnLoading,
                             fourthColumnLoading

}) {

    const changeSecondColumnValues = value => {
        setSecondColumnValues({...secondColumnValues, [name]: value})
    }

    const changeThirdColumnValues =  value => {
        setThirdColumnValues({...thirdColumnValues, [name]: value})
    }

    const changeFourthColumnValues =  value => {
        setFourthColumnValues({...fourthColumnValues, [name]: value})
    }


    return (
        <div className="search-filter-row">
            <div className="search-filter-row-title">
                {rowTitle}
            </div>
            {canClose ?
                <div className="icon-container minus-icon minus-icon-light" onClick={onIconClick}>
                    <img alt="" src={minusIcon} />
                </div>:
                <div className="icon-container minus-icon minus-icon-light" >
                </div>}

            <div className="search-row-block-wrapper">
                <div className="search-row-block all-block">
                    All
                </div>
            </div>
            <SearchRowBlock options={secondColumnOptions}
                            value={secondColumnValues[name]}
                            name={name}
                            handleChange={changeSecondColumnValues}
                            isLoading={isLoading || secondColumnLoading}

            />
            <SearchRowBlock options={thirdColumnOptions}
                            value={thirdColumnValues[name]}
                            name={name}
                            handleChange={changeThirdColumnValues}
                            isLoading={isLoading || thirdColumnLoading}/>

            <SearchRowBlock isLast={true}
                            options={fourthColumnOptions}
                            value={fourthColumnValues[name]}
                            name={name}
                            handleChange={changeFourthColumnValues}
                            isLoading={isLoading || fourthColumnLoading}/>
        </div>
    )
}

export default SearchFilterRow;