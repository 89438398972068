import {USER_TYPES} from "../actionTypes/userActionTypes";

export const rxGetUserInfoPending = payload => ({
    type: USER_TYPES.RX_GET_USER_INFO_PENDING,
    payload,
});

export const rxGetUserInfoDone = payload => ({
    type: USER_TYPES.RX_GET_USER_INFO_DONE,
    payload,
});

export const rxGetLoginInfoPending = payload => ({
    type: USER_TYPES.RX_GET_LOGIN_INFO_PENDING,
    payload,
});

export const rxGetLoginInfoDone = payload => ({
    type: USER_TYPES.RX_GET_LOGIN_INFO_DONE,
    payload,
});

export const rxGetCompanyInfoPending = payload => ({
    type: USER_TYPES.RX_GET_COMPANY_INFO_PENDING,
    payload,
});

export const rxGetCompanyInfoDone = payload => ({
    type: USER_TYPES.RX_GET_COMPANY_INFO_DONE,
    payload,
});

export const rxGetAllPlansPending = payload => ({
    type: USER_TYPES.RX_GET_ALL_PLANS_PENDING,
    payload,
});

export const rxGetAllPlansDone = payload => ({
    type: USER_TYPES.RX_GET_ALL_PLANS_DONE,
    payload,
});

export const rxGetCompanyDataPending = payload => ({
    type: USER_TYPES.RX_GET_COMPANY_DATA_PENDING,
    payload,
});

export const rxGetCompanyDataDone = payload => ({
    type: USER_TYPES.RX_GET_COMPANY_DATA_DONE,
    payload,
});


