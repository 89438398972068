import semanticsOperations from "../../services/operations/semanticsOperations";
import { useDispatch, useSelector } from "react-redux";
import {
    rxGetChartCompaniesDone,
    rxGetChartCompaniesPending,
    rxGetChartLayersDone,
    rxGetChartLayersPending,
    rxGetFirstChartAllLayersDone,
    rxGetFirstChartAllLayersPending,
    rxGetFirstChartDataDone,
    rxGetFirstChartDataPending,
    rxGetFirstColumnDone,
    rxGetFirstColumnPending,
    rxGetFirstColumnV3Done,
    rxGetFirstColumnV3Pending,
    rxGetFourthColumnDone,
    rxGetFourthColumnPending,
    rxGetFourthColumnV3Done,
    rxGetFourthColumnV3Pending,
    rxGetSecondChartAllLayersDone,
    rxGetSecondChartAllLayersPending,
    rxGetSecondChartDataDone,
    rxGetSecondChartDataPending,
    rxGetSecondColumnDone,
    rxGetSecondColumnPending,
    rxGetSecondColumnV3Done,
    rxGetSecondColumnV3Pending,
    rxGetThirdChartAllLayersDone,
    rxGetThirdChartAllLayersPending,
    rxGetThirdChartDataDone,
    rxGetThirdChartDataPending,
    rxGetThirdColumnDone,
    rxGetThirdColumnPending,
    rxGetThirdColumnV3Done,
    rxGetThirdColumnV3Pending,
    rxGetFourthChartAllLayersPending,
    rxGetFourthChartAllLayersDone,
    rxGetFourthChartDataDone,
    rxGetFourthChartDataPending,
} from "../../state-management/actions/semanticsActions";
import moment from "moment";
import { abort, ABORT_API_KEYS, abortFetch } from "../../services/operations/abortFetch";

const formatDate = date => moment(date).format('YYYY/MM/DD')

function UseSemanticsController() {

    const dispatch = useDispatch();
    const firstChartAllLayers =  useSelector(state => state.semantics.semantics.firstChartAllLayers.data);
    const secondChartAllLayers = useSelector(state => state.semantics.semantics.secondChartAllLayers.data);
    const thirdChartAllLayers = useSelector(state => state.semantics.semantics.thirdChartAllLayers.data);
    const fourthChartAllLayers = useSelector(state => state.semantics.semantics.fourthChartAllLayers.data);

    async function getFirstColumnV5 (queryData) {
        dispatch(rxGetFirstColumnPending());
        const response = await semanticsOperations.getFirstColumnV5(queryData);
        if (response) {
            dispatch(rxGetFirstColumnDone(response));
        }
    }

    async function getSecondColumnV5(queryData) {
        dispatch(rxGetSecondColumnPending());
        const response =  await semanticsOperations.getSecondColumnV5(queryData);
        if(response && response.layers) {
            dispatch(rxGetSecondColumnDone(response));
        }
    }

    async function getThirdColumnV5(queryData) {
        dispatch(rxGetThirdColumnPending());
        const response =  await semanticsOperations.getThirdColumnV5(queryData);
        if(response && response.layers) {
            dispatch(rxGetThirdColumnDone(response));
        }
    }

    async function getFourthColumnV5(queryData) {
        dispatch(rxGetFourthColumnPending());
        const response =  await semanticsOperations.getFourthColumnV5(queryData);
        if (response && response.layers) {
            dispatch(rxGetFourthColumnDone(response));
        }
    }

    async function getFirstColumnV3 (queryData) {
        dispatch(rxGetFirstColumnV3Pending());
        const response = await semanticsOperations.getFirstColumnV3(queryData);
        if (response) {
            dispatch(rxGetFirstColumnV3Done(response));
        }
    }

    async function getSecondColumnV3(queryData) {
        dispatch(rxGetSecondColumnV3Pending());
        const response =  await semanticsOperations.getSecondColumnV3(queryData);
        if(response && response.layers) {
            dispatch(rxGetSecondColumnV3Done(response));
        }
    }

    async function getThirdColumnV3(queryData) {
        dispatch(rxGetThirdColumnV3Pending());
        const response =  await semanticsOperations.getThirdColumnV3(queryData);
        if(response && response.layers) {
            dispatch(rxGetThirdColumnV3Done(response));
        }
    }

    async function getFourthColumnV3(queryData) {
        dispatch(rxGetFourthColumnV3Pending());
        const response =  await semanticsOperations.getFourthColumnV3(queryData);
        if (response && response.layers) {
            dispatch(rxGetFourthColumnV3Done(response));
        }
    }

    async function getChartCompaniesAndLayers(queryData) {
        dispatch(rxGetChartCompaniesPending());
        dispatch(rxGetChartLayersPending());
        const response = await semanticsOperations.getChartCompaniesAndLayers(queryData);
        dispatch(rxGetChartCompaniesDone(response.companies));
        dispatch(rxGetChartLayersDone(response.layers));
    }

    function resetSecondColumnV5() {
        abort(ABORT_API_KEYS.getSecondColumnV5);
        dispatch(rxGetSecondColumnDone());
    }

    function resetThirdColumnV5() {
        abort(ABORT_API_KEYS.getThirdColumnV5);
        dispatch(rxGetThirdColumnDone());
    }

    function resetFourthColumnV5() {
        abort(ABORT_API_KEYS.getFourthColumnV5);
        dispatch(rxGetFourthColumnDone());
    }

    function resetSecondColumnV3() {
        abort(ABORT_API_KEYS.getSecondColumnV3);
        dispatch(rxGetSecondColumnV3Done());
    }

    function resetThirdColumnV3() {
        abort(ABORT_API_KEYS.getThirdColumnV3);
        dispatch(rxGetThirdColumnV3Done());
    }

    function resetFourthColumnV3() {
        abort(ABORT_API_KEYS.getFourthColumnV3);
        dispatch(rxGetFourthColumnV3Done());
    }

    async function getFirstChartAllLayers(queryData) {
        dispatch(rxGetFirstChartAllLayersPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getFirstChartAllLayers);
        const response = await semanticsOperations.getChartData(queryData, abortSignal);
        dispatch(rxGetFirstChartAllLayersDone(response));
    } 
    
    function getFirstChartData(firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company) {
        if(firstChartAllLayers.length && firstLayer) {
            dispatch(rxGetFirstChartDataPending());
            const  data =  firstChartAllLayers.map(item => {
                const array = [new Date(formatDate(item.date)), item.layers[firstLayer] || 0];
                if(secondLayer) {
                    array.push(item.layers[secondLayer] || 0);
                }
                if(thirdLayer) {
                    array.push(item.layers[thirdLayer] || 0);
                }
                if(fourthLayer){
                    array.push(item.layers[fourthLayer] || 0);
                }
                if(fifthLayer){
                    array.push(item.layers[fifthLayer] || 0);
                }
                return array;
            });
            const labels = [firstLayer];
            secondLayer && labels.push(secondLayer);
            thirdLayer && labels.push(thirdLayer);
            fourthLayer && labels.push(fourthLayer);
            fifthLayer && labels.push(fifthLayer);
            dispatch(rxGetFirstChartDataDone({ data, labels, title: company}));
        } else {
            dispatch(rxGetFirstChartDataDone())
        }
    }

    async function getSecondChartAllLayers(queryData) {
        dispatch(rxGetSecondChartAllLayersPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getSecondChartAllLayers);
        const response = await semanticsOperations.getChartData(queryData, abortSignal);
        dispatch(rxGetSecondChartAllLayersDone(response));
    }

    function getSecondChartData(firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company) {
        if (secondChartAllLayers.length && firstLayer) {
            dispatch(rxGetSecondChartDataPending());
            const data = secondChartAllLayers.map(item => {
                const array = [new Date(formatDate(item.date)), item.layers[firstLayer] || 0]
                if (secondLayer) {
                    array.push(item.layers[secondLayer] || 0);
                }
                if (thirdLayer) {
                    array.push(item.layers[thirdLayer] || 0);
                }
                if (fourthLayer) {
                    array.push(item.layers[fourthLayer] || 0);
                }
                if (fifthLayer) {
                    array.push(item.layers[fifthLayer] || 0);
                }
                return array;
            });
            const labels = [firstLayer];
            secondLayer && labels.push(secondLayer);
            thirdLayer && labels.push(thirdLayer);
            fourthLayer && labels.push(fourthLayer);
            fifthLayer && labels.push(fifthLayer);
            dispatch(rxGetSecondChartDataDone({ data, labels, title: company}));
        } else {
            dispatch(rxGetSecondChartDataDone())
        }
    }

    async function getThirdChartAllLayers(queryData) {
        dispatch(rxGetThirdChartAllLayersPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getThirdChartAllLayers);
        const response = await semanticsOperations.getChartData(queryData, abortSignal);
        dispatch(rxGetThirdChartAllLayersDone(response));
    }

    function getThirdChartData(firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company) {
            if(thirdChartAllLayers.length && firstLayer) {
                dispatch(rxGetThirdChartDataPending());
                const  data =  thirdChartAllLayers.map(item => {
                    const array = [new Date(formatDate(item.date)), item.layers[firstLayer] || 0]
                    if(secondLayer) {
                        array.push(item.layers[secondLayer] || 0);
                    }
                    if(thirdLayer) {
                        array.push(item.layers[thirdLayer] || 0);
                    }
                    if(fourthLayer){
                        array.push(item.layers[fourthLayer] || 0);
                    }
                    if(fifthLayer){
                        array.push(item.layers[fifthLayer] || 0);
                    }
                    return array;
                });
                const labels = [firstLayer];
                secondLayer && labels.push(secondLayer);
                thirdLayer && labels.push(thirdLayer);
                fourthLayer && labels.push(fourthLayer);
                fifthLayer && labels.push(fifthLayer);
                dispatch(rxGetThirdChartDataDone({ data, labels, title: company}));
            } else {
                dispatch(rxGetThirdChartDataDone());
            }
        }

    async function getFourthChartAllLayers(queryData) {
        dispatch(rxGetFourthChartAllLayersPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getFourthChartAllLayers);
        const response = await semanticsOperations.getChartData(queryData, abortSignal);
        dispatch(rxGetFourthChartAllLayersDone(response));
    }

    function getFourthChartData(firstLayer, secondLayer, thirdLayer, fourthLayer, fifthLayer, company) {
        if(fourthChartAllLayers.length && firstLayer) {
            dispatch(rxGetThirdChartDataPending());
            const  data =  fourthChartAllLayers.map(item => {
                const array = [new Date(formatDate(item.date)), item.layers[firstLayer] || 0]
                if(secondLayer) {
                    array.push(item.layers[secondLayer] || 0);
                }
                if(thirdLayer) {
                    array.push(item.layers[thirdLayer] || 0);
                }
                if(fourthLayer){
                    array.push(item.layers[fourthLayer] || 0);
                }
                if(fifthLayer){
                    array.push(item.layers[fifthLayer] || 0);
                }
                return array;
            });
            const labels = [firstLayer];
            secondLayer && labels.push(secondLayer);
            thirdLayer && labels.push(thirdLayer);
            fourthLayer && labels.push(fourthLayer);
            fifthLayer && labels.push(fifthLayer);
            dispatch(rxGetFourthChartDataDone({ data, labels, title: company}));
        } else {
            dispatch(rxGetFourthChartDataDone());
        }
    }

    return {
        getFirstColumnV5,
        getSecondColumnV5,
        getThirdColumnV5,
        getFourthColumnV5,
        getFirstColumnV3,
        getSecondColumnV3,
        getThirdColumnV3,
        getFourthColumnV3,
        resetSecondColumnV5,
        resetThirdColumnV5,
        resetFourthColumnV5,
        resetSecondColumnV3,
        resetThirdColumnV3,
        resetFourthColumnV3,
        getFirstChartAllLayers,
        getFirstChartData,
        getSecondChartAllLayers,
        getSecondChartData,
        getThirdChartAllLayers,
        getThirdChartData,
        getChartCompaniesAndLayers,
        getFourthChartAllLayers,
        getFourthChartData,
    }
}

export default UseSemanticsController;