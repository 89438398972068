import Operations from "./operations";
import publisherApiHandler from "../apiHandlers/publisherApiHandler";

class PublisherOperations extends Operations {
    constructor(publisherApiHandler) {
        super();
        this.publisherApiHandler = publisherApiHandler;
    }

    async getAndSortPublisherOccurrences(queryData, abortSignal) {
        const { startDate, endDate } = queryData;

        const response =  await this.publisherApiHandler.getPublisherOccurrences(queryData, abortSignal);
        if(response && response.publishers){
            const layers = Object.keys(response.publishers).map (item => ({
                name: item,
                count: response.publishers[item]
            }));
            const sortedLayers = [...layers.sort((a, b)=> b.count - a.count)];
            return {...response, layers: sortedLayers, startDate, endDate }
        }
    }

    async getPublisherOccurrences(queryData, abortSignal) {
        const response =  await this.publisherApiHandler.getPublisherOccurrences(queryData, abortSignal);
        const { coverage, company } = queryData;
        if(response && response.publishers) {
            return {...response, layers: response.publishers, coverage, company };
        }
    }
}

const publisherOperations = new PublisherOperations(publisherApiHandler);

export default publisherOperations;