import publisherOperations from "../../services/operations/pulbisherOperations";
import {useDispatch} from "react-redux";

import {
    rxGetPublishersFirstColumnDone,
    rxGetPublishersFirstColumnPending,
    rxGetPublishersFourthColumnDone,
    rxGetPublishersFourthColumnPending,
    rxGetPublishersSecondColumnDone,
    rxGetPublishersSecondColumnPending,
    rxGetPublishersThirdColumnDone,
    rxGetPublishersThirdColumnPending
} from "../../state-management/actions/publisherActions";
import {abort, ABORT_API_KEYS, abortFetch} from "../../services/operations/abortFetch";


function UsePublisherController() {
    const dispatch = useDispatch();

    async function getPublishersFirstColumn(queryData) {
        dispatch(rxGetPublishersFirstColumnPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublishersFirstColumn);
        const response =  await publisherOperations.getAndSortPublisherOccurrences(queryData, abortSignal);
        if (response) {
            dispatch(rxGetPublishersFirstColumnDone(response));
        }
    }

    async function getPublishersSecondColumn(queryData) {
        dispatch(rxGetPublishersSecondColumnPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublishersSecondColumn);
        const response =  await publisherOperations.getPublisherOccurrences(queryData, abortSignal);
        if (response) {
            dispatch(rxGetPublishersSecondColumnDone(response));
        }
    }

    async function getPublishersThirdColumn(queryData) {
        dispatch(rxGetPublishersThirdColumnPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublishersThirdColumn);
        const response =  await publisherOperations.getPublisherOccurrences(queryData, abortSignal);
        if (response) {
            dispatch(rxGetPublishersThirdColumnDone(response));
        }
    }

    async function getPublishersFourthColumn(queryData) {
        dispatch(rxGetPublishersFourthColumnPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublishersFourthColumn);
        const response =  await publisherOperations.getPublisherOccurrences(queryData, abortSignal);
        if (response) {
            dispatch(rxGetPublishersFourthColumnDone(response));
        }
    }

    function resetPublishersSecondColumn() {
        abort(ABORT_API_KEYS.getPublishersSecondColumn);
        dispatch(rxGetPublishersSecondColumnDone());
    }

    function resetPublishersThirdColumn() {
        abort(ABORT_API_KEYS.getPublishersThirdColumn);
        dispatch(rxGetPublishersThirdColumnDone());
    }

    function resetPublishersFourthColumn() {
        abort(ABORT_API_KEYS.getPublishersFourthColumn);
        dispatch(rxGetPublishersFourthColumnDone());
    }


    return {
        getPublishersFirstColumn,
        getPublishersSecondColumn,
        getPublishersThirdColumn,
        getPublishersFourthColumn,
        resetPublishersSecondColumn,
        resetPublishersThirdColumn,
        resetPublishersFourthColumn,
    }
}

export default UsePublisherController;