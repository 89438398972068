import {useDispatch} from "react-redux";
import {
    rxGetChartCompaniesDone,
    rxGetChartLayersDone,
    rxGetFirstChartAllLayersDone,
    rxGetFirstChartDataDone,
    rxGetFirstColumnDone,
    rxGetFourthColumnDone,
    rxGetSecondChartAllLayersDone,
    rxGetSecondChartDataDone,
    rxGetSecondColumnDone,
    rxGetThirdChartAllLayersDone,
    rxGetThirdChartDataDone,
    rxGetThirdColumnDone
} from "../../state-management/actions/semanticsActions";
import {
    rxGetAllPlansDone,
    rxGetAllPlansPending, rxGetCompanyDataDone, rxGetCompanyDataPending,
    rxGetCompanyInfoDone,
    rxGetCompanyInfoPending,
    rxGetLoginInfoDone,
    rxGetLoginInfoPending,
    rxGetUserInfoDone,
    rxGetUserInfoPending
} from '../../state-management/actions/userActions';
import loginOperations from "../../services/operations/loginOperations";
import {rxGetHiddenLayersDone, rxGetHiddenPublishersDone} from "../../state-management/actions/projectActions";
import {
    rxSaveChartSettings,
    rxSaveDashboardSettings,
    rxSaveHeatmapSettings,
    rxSavePublisherHeatmapSettings
} from "../../state-management/actions/savedSettingsActions";
import {
    rxGetPublisherFilterFirstColCoverageDone,
    rxGetPublisherFilterFourthColCompaniesDone,
    rxGetPublisherFilterFourthColCoverageDone,
    rxGetPublisherFilterSecondColCompaniesDone,
    rxGetPublisherFilterSecondColCoverageDone,
    rxGetPublisherFilterThirdColCompaniesDone,
    rxGetPublisherFilterThirdColCoverageDone,
    rxGetPublishersFirstColumnDone,
    rxGetPublishersFourthColumnDone,
    rxGetPublishersSecondColumnDone,
    rxGetPublishersThirdColumnDone
} from "../../state-management/actions/publisherActions";

function UseLoginController() {
    const dispatch = useDispatch();
    const timer = 14.5 * 60 * 1000;
    // const timer = 7000;

    async function login(payload) {
        dispatch(rxGetLoginInfoPending());
        const response = await loginOperations.login(payload);
        const data = response && response.accessToken ? {
            token: !!response.accessToken,
            error: false,
            email: payload.email,
            }: {
            token: false,
            error: true,
            email: ''
        };
        if(response && response.accessToken) {
            await getUserInfo({email: payload.email});
            dispatch(rxGetLoginInfoDone(data));
            setTimeout(() => refresh(payload.email), timer);
        } else {
            dispatch(rxGetLoginInfoDone(data));
        }

    }

    async function refresh(email) {
        const response = await loginOperations.refresh(email);
        if(response && response.accessToken){
            setTimeout(() =>refresh(email), timer);
        } else {
            logout();
        }
    }

    async function loginWithRefresh(email) {
        const response = await loginOperations.refresh(email);
        if(response && response.accessToken){
            const data = {
                    token: !!response.accessToken,
                    error: false,
                    email,
                }
            await getUserInfo({ email });
            dispatch(rxGetLoginInfoDone(data));
            setTimeout(() =>refresh(email), timer);
        }
    }


    async function getUserInfo(queryData) {
        dispatch(rxGetUserInfoPending());
        const response = await loginOperations.getUserInfo(queryData);
        if (response instanceof Error) {
            dispatch(rxGetUserInfoDone());
        } else {
            dispatch(rxGetUserInfoDone(response));
        }
    }

    async function getCompanyInfo(queryData) {
        dispatch(rxGetCompanyInfoPending());
        const response = await loginOperations.getCompanyInfo(queryData);
        dispatch(rxGetCompanyInfoDone(response));
    }

    async function uploadCompanyImage(payload) {
        return await loginOperations.uploadCompanyImage(payload);
    }

    async function getAllPlans() {
        dispatch(rxGetAllPlansPending());
        const response = await loginOperations.getAllPlans();
        dispatch(rxGetAllPlansDone(response));
    }

    async function getCompanyData(queryData) {
        dispatch(rxGetCompanyDataPending());
        const response = await loginOperations.getCompanyData(queryData);
        dispatch(rxGetCompanyDataDone(response));
    }

    async function checkWarnings(queryData) {
        return await loginOperations.checkWarnings(queryData);
    }

    async function updatePlan(queryData) {
        return await loginOperations.updatePlan(queryData);
    }

    async function cancelPlan(queryData) {
        return await loginOperations.cancelPlan(queryData);
    }

    async function verifyAccount(queryData) {
        const response =  await loginOperations.verifyAccount(queryData);
        console.log({response});
        return response;
    }



    function logout() {
        loginOperations.logout();
        dispatch(rxGetUserInfoDone());
        dispatch(rxGetFirstColumnDone());
        dispatch(rxGetSecondColumnDone());
        dispatch(rxGetThirdColumnDone());
        dispatch(rxGetFourthColumnDone());
        dispatch(rxGetHiddenLayersDone());
        dispatch(rxGetHiddenPublishersDone());
        dispatch(rxSaveHeatmapSettings({}));
        dispatch(rxSaveDashboardSettings({}));
        dispatch(rxSaveChartSettings({}));
        dispatch(rxSavePublisherHeatmapSettings({}));
        dispatch(rxGetLoginInfoDone());
        dispatch(rxGetUserInfoDone());
        dispatch(rxGetChartLayersDone());
        dispatch(rxGetChartCompaniesDone());
        dispatch(rxGetPublisherFilterFirstColCoverageDone());
        dispatch(rxGetPublisherFilterSecondColCoverageDone());
        dispatch(rxGetPublisherFilterThirdColCoverageDone())
        dispatch(rxGetPublisherFilterFourthColCoverageDone());
        dispatch(rxGetPublisherFilterSecondColCompaniesDone());
        dispatch(rxGetPublisherFilterThirdColCompaniesDone());
        dispatch(rxGetPublisherFilterFourthColCompaniesDone());
        dispatch(rxGetPublishersFirstColumnDone());
        dispatch(rxGetPublishersSecondColumnDone());
        dispatch(rxGetPublishersThirdColumnDone());
        dispatch(rxGetPublishersFourthColumnDone());
        dispatch(rxGetFirstChartAllLayersDone());
        dispatch(rxGetFirstChartDataDone());
        dispatch(rxGetSecondChartAllLayersDone());
        dispatch(rxGetSecondChartDataDone());
        dispatch(rxGetThirdChartAllLayersDone());
        dispatch(rxGetThirdChartDataDone());
        dispatch(rxGetCompanyInfoDone());
        dispatch(rxGetCompanyDataDone());
    }

    return {
        login,
        logout,
        getUserInfo,
        loginWithRefresh,
        getCompanyInfo,
        getAllPlans,
        getCompanyData,
        checkWarnings,
        updatePlan,
        cancelPlan,
        uploadCompanyImage,
        verifyAccount
    }
}

export default UseLoginController;
