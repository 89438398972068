import React, {useEffect, useRef, useState} from "react";

function AccountPageDropdown({ values, value, setValue }) {
    const [ open, setOpen ] = useState(false);
    const ref = useRef(null);

    const handleClickOutside = e => {
        if(ref.current && !ref.current.contains(e.target)){
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const handleClick = value => {
        setValue({...value});
        setOpen(false);
    }

    return (
        <div className={`project-dropdown ${open? 'open': ''}`} ref={ref}>
            <div className="project-dropdown-input"  onClick={() => setOpen(open => !open)}>{value.name}</div>
            <div className="project-dropdown-content">
                {
                    values.map(item => {
                        return (
                            <div className={`project-dropdown-item ${value.name === item.name? 'active': ''}`}
                                 key={item.key}
                                 onClick={()=>handleClick(item)}
                            >
                                {item.name}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AccountPageDropdown;