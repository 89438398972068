import Fetch from "../fetch";
import {ABORT_API_KEYS, abortFetch} from "../operations/abortFetch";

const END_POINT = {
    prefix: 'semantics',
    locations: 'locations',
    publishers: 'publishers',
    companies: 'companies',
    weekly: 'weekly',
};

class SemanticsApiHandler {

    async getFirstColumnV5(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getFirstColumnV5);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal, 'v5');
    }

    async getSecondColumnV5(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getSecondColumnV5);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal, 'v5');
    }

    async getThirdColumnV5(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getThirdColumnV5);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal, 'v5');
    }

    async getFourthColumnV5(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getFourthColumnV5);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal, 'v5');
    }

    async getFirstColumnV3(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getFirstColumnV3);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal, 'v2');
    }

    async getSecondColumnV3(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getSecondColumnV3);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal, 'v2');
    }

    async getThirdColumnV3(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getThirdColumnV3);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal, 'v2');
    }

    async getFourthColumnV3(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getFourthColumnV3);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal, 'v2');
    }

    async getChartCompaniesAndLayers(queryData) {
        const abortSignal = abortFetch(ABORT_API_KEYS.getChartCompanies);
        return await Fetch.get(END_POINT.prefix, queryData, abortSignal, 'v2');
    }

    async getChartData(queryData, abortSignal) {
        const relativePath = `${END_POINT.prefix}/${END_POINT.weekly}`
        return await Fetch.get(relativePath, queryData, abortSignal);
    }
}

const semanticsApiHandler = new SemanticsApiHandler()

export default semanticsApiHandler;
