import React from "react";

const getPercentage = (count, baseNumber) => {
    if (count === '') {
        return '0';
    }
    if (count === 0) {
        return '< 5 %';
    }
    if (count && baseNumber) {
        const percentage = Math.round(count / baseNumber * 100);
        if (percentage < 5) {
            return '< 5 %';
        }
        return `${percentage} %`;
    }
}

function TableFirstRow({
                           firstColumnCount,
                           secondColumnCount,
                           thirdColumnCount,
                           fourthColumnCount
                       }) {

    const secondColumnPercentage = getPercentage(secondColumnCount, firstColumnCount);

    const thirdColumnPercentage = getPercentage(thirdColumnCount, firstColumnCount);

    const fourthColumnPercentage = getPercentage(fourthColumnCount, firstColumnCount);

    return (
        <div className="outer-table-first-row">
            <div className="outer-table-first-row-item total-count">
            </div>
            <div className="first-row-blank-section" >
                Market Total:
            </div>
            <div className="outer-table-first-row-item count-percent">
                <span className="count">{firstColumnCount || 0}</span>
                <span className="percent">{firstColumnCount ? "100 %" : ""}</span>
            </div>
            <div className="outer-table-first-row-item count-percent">
                <span className="count">{secondColumnCount || 0}</span>
                <span className="percent">{secondColumnPercentage}</span>
            </div>
            <div className="outer-table-first-row-item count-percent">
                <span className="count">{thirdColumnCount || 0}</span>
                <span className="percent">{thirdColumnPercentage}</span>
            </div>
            <div className="outer-table-first-row-item count-percent">
                <span className="count">{fourthColumnCount || 0}</span>
                <span className="percent">{fourthColumnPercentage}</span>
            </div>
        </div>

    );
}

export default TableFirstRow;