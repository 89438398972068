import companyOperations from "../../services/operations/companyOperations";
import {useDispatch} from "react-redux";
import {
    rxGetPublisherFilterFourthColCompaniesDone,
    rxGetPublisherFilterFourthColCompaniesPending,
    rxGetPublisherFilterSecondColCompaniesDone,
    rxGetPublisherFilterSecondColCompaniesPending,
    rxGetPublisherFilterThirdColCompaniesDone,
    rxGetPublisherFilterThirdColCompaniesPending
} from "../../state-management/actions/publisherActions";
import {ABORT_API_KEYS, abortFetch} from "../../services/operations/abortFetch";

function UseCompanyController() {
    const dispatch = useDispatch();

    async function getPublisherFilterAllColumnCompanies(queryData) {
        dispatch(rxGetPublisherFilterSecondColCompaniesPending());
        dispatch(rxGetPublisherFilterThirdColCompaniesPending());
        dispatch(rxGetPublisherFilterFourthColCompaniesPending());
        const response = await companyOperations.getPublisherFilterCompanies(queryData);
        dispatch(rxGetPublisherFilterSecondColCompaniesDone(response));
        dispatch(rxGetPublisherFilterThirdColCompaniesDone(response));
        dispatch(rxGetPublisherFilterFourthColCompaniesDone(response));

    }

    async function getPublisherFilterSecondColCompanies(queryData) {
        dispatch(rxGetPublisherFilterSecondColCompaniesPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublisherFilterSecondColCompanies);
        const response = await companyOperations.getPublisherFilterCompanies(queryData, abortSignal);
        dispatch(rxGetPublisherFilterSecondColCompaniesDone(response));
    }

    async function getPublisherFilterThirdColCompanies(queryData) {
        dispatch(rxGetPublisherFilterThirdColCompaniesPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublisherFilterThirdColCompanies);
        const response = await companyOperations.getPublisherFilterCompanies(queryData, abortSignal);
        dispatch(rxGetPublisherFilterThirdColCompaniesDone(response));
    }

    async function getPublisherFilterFourthColCompanies(queryData) {
        dispatch(rxGetPublisherFilterFourthColCompaniesPending());
        const abortSignal = abortFetch(ABORT_API_KEYS.getPublisherFilterFourthColCompanies);
        const response = await companyOperations.getPublisherFilterCompanies(queryData, abortSignal);
        dispatch(rxGetPublisherFilterFourthColCompaniesDone(response));
    }

    return {
        getPublisherFilterAllColumnCompanies,
        getPublisherFilterSecondColCompanies,
        getPublisherFilterThirdColCompanies,
        getPublisherFilterFourthColCompanies,
    }
}

export default UseCompanyController;