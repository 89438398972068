import {API_VERSIONS} from "../core/constants/apiVersions";
import {CONTENT_TYPES} from "../core/constants/contentTypes";
import {createQuery} from "../core/helpers/createQuery";
import {HTTP_METHODS} from "../core/constants/httpMethods";
import buildHeaders from "../core/helpers/buildHeaders";
import { DOMAIN, LOGIN_URL, API_URL, GRAPH_URL } from "../core/environments";

export const errorHandler = response => {
    //handling unauthorized, forbidden, not-found ,  maintenance and backend errors
    if (!response.ok) {

        return new Error(`${response.status}`);

    }


    return response;
};

class Fetch {
    static token = '';

    static get(url, queryData, signal, apiVersion= API_VERSIONS.V1, contentType = CONTENT_TYPES.applicationJson) {
        return this.request(`${apiVersion}/${url}${queryData ? createQuery(queryData): ''}`, HTTP_METHODS.GET, null, contentType, signal)
            .catch(()=>{});
    }


    static post(url, data, apiVersion = API_VERSIONS.V1, contentType =  CONTENT_TYPES.applicationJson, signal) {
        return this.request(`${apiVersion}/${url}`, HTTP_METHODS.POST, data, contentType, signal);
    }

    static put(url, data, apiVersion = API_VERSIONS.V1, contentType = CONTENT_TYPES.applicationJson, signal) {
        return this.request(`${apiVersion}/${url}`, HTTP_METHODS.PUT, data, contentType, signal);
    }

    static delete(url, data, apiVersion = API_VERSIONS.V1, contentType = CONTENT_TYPES.applicationJson, signal) {
        return this.request (`${apiVersion}/${url}`, HTTP_METHODS.DELETE, data, contentType, signal);
    }


    static async request(url, method, data, contentType, signal) {
        const headers = buildHeaders(contentType, true, this.token);
        const body = method !== HTTP_METHODS.GET ? contentType === CONTENT_TYPES.applicationJson ? JSON.stringify(data) : data : null;

        const response = await this.uniqueRequest(url, method, headers, body, signal);
        const result = await response.clone().json();

        return result;
    }

    static async uniqueRequest(url, method, headers, body, signal) {
        //this method will be used to make requests unique
        const response = await fetch(`${DOMAIN}/${url}`, {
            method,
            headers,
            body,
            signal,
            // credentials: INCLUDE_CREDENTIALS
        });
        return errorHandler(response);
    }

    static userPost(url, data, contentType =  CONTENT_TYPES.applicationJson, signal) {
        return this.userRequest(url, HTTP_METHODS.POST, data, contentType, signal).catch(() => {
        })
    }

    static userPut(url, data, contentType = CONTENT_TYPES.applicationJson, signal) {
        return this.userRequest(url, HTTP_METHODS.PUT, data, contentType, signal).catch(() => {});
    }

    static userGet(url, queryData, contentType = CONTENT_TYPES.applicationJson, signal) {
        return this.userRequest(`${url}${queryData ? createQuery(queryData): ''}`, HTTP_METHODS.GET, null, contentType, signal)
            .catch(()=>{});
    }

    static async userRequest(url, method, data, contentType, signal) {
        const headers = buildHeaders(contentType, true, this.token);
        const body = method !== HTTP_METHODS.GET ? contentType === CONTENT_TYPES.applicationJson ? JSON.stringify(data) : data : null;

        const response = await this.userUniqueRequest(url, method, headers, body, signal);
        if(response instanceof Error){
            return response
        }
        const result = await response.clone().json();

        return result;
    }

    static async userUniqueRequest(url, method, headers, body, signal) {
        //this method will be used to make requests unique
        const response = await fetch(`${LOGIN_URL}/${url}`, {
            method,
            headers,
            body,
            signal,
            // credentials: INCLUDE_CREDENTIALS
        });
        return errorHandler(response);
    }

    static apiGet(url, queryData, contentType = CONTENT_TYPES.applicationJson, signal) {
        return this.apiRequest(`${url}${queryData ? createQuery(queryData): ''}`, HTTP_METHODS.GET, null, contentType, signal)
            .catch(()=>{});
    }

    static apiPost(url, data, contentType =  CONTENT_TYPES.applicationJson, signal) {
        return this.apiRequest(url, HTTP_METHODS.POST, data, contentType, signal).catch(() => {
        })
    }

    static async apiRequest(url, method, data, contentType, signal) {
        const headers = buildHeaders(contentType, true, this.token, {
            'X-EVAI-API-Application-ID' : 'evAI',
            'X-EVAI-API-Application-Key' :  'b298275b-bd28-4089-bfe3-f6644a27707c'
        });
        const body = method !== HTTP_METHODS.GET ? contentType === CONTENT_TYPES.applicationJson ? JSON.stringify(data) : data : null;

        const response = await this.apiUniqueRequest(url, method, headers, body, signal);
        if(response instanceof Error){
            return response
        }
        const result = await response.clone().json();

        return result;
    }

    static async apiUniqueRequest(url, method, headers, body, signal) {
        //this method will be used to make requests unique
        const response = await fetch(`${API_URL}/${url}`, {
            method,
            headers,
            body,
            signal,
            // credentials: INCLUDE_CREDENTIALS
        });
        return errorHandler(response);
    }

    //graph Requests


    static graphGet(url, queryData, contentType = CONTENT_TYPES.applicationJson, signal) {
        return this.graphRequest(`${url}${queryData ? createQuery(queryData): ''}`, HTTP_METHODS.GET, null, contentType, signal)
            .catch(()=>{});
    }


    static graphPost(url, data,  signal, contentType =  CONTENT_TYPES.applicationJson) {
        return this.graphRequest(`${url}`, HTTP_METHODS.POST, data, contentType, signal)
            .catch(()=>{});
    }

    static async graphRequest(url, method, data, contentType, signal) {
        const headers = buildHeaders(contentType, true, this.token);
        const body = method !== HTTP_METHODS.GET ? contentType === CONTENT_TYPES.applicationJson ? JSON.stringify(data) : data : null;

        const response = await this.graphUniqueRequest(url, method, headers, body, signal);
        const result = await response.clone().json();

        return result;
    }

    static async graphUniqueRequest(url, method, headers, body, signal) {
        //this method will be used to make requests unique
        const response = await fetch(`${GRAPH_URL}/${url}`, {
            method,
            headers,
            body,
            signal,
            // credentials: INCLUDE_CREDENTIALS
        });
        return errorHandler(response);
    }





}

export default Fetch;