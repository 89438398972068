import Operations from "./operations";

import projectApiHandler from "../apiHandlers/projectApiHandler";

class ProjectOperations extends Operations {
    constructor(projectApiHandler) {
        super();
        this.projectApiHandler = projectApiHandler;
    }

    async getHiddenLayers(queryData) {
        const response =  await this.projectApiHandler.getHiddenLayers(queryData);
        if(response && response.hiddenLayers){
            const hiddenLayers = {};
            response.hiddenLayers.forEach(layer => hiddenLayers[layer] = true);
            return {name: response.name, layers: {...hiddenLayers}};
        }
    }

    async getHiddenPublishers(queryData) {
        const response =  await this.projectApiHandler.getHiddenLayers(queryData);
        if(response && response.hiddenPublishers){
            const hiddenPublishers = {};
            response.hiddenPublishers.forEach(publisher => hiddenPublishers[publisher] = true);
            return {name: response.name, publishers: {...hiddenPublishers}};
        }
    }

    async getProjectDetails(queryData) {
        const response = await this.projectApiHandler.getHiddenLayers(queryData);
        if(response?.searchTerms){
            return Object.keys(response.searchTerms).map(term => {
                return {
                    term,
                    site: [...response.searchTerms[term]],
                }
            });
        }
    }

    async changeHiddenLayers(payload){
        return await this.projectApiHandler.changeHiddenLayers(payload);
    }

    async changeHiddenPublishers(payload) {
        return await this.projectApiHandler.changeHiddenPublishers(payload);
    }

    async requestProject(payload) {
        return await this.projectApiHandler.requestProject(payload);
    }

}

const projectOperations= new ProjectOperations(projectApiHandler);

export default projectOperations;