import { useDispatch } from "react-redux";
import graphOperations from "../../services/operations/graphOperations";
import {
    rxGetEntityDetailsDone,
    rxGetEntityDetailsPending,
    rxGetFiltersDone,
    rxGetFiltersPending,
    rxGetNetworkDone,
    rxGetNetworkPending
} from "../../state-management/actions/graphActions";

function useGraphController() {
    const dispatch = useDispatch();

    async function getFilters(queryData) {
        dispatch(rxGetFiltersPending());
        const response = await graphOperations.getFilters(queryData);
        dispatch(rxGetFiltersDone(response));
    }

    async function getNetwork(payload) {
        dispatch(rxGetNetworkPending());
        const response = await graphOperations.getNetwork(payload);
        if(response){
            dispatch(rxGetNetworkDone(response));
        } else {
            dispatch(rxGetNetworkDone({project: payload.project, entities: [], relationships: []}));
        }

    }

    async function getEntityDetails(payload) {
        dispatch(rxGetEntityDetailsPending());
        const response = await graphOperations.getEntityDetails(payload);
        if(response){
            dispatch(rxGetEntityDetailsDone({...response, name: payload.entity}))
        }
    }

    return {
        getFilters,
        getNetwork,
        getEntityDetails,
    }
}

export default useGraphController;