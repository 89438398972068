import React from "react";

function ColorIndicatorSection() {
    return (
        <div className="color-indicator-section">
            <div className="color-indicator-column">
                <div className="color-indicator-block color-scheme-10" />
                <div className="color-indicator-block color-scheme-9" />
                <div className="color-indicator-block color-scheme-8" />
                <div className="color-indicator-block color-scheme-7" />
                <div className="color-indicator-block color-scheme-6" />
                <div className="color-indicator-block color-scheme-5" />
                <div className="color-indicator-block color-scheme-4" />
                <div className="color-indicator-block color-scheme-3" />
                <div className="color-indicator-block color-scheme-2" />
            </div>
            <div className="color-indicator-percent-column">
                <div className="color-indicator-percent-block">
                    100%
                </div>
                <div className="color-indicator-percent-block">
                    90%
                </div>
                <div className="color-indicator-percent-block">
                    80%
                </div>
                <div className="color-indicator-percent-block">
                    70%
                </div>
                <div className="color-indicator-percent-block">
                    60%
                </div>
                <div className="color-indicator-percent-block">
                    50%
                </div>
                <div className="color-indicator-percent-block">
                    40%
                </div>
                <div className="color-indicator-percent-block">
                    30%
                </div>
                <div className="color-indicator-percent-block">
                    20%
                </div>
                <div className="color-indicator-percent-block light">
                    &lt;20%
                </div>
            </div>
            <div className="color-indicator-markers">
                <div className="color-indicator-tooltip">
                    Number/shares of articles containing the subject
                </div>
                <div className="color-scheme">
                    color scheme
                </div>

                <div className="color-scheme threshold">
                    threshold
                </div>
            </div>
        </div>
    );
}

export default ColorIndicatorSection;