const DONE = 'DONE';
const PENDING = 'PENDING';

const createAsyncReducer = (prefix, initialState) => (state = { isLoading: false, data: initialState }, action) => {
    const { type } = action;
    switch ( type ) {
        case `${prefix}_${PENDING}`:
            return {
                data: state.data,
                isLoading: true,
            };

        case `${prefix}_${DONE}`:
            return {
                ...state,
                data: action.payload || initialState,
                isLoading: false,
            };

        default:
            return state
    }
};

export default createAsyncReducer;
