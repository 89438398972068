import React, {useState, useRef, useEffect} from "react";
import searchIcon from "../../../images/searchIcon.svg";
import SubjectDropdownItem from "./subjectDropdownItem";
import {useSelector} from "react-redux";


function SubjectSelect({ subjectSelectedValue, setSubjectSelectedValue }) {
    const [ showDropdown, setShowDropdown ] = useState(false);
    const projects = useSelector(state => state.userInfo.data.projects);

    const ref = useRef(null);

    const handleClickOutside = e => {
        if(ref.current && !ref.current.contains(e.target)){
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const toggleDropdown = () => {
        if(showDropdown){
            setShowDropdown(false);

        } else {
            setShowDropdown(true);
        }
    };

    const handleRowClick = value => {
        if(value !== subjectSelectedValue) {
            setSubjectSelectedValue(value);
        }
        setShowDropdown(false);
    }
    return(
        <div className={`subject-left-side ${showDropdown? 'open': ''}`} ref={ref}>
            <span className="subject" onClick={()=>setShowDropdown(false)}>Project</span>
            <div className="icon-container search-icon" onClick={toggleDropdown}>
                <img src={searchIcon} alt="" />
            </div>
            <div onClick={toggleDropdown} className="subject-title">
                <div className="search-name title">{subjectSelectedValue}</div>
                {/*<div className="saved-search">Saved search #1 | 02/05/2020</div>*/}
            </div>
            <div className="subject-dropdown">
                <div className="subject-dropdown-scroll">
                    {projects.map(project => <SubjectDropdownItem project={project}
                                                                  key={project}
                                                                  handleClick={()=>handleRowClick(project)}
                                                                  selectedProject={subjectSelectedValue}/> )}
                </div>

            </div>
        </div>
    );
}

export default SubjectSelect;