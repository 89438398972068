import React from 'react';

function SearchBar(props) {
    return (
        <div className='search-bar' >
            <span>search</span>
        </div>
    );
}

export default SearchBar;