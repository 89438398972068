import {SEMANTICS_TYPES} from "../actionTypes/semanticsActionTypes";

export const rxGetChartCompaniesPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_CHART_COMPANIES_PENDING,
    payload,
});

export const rxGetChartCompaniesDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_CHART_COMPANIES_DONE,
    payload,
})

export const rxGetFirstColumnPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FIRST_COLUMN_PENDING,
    payload,
});

export const rxGetFirstColumnDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FIRST_COLUMN_DONE,
    payload,
});

export const rxGetSecondColumnPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_SECOND_COLUMN_PENDING,
    payload,
});

export const rxGetSecondColumnDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_SECOND_COLUMN_DONE,
    payload,
});

export const rxGetThirdColumnPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_THIRD_COLUMN_PENDING,
    payload,
});

export const rxGetThirdColumnDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_THIRD_COLUMN_DONE,
    payload,
});

export const rxGetFourthColumnPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FOURTH_COLUMN_PENDING,
    payload,
});

export const rxGetFourthColumnDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FOURTH_COLUMN_DONE,
    payload,
});

export const rxGetFirstColumnV3Pending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FIRST_COLUMN_V3_PENDING,
    payload,
});


export const rxGetFirstColumnV3Done = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FIRST_COLUMN_V3_DONE,
    payload,
});

export const rxGetSecondColumnV3Pending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_SECOND_COLUMN_V3_PENDING,
    payload,
});

export const rxGetSecondColumnV3Done = payload => ({
    type: SEMANTICS_TYPES.RX_GET_SECOND_COLUMN_V3_DONE,
    payload,
});

export const rxGetThirdColumnV3Pending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_THIRD_COLUMN_V3_PENDING,
    payload,
});

export const rxGetThirdColumnV3Done = payload => ({
    type: SEMANTICS_TYPES.RX_GET_THIRD_COLUMN_V3_DONE,
    payload,
});

export const rxGetFourthColumnV3Pending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FOURTH_COLUMN_V3_PENDING,
    payload,
});

export const rxGetFourthColumnV3Done = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FOURTH_COLUMN_V3_DONE,
    payload,
});

export const rxGetChartLayersPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_CHART_LAYERS_PENDING,
    payload,
});

export const rxGetChartLayersDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_CHART_LAYERS_DONE,
    payload,
});

export const rxGetFirstChartAllLayersPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FIRST_CHART_ALL_LAYERS_PENDING,
    payload,
});
export const rxGetFirstChartAllLayersDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FIRST_CHART_ALL_LAYERS_DONE,
    payload,
});

export const rxGetSecondChartAllLayersPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_SECOND_CHART_ALL_LAYERS_PENDING,
    payload,
});
export const rxGetSecondChartAllLayersDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_SECOND_CHART_ALL_LAYERS_DONE,
    payload,
});

export const rxGetThirdChartAllLayersPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_THIRD_CHART_ALL_LAYERS_PENDING,
    payload,
});
export const rxGetThirdChartAllLayersDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_THIRD_CHART_ALL_LAYERS_DONE,
    payload,
});

export const rxGetFourthChartAllLayersPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FOURTH_CHART_ALL_LAYERS_PENDING,
    payload,
});
export const rxGetFourthChartAllLayersDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FOURTH_CHART_ALL_LAYERS_DONE,
    payload,
});

export const rxGetFirstChartDataPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FIRST_CHART_DATA_PENDING,
    payload,
});

export const rxGetFirstChartDataDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FIRST_CHART_DATA_DONE,
    payload,
});

export const rxGetSecondChartDataPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_SECOND_CHART_DATA_PENDING,
    payload,
});

export const rxGetSecondChartDataDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_SECOND_CHART_DATA_DONE,
    payload,
});

export const rxGetThirdChartDataPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_THIRD_CHART_DATA_PENDING,
    payload,
});

export const rxGetThirdChartDataDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_THIRD_CHART_DATA_DONE,
    payload,
});

export const rxGetFourthChartDataPending = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FOURTH_CHART_DATA_PENDING,
    payload,
});

export const rxGetFourthChartDataDone = payload => ({
    type: SEMANTICS_TYPES.RX_GET_FOURTH_CHART_DATA_DONE,
    payload,
});




