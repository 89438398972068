import Operations from "./operations";
import graphApiHandler from "../apiHandlers/graphApiHandler";

class GraphOperations extends Operations {
    constructor(graphApiHandler) {
        super();
        this.graphApiHandler = graphApiHandler;
    }

    async getFilters (queryData) {
        return await this.graphApiHandler.getFilters(queryData);
    }

    async getNetwork (payload) {
        return await this.graphApiHandler.getNetwork(payload);
    }

    async getEntityDetails (payload) {
        return await this.graphApiHandler.getEntityDetails(payload);
    }
}

const graphOperations = new GraphOperations(graphApiHandler);

export default graphOperations;
