import {PUBLISHER_TYPES} from "../actionTypes/publisherActionTypes";

export const rxGetPublishersFirstColumnPending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHERS_FIRST_COLUMN_PENDING,
    payload,
});

export const rxGetPublishersFirstColumnDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHERS_FIRST_COLUMN_DONE,
    payload,
});

export const rxGetPublishersSecondColumnPending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHERS_SECOND_COLUMN_PENDING,
    payload,
});

export const rxGetPublishersSecondColumnDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHERS_SECOND_COLUMN_DONE,
    payload,
});

export const rxGetPublishersThirdColumnPending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHERS_THIRD_COLUMN_PENDING,
    payload,
});

export const rxGetPublishersThirdColumnDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHERS_THIRD_COLUMN_DONE,
    payload,
});

export const rxGetPublishersFourthColumnPending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHERS_FOURTH_COLUMN_PENDING,
    payload,
});

export const rxGetPublishersFourthColumnDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHERS_FOURTH_COLUMN_DONE,
    payload,
});

export const rxGetPublisherFilterFirstColCoveragePending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_FIRST_COL_COVERAGE_PENDING,
    payload,
});

export const rxGetPublisherFilterFirstColCoverageDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_FIRST_COL_COVERAGE_DONE,
    payload,
});


export const rxGetPublisherFilterSecondColCoveragePending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_SECOND_COL_COVERAGE_PENDING,
    payload,
});

export const rxGetPublisherFilterSecondColCoverageDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_SECOND_COL_COVERAGE_DONE,
    payload,
});

export const rxGetPublisherFilterThirdColCoveragePending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_THIRD_COL_COVERAGE_PENDING,
    payload,
});

export const rxGetPublisherFilterThirdColCoverageDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_THIRD_COL_COVERAGE_DONE,
    payload,
});

export const rxGetPublisherFilterFourthColCoveragePending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_FOURTH_COL_COVERAGE_PENDING,
    payload,
});

export const rxGetPublisherFilterFourthColCoverageDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_FOURTH_COL_COVERAGE_DONE,
    payload,
});


export const rxGetPublisherFilterSecondColCompaniesPending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_SECOND_COL_COMPANIES_PENDING,
    payload,
});

export const rxGetPublisherFilterSecondColCompaniesDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_SECOND_COL_COMPANIES_DONE,
    payload,
});

export const rxGetPublisherFilterThirdColCompaniesPending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_THIRD_COL_COMPANIES_PENDING,
    payload,
});

export const rxGetPublisherFilterThirdColCompaniesDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_THIRD_COL_COMPANIES_DONE,
    payload,
});

export const rxGetPublisherFilterFourthColCompaniesPending = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_FOURTH_COL_COMPANIES_PENDING,
    payload,
});

export const rxGetPublisherFilterFourthColCompaniesDone = payload => ({
    type: PUBLISHER_TYPES.RX_GET_PUBLISHER_FILTER_FOURTH_COL_COMPANIES_DONE,
    payload,
});
