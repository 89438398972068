import React, { useRef, useState, useEffect } from "react";
import clipIcon from "../../../images/clipIcon.svg";
import FiltersTable from "./filtersTable";

function SearchRowBlock({   isLast,
                            options,
                            value,
                            handleChange,
                            isLoading
}) {
    const [ showSelect, setShowSelect ] = useState(false);
    const [ filterValue, setFilterValue ] = useState('');
    const ref = useRef(null);

    const closeDropdown = () => {
        setShowSelect(false);
        setFilterValue('');
    };

    const handleClickOutside = e => {
        if(ref.current && !ref.current.contains(e.target)){
            closeDropdown();
        }
    };

    const toggleDropdown = () => {
        if(showSelect){
            setShowSelect(false);
            setFilterValue('');
        } else {
            setShowSelect(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    return (
        <div className={`search-row-block-wrapper ${showSelect ? "open" : ""}`} ref={ref}>
            {!value && <div className="search-row-block">
                {isLast ?
                    <>
                        <hr className="half-divider"/>
                        <div className="clip-icon">
                            <img src={clipIcon} alt="" className="clip-icon"/>
                        </div>
                    </> :
                    <hr className="full-divider"/>}
            </div>}
            <div className="search-row-block dropdown all-block" onClick={toggleDropdown} title={value}>
                {value}
            </div>
            <FiltersTable
                // setValue={setValue}
                selectedValue={value}
                options={options}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                handleChange={handleChange}
                setShowSelect={setShowSelect}
                showAllOption
                isLoading={isLoading}
            />
        </div>
    )
}

export default SearchRowBlock;

// <div >
//     {showSelect ?
//         <select className="search-row-block" value={value} onChange={e=>setValue(e.target.value)}>
//             {/*<option value="" disabled hidden></option>*/}
//             <option value="All">All</option>
//             {options.map(option => <option value={option} key={option}>{option}</option> )}
//             {/*<option value="volvo">National</option>*/}
//             {/*<option value="saab">Saab</option>*/}
//             {/*<option value="opel">Opel</option>*/}
//             {/*<option value="audi">Audi</option>*/}
//         </select>
//         :
//         <div className="search-row-block" onClick={()=>setShowSelect(true)}>
//             {isLast?
//                 <>
//                     <hr className="half-divider" />
//                     <div className="clip-icon">
//                         <img src={clipIcon} className="clip-icon"/>
//                     </div>
//                 </>:
//                 <hr className="full-divider"/>}
//         </div>}
// </div>

