import React from 'react';
import greenIcon from '../../../images/greenIcon.png';
import greyConfirmIcon from '../../../images/greyConfirmIcon.png';
import PLAN_UPDATE_MODAL_CONSTANTS from "../../../core/constants/planUpdateModalConstants";
import UseLoginController from "../../../customHooks/controllerHooks/loginController";
import {useSelector} from "react-redux";
const PROJECTS = [
  'ProjectName 1',
  'ProjectName 7',
  'ProjectName 4',
  'ProjectName 10',
  'ProjectName 5',
];

function SelectedProjects({ setModalState, onClose, selectedPlanName, selectedProjects }) {
    const { company } = useSelector(state => state.userInfo.data);
    const { updatePlan } = UseLoginController();

    const handleConfirm = () => {
        updatePlan({company, plan: selectedPlanName, projects: selectedProjects})
            .then(res => {
                // if(res instanceof Error) return;
                setModalState(PLAN_UPDATE_MODAL_CONSTANTS.DOWNGRADE_TO_PLUS_CONFIRM);
            })
    }

  return (
    <>
      <div className="downgrade-title">
        Hier ist die Liste der ausgewählten Projekte.
      </div>
      <div className="selected-projects-notice">
        Nach einer weiteren Bestätigung steht Ihnen das Wissen der <br />
        restlichen Projekte nicht mehr zur Verfügung.  Weiterhin nutzen Sie:
      </div>
      <div className="downgrade-select-section">
        {
          selectedProjects.map(project => (
            <div className="downgrade-select-item" key={project}>
              <img src={greenIcon} />
              <span>{project}</span>
            </div>
          ))
        }
      </div>
      <div className="selected-projects-notice">
        Sind Sie sicher?
      </div>
      <div className="plan-update-popup-buttons">
        <div className="plan-update-popup-button" onClick={onClose}>
          Abbrechen
        </div>
        <div className="plan-update-popup-button" onClick={handleConfirm}>
          Bestätigen
        </div>
      </div>
    </>

  );
}

export default SelectedProjects;
