import {useCallback, useEffect} from 'react';

function UseCloseOnEscape(func) {
    const closeOnEscape = useCallback(e => {
        if(e.code === 'Escape') {
            func();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', closeOnEscape);
        return () => document.removeEventListener('keydown', closeOnEscape);
    },[]);
}

export default UseCloseOnEscape;