import React from "react";
import TableData from "./tableData";
import eyeSolid from '../../../images/eyeSolid.svg';
import eyeSlashSolid from '../../../images/eyeSlashSolid.svg';

function OutcomeTable({
                          showHiddenLayers,
                          setShowHiddenLayers,
                          hiddenLayers,
                          project,
                          firstColumn,
                          secondColumn,
                          thirdColumn,
                          fourthColumn,
                          firstColumnArticlesCount,
                          secondColumnArticlesCount,
                          thirdColumnArticlesCount,
                          fourthColumnArticlesCount,
                          changeHiddenLayers,
                          showEyeIcon = true,
                      }) {


    const handleRowIconClick = (project, layer, hide) => {
        changeHiddenLayers({
            project,
            layer,
            hide,
        });
    };

    return (
        <table className={`heatmap-table ${showHiddenLayers? "show-hidden-layers": ""}`}>
            <tbody>
            {firstColumn.map((row, index)=> {
                const hidden = !!hiddenLayers[row.name];
                if(!index && showEyeIcon) {
                    return (
                        <tr key={row.name} >
                            <td className="first" title={row.name}>
                                <img src={showHiddenLayers? eyeSlashSolid: eyeSolid} alt="" onClick={()=>setShowHiddenLayers(!showHiddenLayers)}
                                     title={showHiddenLayers? "Hide hidden layers": "Show hidden layers"}/>
                                <span>{row.name}</span>
                            </td>
                            <TableData data={row.count} totalCount={firstColumnArticlesCount} />
                            <TableData data={secondColumn[row.name]} totalCount={secondColumnArticlesCount} />
                            <TableData data={thirdColumn[row.name]} totalCount={thirdColumnArticlesCount} />
                            <TableData data={fourthColumn[row.name]} totalCount={fourthColumnArticlesCount} />
                        </tr>
                    )
                }
                return (
                    <tr key={row.name} className={hidden ? 'hidden': ''}>
                        <td title={row.name}>
                            <img src={hidden ? eyeSlashSolid: eyeSolid}
                                 alt=""
                                 onClick={()=>handleRowIconClick(project, row.name, !hidden)}
                            />
                            <span>{row.name}</span>
                        </td>
                        <TableData data={row.count} totalCount={firstColumnArticlesCount} />
                        <TableData data={secondColumn[row.name]} totalCount={secondColumnArticlesCount} />
                        <TableData data={thirdColumn[row.name]} totalCount={thirdColumnArticlesCount} />
                        <TableData data={fourthColumn[row.name]} totalCount={fourthColumnArticlesCount} />
                    </tr>
                )
            })}

            </tbody>
        </table>
    )
}

export default OutcomeTable;
