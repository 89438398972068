import SemanticTableTitle from "./semanticTableTitle";
import React, {useState} from "react";

const SemanticTable = ({ title, defaultOpen, children }) => {
    const [ open, setOpen ] = useState(!!defaultOpen);
  return (
      <div className="semantic-table">
        <SemanticTableTitle title={title} isOpen={open} handleClick={() => setOpen(!open)}/>
        <div className="semantic-outcome-table">
            { open && children }
        </div>
      </div>)
};

export default SemanticTable;