import React from "react";
import searchSolid from "../../../images/search-solid.png";

function SubjectDropdownItem({ project, handleClick, selectedProject }) {
    return(
        <div className={`search-text-section ${selectedProject===project? 'selected': ''}`} onClick={handleClick}>
            <div className="filter-icon subject-filter-icon">
                <img src={searchSolid} alt="" />
            </div>
            <div>
                <div className="search-name">{project}</div>
                {/*<div className="saved-search">Saved search #1 | 02/05/2020</div>*/}
            </div>
        </div>
    );
}

export default SubjectDropdownItem;