import {combineReducers} from "redux";
import createReducer from "../createReducer";
import {SAVED_SETTINGS_TYPES} from "../actionTypes/savedSettingsActionTypes";

export const savedSettingsReducer = combineReducers({
    dashboardSettings: createReducer({}, {
        [SAVED_SETTINGS_TYPES.RX_SAVE_DASHBOARD_SETTINGS]: (state, action) => action.payload,
    }),
    heatmapSettings: createReducer({}, {
        [SAVED_SETTINGS_TYPES.RX_SAVE_HEATMAP_SETTINGS]: (state, action) => action.payload,
    }),
    chartSettings: createReducer({}, {
        [SAVED_SETTINGS_TYPES.RX_SAVE_CHART_SETTINGS]: (state, action) => action.payload,
    }),
    publisherHeatmapSettings: createReducer({}, {
        [SAVED_SETTINGS_TYPES.RX_SAVE_PUBLISHER_HEATMAP_SETTINGS]: (state, action) => action.payload,
    })
});